import { Grid } from "@mui/material";
import { sortBy } from "lodash";
import { Field, FilledField } from "../lib/fields";
import FieldComponent, { IFieldProps } from "./Field";

interface IFieldListProps extends Omit<IFieldProps, "field" | "updateHandler" | "propName"> {
    fields: FilledField[];
    dense?: boolean;
    updateFieldHandler: IFieldProps["updateHandler"];
}

const getParentValue = (field: Field, fields: FilledField[]) => {
    const depends = field?.options?.depends;
    if (depends != null) {
        const parentField = fields.find(({ title }) => title === depends);
        if (parentField != null) {
            return parentField.value;
        }
    }
    return null;
};

const FieldList = ({ dense, fields, updateFieldHandler, ...fieldProps }: IFieldListProps) => {
    return (
        // no column size so that no additional padding is applied
        <Grid container spacing={dense ? 2 : 0}>
            {sortBy(fields, (field) => field.order ?? 0).map((field) => (
                <FieldComponent
                    key={field.title}
                    parentValue={getParentValue(field, fields)}
                    propName={field.title}
                    field={field}
                    updateHandler={updateFieldHandler}
                    dense={dense}
                    {...fieldProps}
                />
            ))}
        </Grid>
    );
};

export default FieldList;
