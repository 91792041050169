import ExpandMoreIcon from "@mui/icons-material/ExpandMoreRounded";
import { Accordion, AccordionDetails, AccordionSummary, Box, Divider, Grid, Stack, styled, Typography } from "@mui/material";
import { AttributeCategoryDto, AttributeCategoryListDto, ClientDto, MeasureDto, MeasureUpdate } from "api-shared";
import { TFunction } from "i18next";
import { groupBy, sortBy } from "lodash";
import React, { useCallback, useMemo, useState } from "react";
import FieldList from "../../components/FieldList";
import { MeasureBreadcrumb } from "../../components/MeasureBreadcrumb";
import FavoriteIcon from "../../components/icons/FavoriteIcon";
import EditableText from "../../components/input/EditableText";
import MarkdownEditorUserMentionsMeasure from "../../components/markdowneditor/MarkdownEditorUserMentionsMeasure";
import SidebarGutters from "../../components/sidebar/SidebarGutters";
import { IUpdateMeasureOptions, useCurrentUserCanEditMeasure, useMeasureFields } from "../../domain/measure/detail";
import { useToggleFavoriteMeasure } from "../../domain/measure/favorite";
import { useUiState } from "../../domain/ui-state";
import { getFieldValue } from "../../lib/fields";
import { translateFromProperty } from "../../lib/translate";
import { Language, translationKeys } from "../../translations/main-translations";
import FieldHelp from "../help/FieldHelp";
import MeasureSettings from "./MeasureSettings";

const MeasureSettingsHeader = styled(MeasureSettings)(({ theme }) => ({
    flexGrow: 0,
    flexShrink: 0,
    [theme.breakpoints.up("md")]: {
        display: "none",
    },
    padding: theme.spacing(2.5),
}));

const SettingsDivider = styled(Divider)(({ theme }) => ({
    flexGrow: 0,
    flexShrink: 0,
    [theme.breakpoints.up("md")]: {
        display: "none",
    },
}));

const FieldsContainer = styled("div")(({ theme }) => ({
    padding: theme.spacing(4, 0, 3),
}));

interface MeasureSidebarProps {
    measure: MeasureDto;
    attributeCategories: AttributeCategoryListDto;
    client: ClientDto;
    language: Language;
    updateMeasure: (payload: IUpdateMeasureOptions) => void;
    translate: TFunction;
    closeMenu: () => void;
    ideaDisplayId?: number;
    openIdeaModal: () => void;
}

interface SortedFields {
    [categoryId: number]: {
        category: AttributeCategoryDto;
        fields: any[];
    };
}

function getCategorizedFields(attributeCategories: AttributeCategoryListDto, fields?: any[]): SortedFields {
    if (fields == null) {
        return {};
    }

    const grouped = groupBy(fields, (field) => field.context.categoryId ?? 0);
    const sortedCategories = sortBy([{ id: 0, nameDe: "", nameEn: "", order: 0 }, ...attributeCategories], ["order"]);
    const result: SortedFields = {};

    sortedCategories.forEach((category) => {
        const fieldsInCategory = grouped[String(category.id)] || [];
        result[category.order] = {
            category,
            fields: fieldsInCategory,
        };
    });

    return result;
}

function MeasureSidebar({
    measure,
    attributeCategories,
    client,
    language,
    updateMeasure,
    translate,
    closeMenu,
    openIdeaModal,
    ideaDisplayId,
}: Readonly<MeasureSidebarProps>) {
    const [helpFieldTitle, setHelpFieldTitle] = useState<string | null>(null);

    const [uiState, updateUiState] = useUiState();
    const currentUserCanEditMeasure = useCurrentUserCanEditMeasure(measure);
    const measureFields = useMeasureFields(measure);
    const visibleFields = useMemo(() => measureFields.filter((field) => field.isVisible), [measureFields]);
    const categorizedGroups = useMemo(() => getCategorizedFields(attributeCategories, visibleFields), [attributeCategories, visibleFields]);

    const toggleFavoriteMeasure = useToggleFavoriteMeasure(measure.id, measure.isFavorite);
    const [isTitleError, setIsTitleError] = useState(false);

    function onTextSave(title: string) {
        if (measure.title !== title && title.length <= 1024) {
            updateMeasure({ measureId: measure.id, changes: { title } });
        }
        setIsTitleError(title.length > 1024);
    }

    const updateField = useCallback(
        (changes: MeasureUpdate) => {
            updateMeasure({ measureId: measure.id, changes });
        },
        [measure.id, updateMeasure],
    );

    const helpField = visibleFields.find(({ title }) => title === helpFieldTitle);
    const helpFieldValue = helpFieldTitle != null ? getFieldValue(helpFieldTitle, visibleFields) : null;

    const parentFieldTitle = helpField?.options != null ? helpField.options.depends : null;
    const helpFieldParentValue = parentFieldTitle != null ? getFieldValue(parentFieldTitle, visibleFields) : null;

    return (
        <>
            <MeasureSettingsHeader closeMenu={closeMenu} justifyContent="space-between" />
            <SettingsDivider />
            <FieldsContainer>
                {helpFieldTitle == null ? (
                    <Grid container component={SidebarGutters}>
                        <Grid item xs={12}>
                            <Stack direction="row" spacing={0.5} alignItems="center">
                                <MeasureBreadcrumb
                                    label={translate(measure.measureConfig.name)}
                                    measureDisplayId={measure.clientIid}
                                    ideaDisplayId={ideaDisplayId}
                                    ideaChipProps={{
                                        onClick: openIdeaModal,
                                    }}
                                    ideaChipTestId="ideaOrigin_IdChip"
                                />
                                <FavoriteIcon
                                    iconSize="small"
                                    value={measure.isFavorite}
                                    updateValue={toggleFavoriteMeasure}
                                    translate={translate}
                                />
                            </Stack>
                        </Grid>
                        <Grid item xs={12}>
                            <Box mx={-1.5}>
                                <EditableText
                                    variant="h6"
                                    text={measure.title}
                                    errorText={isTitleError ? translate(translationKeys.VDLANG_MEASURE_TITLE_TOO_LONG_ERROR) : null}
                                    disabled={!currentUserCanEditMeasure}
                                    onTextSave={onTextSave}
                                    size="small"
                                />
                            </Box>
                        </Grid>
                        {Object.values(categorizedGroups).map(({ category, fields }) => {
                            if (fields.length === 0) {
                                return null;
                            }

                            return category.id === 0 ? (
                                <Grid item xs={12} key={category.id}>
                                    <Grid item xs={12}>
                                        <Typography variant="subtitle1">{category?.nameDe}</Typography>
                                    </Grid>
                                    <Grid item xs={12}>
                                        <FieldList
                                            measureId={measure.id}
                                            fields={fields}
                                            updateFieldHandler={updateField}
                                            disabled={!currentUserCanEditMeasure}
                                            client={client}
                                            processName={measure.measureConfig.name}
                                            lang={language}
                                            translate={translate}
                                            openFieldHelp={setHelpFieldTitle}
                                            TextEditorComponent={MarkdownEditorUserMentionsMeasure}
                                        />
                                    </Grid>
                                </Grid>
                            ) : (
                                <Grid item xs={12} key={category.id}>
                                    <Accordion
                                        variant="elevation"
                                        elevation={0}
                                        defaultExpanded={uiState.measureAttributeCategoryState[category.id] ?? true}
                                        onChange={(_, expanded) => {
                                            updateUiState({
                                                measureAttributeCategoryState: {
                                                    ...uiState.measureAttributeCategoryState,
                                                    [category.id]: expanded,
                                                },
                                            });
                                        }}
                                    >
                                        <AccordionSummary expandIcon={<ExpandMoreIcon />} sx={{ flexDirection: "row-reverse", padding: 0 }}>
                                            <Typography variant="subtitle1">{translateFromProperty(category, "name", language)}</Typography>
                                        </AccordionSummary>
                                        <AccordionDetails sx={{ padding: 0, mb: 2 }}>
                                            <FieldList
                                                measureId={measure.id}
                                                fields={fields}
                                                updateFieldHandler={updateField}
                                                disabled={!currentUserCanEditMeasure}
                                                client={client}
                                                processName={measure.measureConfig.name}
                                                lang={language}
                                                translate={translate}
                                                openFieldHelp={setHelpFieldTitle}
                                                TextEditorComponent={MarkdownEditorUserMentionsMeasure}
                                            />
                                        </AccordionDetails>
                                    </Accordion>
                                </Grid>
                            );
                        })}
                    </Grid>
                ) : (
                    <FieldHelp
                        title={helpFieldTitle}
                        params={[helpFieldValue, helpFieldParentValue]}
                        onClose={() => setHelpFieldTitle(null)}
                    />
                )}
            </FieldsContainer>
        </>
    );
}

export default React.memo(MeasureSidebar);
