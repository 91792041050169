import { TableSummaryRow } from "@devexpress/dx-react-grid-material-ui";
import { Typography } from "@mui/material";

const SummaryItem = ({ children }: TableSummaryRow.ItemProps) => {
    return (
        <Typography color="textPrimary" component="span" fontWeight="bold">
            {children}
        </Typography>
    );
};

export default SummaryItem;
