import ChevronRightRounded from "@mui/icons-material/ChevronRightRounded";
import {
    AccordionDetails,
    AccordionSummaryProps,
    Divider,
    Grid,
    Accordion as MuiAccordion,
    AccordionSummary as MuiAccordionSummary,
    Paper,
    Typography,
    styled,
} from "@mui/material";
import { MeasureDto, SubTaskDto } from "api-shared";
import { TFunction } from "i18next";
import { groupBy } from "lodash";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import OverlineLabel from "../../components/OverlineLabel";
import SubtaskEffortChart from "../../components/tasks/SubtaskEffortChart";
import { useSubTaskLevel } from "../../components/tasks/useSubtaskLevel";
import { translationKeys } from "../../translations/main-translations";
import { useMeasureContext } from "../MeasureContext";

export interface IEffortOverviewForMeasureProps {
    tasks: SubTaskDto[];
}

export interface IEffortChartForGateProps {
    task: SubTaskDto;
    translate: TFunction;
    fullWidth?: boolean;
}

const Label = styled(OverlineLabel)(({ theme }) => ({
    margin: 0,
    fontSize: theme.typography.pxToRem(10),
}));

const EstimationSum = styled("div")(({ theme }) => ({
    backgroundColor: theme.palette.background.light,
    padding: theme.spacing(0.5, 3, 0.5, 3),
}));

const AccordionSummary = styled((props: AccordionSummaryProps) => <MuiAccordionSummary expandIcon={<ChevronRightRounded />} {...props} />)({
    flexDirection: "row-reverse",
    "& .MuiAccordionSummary-expandIconWrapper.Mui-expanded": {
        transform: "rotate(-90deg)",
    },
    "&:before": {
        display: "none",
    },
});
const Accordion = styled(MuiAccordion)(({ theme }) => ({
    borderRadius: theme.shape.borderRadiusLarge,
}));

const EffortChartForGate = ({ task, translate, fullWidth = false }: IEffortChartForGateProps) => {
    const levelName = useSubTaskLevel(task) ?? translate(translationKeys.VDLANG_ACTIVITIES_GATE_TASK_NOT_ASSIGNED_LABEL);

    if (!task.estimatedEffort && !task.trackedTime) {
        return null;
    }
    return (
        <Grid item xs={fullWidth ? 12 : 6} sx={{ mt: 0.5, pl: 1, pr: 1 }}>
            <Label>{levelName}</Label>
            <SubtaskEffortChart
                dense
                activity={{
                    estimatedEffort: task.estimatedEffort,
                    trackedTime: task.trackedTime,
                }}
            />
        </Grid>
    );
};

const getSortedTasksList = (tasks: SubTaskDto[], measure: MeasureDto) => {
    // group and order the tasks based on gate task order
    const groupedTasks = groupBy(tasks, (item) => item.gateTaskId);
    const orderedGateTaskConfigs = [...measure.measureConfig.gateTaskConfigs].sort((a, b) => a.order - b.order);

    const orderedGroupedTasks: SubTaskDto[] = [];

    // calculate the sums per level and add them to a "dummy task" representing the respective level
    orderedGateTaskConfigs.forEach((gtc) => {
        const gt = measure.gateTasks.find((gt) => gt.gateTaskConfigId === gtc.id);
        if (gt && groupedTasks[gt.id]) {
            const tasksInLevel = groupedTasks[gt.id];
            if (tasksInLevel.length > 0) {
                const eff = tasksInLevel.reduce((a, b) => a + (b.estimatedEffort ?? 0), 0);
                const time = tasksInLevel.reduce((a, b) => a + (b.trackedTime ?? 0), 0);
                const newTask = {
                    ...tasksInLevel[0],
                    estimatedEffort: eff,
                    trackedTime: time,
                };
                if (eff > 0 || time > 0) {
                    orderedGroupedTasks.push(newTask);
                }
            }
        }
    });

    // add sums to dummy task for unassigned tasks
    if (groupedTasks.null && groupedTasks.null.length > 0) {
        const eff = groupedTasks.null.reduce((a, b) => a + (b.estimatedEffort ?? 0), 0);
        const time = groupedTasks.null.reduce((a, b) => a + (b.trackedTime ?? 0), 0);
        const newTask = {
            ...groupedTasks.null[0],
            estimatedEffort: eff,
            trackedTime: time,
        };
        if (eff > 0 || time > 0) {
            orderedGroupedTasks.push(newTask);
        }
    }
    return orderedGroupedTasks;
};

const EffortOverviewForMeasure = ({ tasks }: IEffortOverviewForMeasureProps) => {
    const [showEstimations, setShowEstimations] = useState(false);
    const { t: translate } = useTranslation();

    const measure = useMeasureContext();
    const orderedGroupedTasks = getSortedTasksList(tasks, measure);

    const anyTaskHasTimes = tasks.some(
        (task) => (task.estimatedEffort !== null && task.estimatedEffort > 1) || (task.trackedTime !== null && task?.trackedTime > 0),
    );
    return (
        <Paper variant="elevation" elevation={0}>
            <Accordion
                square
                disableGutters
                expanded={showEstimations}
                onChange={(e) => setShowEstimations(!showEstimations)}
                variant="outlined"
            >
                <AccordionSummary>
                    <Typography variant="subtitle1" sx={{ marginLeft: 1 }}>
                        {translate(translationKeys.VDLANG_ACTIVITY_EFFORT_ESTIMATION_OVERVIEW)}
                    </Typography>
                </AccordionSummary>
                <AccordionDetails sx={{ p: 0, borderRadius: 8 }}>
                    <Divider />
                    {
                        // show the sum entry only if 2 or more levels have time values
                        orderedGroupedTasks.length > 1 && (
                            <>
                                <EstimationSum>
                                    <Label>{translate(translationKeys.VDLANG_ACTIVITY_EFFORT_ESTIMATION_OVERVIEW_TOTAL)}</Label>
                                    <SubtaskEffortChart
                                        dense
                                        activity={{
                                            estimatedEffort: orderedGroupedTasks.reduce((a, b) => a + (b.estimatedEffort ?? 0), 0),
                                            trackedTime: orderedGroupedTasks.reduce((a, b) => a + (b.trackedTime ?? 0), 0),
                                        }}
                                    />
                                </EstimationSum>
                                <Divider />
                            </>
                        )
                    }
                    <Grid container sx={{ pl: 2, pr: 2, pb: 1 }}>
                        {anyTaskHasTimes ? (
                            orderedGroupedTasks.map((task) => (
                                <EffortChartForGate
                                    key={task.gateTaskId}
                                    translate={translate}
                                    task={task}
                                    fullWidth={orderedGroupedTasks.length === 1}
                                />
                            ))
                        ) : (
                            <Typography variant="body2" sx={{ m: 2 }}>
                                {translate(translationKeys.VDLANG_ACTIVITY_EFFORT_ESTIMATION_OVERVIEW_NO_VALUES)}
                            </Typography>
                        )}
                    </Grid>
                </AccordionDetails>
            </Accordion>
        </Paper>
    );
};

export default EffortOverviewForMeasure;
