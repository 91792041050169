import { Container, Divider, Stack, styled, Tab, Tabs } from "@mui/material";
import { FeatureFlags, GateTaskType, MeasureDto, SubTaskStatus } from "api-shared";
import { TFunction } from "i18next";
import React, { Suspense } from "react";
import { Link, Route, Routes, useLocation } from "react-router-dom";
import CommentsForCurrentMeasure from "../../components/comment/CommentsForCurrentMeasure";
import ActivityTemplateIcon from "../../components/icons/ActivityTemplateIcon";
import LoadingAnimation from "../../components/loading/LoadingAnimation";
import { useClientHasFeature } from "../../domain/client";
import { useCommentsForMeasure } from "../../domain/comment";
import { useMeasureFiles } from "../../domain/files";
import { useMeasureFields, useMethodIdFieldValue } from "../../domain/measure/detail";
import { useActivityTemplate } from "../../domain/methods/activity-template";
import { useSubTasksByMeasure } from "../../domain/subtasks";
import { RouteFor, withNestedRoutes } from "../../lib/routes";
import { translationKeys } from "../../translations/main-translations";
import FeedHistory from "./FeedHistory";
import FileList from "./FileList";
import GateListForCurrentMeasure from "./GateListForCurrentMeasure";
import History from "./History";
import MeasureSettings from "./MeasureSettings";
import SubtasksForCurrentMeasure from "./SubtasksForCurrentMeasure";

const ExtendedCalculation = React.lazy(() => import("./calculation/ExtendedCalculation"));
const MeasureSuggestions = React.lazy(() => import("./MeasureSuggestions"));

const ScrollContainer = styled("div")(({ theme }) => ({
    flexGrow: 1,
    minHeight: 0,
    overflowY: "auto",
    padding: theme.spacing(3, 2, 3),
    transition: theme.transitions.create("padding"),
    [theme.breakpoints.down("md")]: {
        padding: theme.spacing(3, 2),
    },
    [theme.breakpoints.only("xs")]: {
        padding: theme.spacing(3, 0),
        overflowX: "hidden",
    },
}));

const CenteredContainer = styled(Container)(({ theme }) => ({
    // Make it a flex column, so children are able to grow vertically if they want to
    display: "flex",
    flexDirection: "column",
    // Make growing components grow to the viewport bottom, while larger children can still use scrolling of the ScrollContainer above
    minHeight: "100%",
}));

const RootContainer = styled(Stack)(({ theme }) => ({
    backgroundColor: theme.palette.background.default,
    minHeight: "100%",
    height: 0, // fix Safari/IE bug with flexbox
    [theme.breakpoints.up("md")]: {
        minWidth: theme.breakpoints.values.md,
    },
}));

const ProcessTabs = styled(Tabs)(({ theme }) => ({
    flexShrink: 1,
    flexGrow: 1,
    maxWidth: theme.breakpoints.values.lg,
    alignSelf: "flex-end", // align buttons at the bottom of the banner (other items are centered)
    minHeight: 0, // unset default minHeight of tabs
}));

// Style the Link instead of Tab because a styled(Tab) looses customizability with component prop
const ProcessTabLink = styled(Link)(({ theme }) => ({
    // root styles would be merged with Tab styles and the latter get precedence
    // generate a dedicated rule which automatically has higher precedence
    "&": {
        flexDirection: "row",
        minWidth: 0,
        minHeight: 0, // unset default minHeight of tabs
    },
}));

const Banner = styled(Stack)(({ theme }) => ({
    backgroundColor: theme.palette.background.paper,
    paddingRight: theme.spacing(3),
    minHeight: theme.spacing(8),
    [theme.breakpoints.down("md")]: {
        paddingRight: 0,
        minHeight: theme.spacing(7),
    },
    flexShrink: 0,
}));

const ResponsiveMeasureSettings = styled(MeasureSettings)(({ theme }) => ({
    flexShrink: 0,
    [theme.breakpoints.down("md")]: {
        display: "none",
    },
}));

enum MeasureTab {
    PROCESS,
    CALCULATION,
    SUBTASKS,
    COMMENTS,
    DOCUMENTS,
    HISTORY,
    SUGGESTIONS,
    FEED_HISTORY,
}

interface TabInfo {
    type: MeasureTab;
    path: string;
    label: string;
    component: React.ComponentType;
    anonymous?: boolean;
}

const SUB_ROUTES = [
    {
        type: MeasureTab.PROCESS,
        path: "" as const,
        label: translationKeys.VDLANG_MEASURE_TABS_PROCESS,
        component: GateListForCurrentMeasure,
        anonymous: true,
    },
    {
        type: MeasureTab.CALCULATION,
        path: RouteFor.measure.detailViewTab.calculation,
        label: translationKeys.VDLANG_MEASURE_TABS_CALCULATION,
        component: ExtendedCalculation,
        anonymous: true,
    },
    {
        type: MeasureTab.SUBTASKS,
        path: RouteFor.measure.detailViewTab.activities,
        label: translationKeys.VDLANG_MEASURE_TABS_ACTIVITIES,
        component: SubtasksForCurrentMeasure,
        anonymous: true,
    },
    {
        type: MeasureTab.COMMENTS,
        path: RouteFor.measure.detailViewTab.comments,
        label: translationKeys.VDLANG_MEASURE_TABS_COMMENTS,
        component: CommentsForCurrentMeasure,
    },
    {
        type: MeasureTab.DOCUMENTS,
        path: RouteFor.measure.detailViewTab.documents,
        label: translationKeys.VDLANG_MEASURE_TABS_DOCUMENTS,
        component: FileList,
        anonymous: true,
    },
    {
        type: MeasureTab.HISTORY,
        path: RouteFor.measure.detailViewTab.history,
        label: translationKeys.VDLANG_MEASURE_TABS_HISTORY,
        component: History,
    },
    {
        type: MeasureTab.FEED_HISTORY,
        path: RouteFor.measure.detailViewTab.feedHistory,
        label: translationKeys.VDLANG_MEASURE_TABS_FEED_HISTORY,
        component: FeedHistory,
    },
    {
        type: MeasureTab.SUGGESTIONS,
        path: RouteFor.measure.detailViewTab.suggestions,
        label: translationKeys.VDLANG_MEASURE_TABS_SUGGESTIONS,
        component: MeasureSuggestions,
    },
] satisfies TabInfo[];

export interface IMeasureframeProps {
    measure: MeasureDto;
    translate: TFunction;
}

const Measureframe = ({ translate, measure }: IMeasureframeProps) => {
    const location = useLocation();

    const fields = useMeasureFields(measure);
    const methodId = useMethodIdFieldValue(fields);

    const activityTemplateQuery = useActivityTemplate({ methodId });
    const filesQuery = useMeasureFiles({ measureId: measure.id });

    const hasActivityTemplate = activityTemplateQuery.isSuccess && activityTemplateQuery.data.items.length > 0;

    const hasExtendedCalculationEnabled = useClientHasFeature(FeatureFlags.FEATURE_EXTENDED_CALCULATION);
    const hasOpenAIApiEnabled = useClientHasFeature(FeatureFlags.FEATURE_OPENAI_API);

    const subTasks = useSubTasksByMeasure(measure.id);
    const commentsQuery = useCommentsForMeasure({ measureId: measure.id, enabled: true });

    let availableTabs = SUB_ROUTES;
    const hasCalculationGates = measure.measureConfig.gateTaskConfigs.some((gtc) => gtc.type === GateTaskType.Calculation);
    if (!hasExtendedCalculationEnabled || !hasCalculationGates) {
        availableTabs = availableTabs.filter(({ type }) => type !== MeasureTab.CALCULATION);
    }
    if (!hasOpenAIApiEnabled) {
        availableTabs = availableTabs.filter(({ type }) => type !== MeasureTab.SUGGESTIONS);
    }

    // There is no good way with react-router v6 to match multiple relative routes programmatically
    // Match using String.prototype.includes, so that nested routing of activities still works
    const routeIndex = availableTabs.findIndex(({ path }) => path.length > 0 && location.pathname.includes(path));
    let tabValue = routeIndex > -1 ? routeIndex : 0; // Fall back to first tab

    if (availableTabs.findIndex(({ type }) => type === MeasureTab.FEED_HISTORY) === routeIndex) {
        tabValue = -1;
    }

    const getNoOfItemsLabel = (type: MeasureTab) => {
        switch (type) {
            case MeasureTab.SUBTASKS:
                return subTasks.isSuccess
                    ? ` (${subTasks.data.filter((task) => task.status === SubTaskStatus.STATUS_COMPLETED).length}/${subTasks.data?.length})`
                    : "";
            case MeasureTab.DOCUMENTS:
                return filesQuery.isSuccess ? ` (${filesQuery.data.length})` : "";
            case MeasureTab.COMMENTS:
                return commentsQuery.isSuccess ? ` (${commentsQuery.data.length})` : "";
            default:
                return "";
        }
    };

    const getTabLabel = (tab: TabInfo) => {
        const noOfItemsLabel = getNoOfItemsLabel(tab.type);
        const name = `${translate(tab.label)}${noOfItemsLabel}`;
        if (tab.type !== MeasureTab.SUBTASKS) {
            return name;
        }
        return (
            <>
                {hasActivityTemplate && <ActivityTemplateIcon color="primary" fontSize="inherit" />}
                &nbsp;
                {name}
            </>
        );
    };

    return (
        <RootContainer>
            <Banner direction="row" spacing={2} alignItems="center" justifyContent="space-between">
                <ProcessTabs value={tabValue} variant="scrollable" scrollButtons allowScrollButtonsMobile>
                    {
                        // FEED_HISTORY navigation in MeasureActions but component must be here
                    }
                    {availableTabs
                        .filter(({ type }) => type !== MeasureTab.FEED_HISTORY)
                        .map((tab, index) => (
                            <Tab key={index} component={ProcessTabLink} to={{ ...location, pathname: tab.path }} label={getTabLabel(tab)} />
                        ))}
                </ProcessTabs>
                <ResponsiveMeasureSettings dense spacing={1} />
            </Banner>
            <Divider />
            <ScrollContainer>
                <CenteredContainer disableGutters maxWidth="lg">
                    <Suspense fallback={<LoadingAnimation />}>
                        <Routes>
                            {availableTabs.map(({ path, component: Component }) => (
                                <Route path={withNestedRoutes(path)} key={path} element={<Component />} />
                            ))}
                        </Routes>
                    </Suspense>
                </CenteredContainer>
            </ScrollContainer>
        </RootContainer>
    );
};

export default React.memo(Measureframe);
