import CloseIcon from "@mui/icons-material/CloseRounded";
import { IconButton, styled } from "@mui/material";
import { CalculationHistoryType, EffectCategoryHistoryEntryDto } from "api-shared";
import { TFunction } from "i18next";
import { orderBy } from "lodash";
import { useState } from "react";
import BaseDialog, { IBaseDialogProps, baseDialogClasses } from "../../../../components/dialogues/BaseDialog";
import LoadingAnimation from "../../../../components/loading/LoadingAnimation";
import Pagination from "../../../../components/table/Pagination";
import { useEffectCategoryHistory } from "../../../../domain/effect-category";
import { getEffectType } from "../../../../lib/history";
import { translationKeys } from "../../../../translations/main-translations";
import CalculationHistoryTable from "./CalculationHistoryTable";

const DenseDialog = styled(BaseDialog)(({ theme }) => ({
    [`& .${baseDialogClasses.title}`]: {
        padding: theme.spacing(1.5, 3),
    },
    [`& .${baseDialogClasses.actions}`]: {
        padding: theme.spacing(0.25, 0.5),
    },
}));

export interface ICalculationHistoryDialogProps
    extends Omit<IBaseDialogProps, "actions" | "translate" | "title">,
        Partial<Pick<IBaseDialogProps, "title">> {
    translate: TFunction;
    categoryId: number;
}

const DEFAULT_PAGE_SIZE = 15;

const CalculationHistoryDialog = ({ translate, title, categoryId, onClose, ...other }: ICalculationHistoryDialogProps) => {
    const effectCategoryHistoryEntriesQuery = useEffectCategoryHistory(categoryId);
    const [historyPageIndex, setHistoryPageIndex] = useState<number>(0);
    const [historyPageSize, setHistoryPageSize] = useState<number>(DEFAULT_PAGE_SIZE);

    if (!effectCategoryHistoryEntriesQuery.isSuccess) {
        return (
            <DenseDialog
                title={translate("history")}
                maxWidth="lg"
                onClose={onClose}
                {...other}
                disableScrollLock={true}
                disableContentPadding
            >
                <LoadingAnimation />;
            </DenseDialog>
        );
    }
    const effectCategoryHistoryEntries = effectCategoryHistoryEntriesQuery.data.items;
    // Hold back entries of a different EffectCategory
    const effectCategoryEntries = effectCategoryHistoryEntries.filter(
        ({ historyType }) => historyType === CalculationHistoryType.EffectCategory,
    ) as EffectCategoryHistoryEntryDto[];
    const matchingIds = effectCategoryEntries.some(({ effectCategoryId }) => effectCategoryId === categoryId);
    const calculationHistoryEntries = matchingIds ? effectCategoryHistoryEntries : [];
    const effectType = getEffectType(calculationHistoryEntries);
    const actions = [
        <Pagination
            key="pagination"
            onPageChange={setHistoryPageIndex}
            onPageSizeChange={setHistoryPageSize}
            pageSizeOptions={[15, 20, 25, 50, 100, 200]}
            page={historyPageIndex}
            pageSize={historyPageSize}
            data={calculationHistoryEntries}
            count={calculationHistoryEntries.length}
            translate={translate}
        />,
    ];

    const menuActions = [
        <IconButton key="close" aria-label={translate(translationKeys.VDLANG_CLOSE)} onClick={onClose}>
            <CloseIcon />
        </IconButton>,
    ];

    const sortedEntries = orderBy(calculationHistoryEntries, "updatedAt", "desc");

    // get all currency changes with timestamp so other entries can use the currency that was valid at the time of their creation
    const currencyChanges = sortedEntries
        .filter((entry) => entry.historyType == CalculationHistoryType.EffectCategory && entry.attribute === "currency_id")
        .map((entry) => ({ isoCode: String(entry.newValue), changedAt: entry.updatedAt }));

    const pagedHistoryEntries = sortedEntries.slice(
        historyPageIndex * historyPageSize,
        historyPageIndex * historyPageSize + historyPageSize,
    );

    return (
        <DenseDialog
            title={translate("history")}
            maxWidth="lg"
            actions={actions}
            menuActions={menuActions}
            onClose={onClose}
            {...other}
            disableScrollLock={true}
        >
            <CalculationHistoryTable
                historyEntries={pagedHistoryEntries}
                translate={translate}
                effectType={effectType}
                currencyChanges={currencyChanges}
            />
        </DenseDialog>
    );
};

export default CalculationHistoryDialog;
