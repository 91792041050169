import { CommentDto } from "api-shared";
import React from "react";
import { CommentParentType, useCreateComment, useDeleteComment, useUpdateComment } from "../../domain/comment";
import { useMeasureQuery } from "../../domain/measure/detail";
import { trackEvent } from "../../infrastructure/tracking";
import { MeasureContextProvider } from "../../view/MeasureContext";
import LoadingAnimation from "../loading/LoadingAnimation";
import MarkdownEditorUserMentionsMeasure from "../markdowneditor/MarkdownEditorUserMentionsMeasure";
import CommentList from "./CommentList";

export interface IMeasureCommentListProps {
    hideMessage?: boolean;
    comments: CommentDto[];
    measureId: number;
    isFeedEntry?: boolean;
}

const MeasureCommentList = ({ hideMessage = true, comments, measureId, isFeedEntry = false, ...otherProps }: IMeasureCommentListProps) => {
    const measure = useMeasureQuery(measureId);
    const createCommentMutation = useCreateComment();
    const updateCommentMutation = useUpdateComment();
    const deleteCommentMutation = useDeleteComment();
    const createComment = (parentId: number, comment: string) => {
        createCommentMutation.mutate({ parentType: CommentParentType.MEASURE, parentId, comment, measureId });
        isFeedEntry && trackEvent({ category: "Feed", action: "Feed Item Commented" });
    };
    const updateComment = (commentId: number, comment: string) =>
        updateCommentMutation.mutate({ parentType: CommentParentType.MEASURE, comment, commentId });
    const deleteComment = (commentId: number) =>
        deleteCommentMutation.mutate({ parentType: CommentParentType.MEASURE, commentId, parentId: measureId, measureId });

    if (comments == null || !measure.isSuccess) {
        return <LoadingAnimation />;
    }

    return (
        <MeasureContextProvider measure={measure.data}>
            <CommentList
                hideMessage={hideMessage}
                parentId={measureId}
                comments={comments}
                deleteComment={deleteComment}
                updateComment={updateComment}
                createComment={createComment}
                customEditorComponent={MarkdownEditorUserMentionsMeasure}
                {...otherProps}
            />
        </MeasureContextProvider>
    );
};

export default React.memo(MeasureCommentList);
