import { FormControlLabel, Radio, RadioGroup, styled } from "@mui/material";
import { DecisionType, UserDto } from "api-shared";
import { TFunction } from "i18next";
import React from "react";
import { formatUser } from "../../../lib/formatters";
import DecisionStep from "./DecisionStep";

const decisionTypeOrder = [DecisionType.TYPE_DOCUMENT, DecisionType.TYPE_REQUEST];

const DecisionRadioGroup = styled(RadioGroup)(({ theme }) => ({
    [theme.breakpoints.up("md")]: {
        justifyContent: "space-around",
        height: "100%",
    },
}));

interface IDecisionSelectionStepProps {
    selection: DecisionType;
    updateSelection: (newSelection: DecisionType) => void;
    disabled?: boolean;
    translate: TFunction;
    decider?: UserDto;
    canRequest: boolean;
    canDocument: boolean;
}

const DecisionSelectionStep = ({
    decider,
    selection,
    updateSelection,
    disabled = false,
    translate,
    canRequest,
    canDocument,
}: IDecisionSelectionStepProps) => {
    const update = (event: React.ChangeEvent<HTMLInputElement>, newValue: string) => updateSelection(+newValue);
    const canSelectOption = (option: DecisionType) => (option === DecisionType.TYPE_REQUEST ? canRequest : canDocument);

    return (
        <DecisionStep variant="medium" item xs={12} md={3}>
            <DecisionRadioGroup name="decisionType" value={String(selection)} onChange={update}>
                {decisionTypeOrder.map((type) => (
                    <FormControlLabel
                        key={type}
                        value={String(type)}
                        control={<Radio size="small" />}
                        disabled={disabled || !canSelectOption(type)}
                        label={translate(`decision_type_${type}`, {
                            name: decider != null ? formatUser(decider, { translate }) : "",
                        })}
                    />
                ))}
            </DecisionRadioGroup>
        </DecisionStep>
    );
};

export default DecisionSelectionStep;
