import CloseIcon from "@mui/icons-material/CloseRounded";
import { Grid, IconButton, ListItem, ListItemAvatar, Typography, styled } from "@mui/material";
import { UserDto } from "api-shared";
import { CommentBubble } from "../../../components/comment/CommentBubble";
import BaseDialog, { IBaseDialogProps } from "../../../components/dialogues/BaseDialog";
import UserEntryWithPopup from "../../../components/user/UserEntryWithPopup";
import UserName from "../../../components/user/UserName";
import { translationKeys } from "../../../translations/main-translations";
import { useTranslation } from "react-i18next";

const DecisionCommentHeader = styled(Grid)(({ theme }) => ({
    paddingBottom: theme.spacing(0.5),
    flexGrow: 1,
    flexShrink: 1,
}));

interface DecisionCommentDialogProps extends Pick<IBaseDialogProps, "open" | "onClose"> {
    users: UserDto[];
    decisionReason: string;
    deciderId: number;
}

const DecisionCommentDialog = ({ open, onClose, users, deciderId, decisionReason }: DecisionCommentDialogProps) => {
    const { t: translate } = useTranslation();

    return (
        <BaseDialog
            open={open}
            onClose={onClose}
            title={translate(translationKeys.VDLANG_DECISION_COMPLETED_COMMENT)}
            menuActions={[
                <IconButton key="close" onClick={onClose}>
                    <CloseIcon />
                </IconButton>,
            ]}
            maxWidth="sm"
            disableContentPadding
        >
            <ListItem alignItems="flex-start" sx={{ p: 3 }}>
                <ListItemAvatar>
                    <UserEntryWithPopup iconOnly user={users.find((u) => u.id === deciderId)} />
                </ListItemAvatar>
                <CommentBubble>
                    <DecisionCommentHeader container alignItems="center">
                        <Typography fontWeight="bold">
                            <UserName userId={deciderId} users={users} translate={translate} />
                        </Typography>
                    </DecisionCommentHeader>
                    <Typography variant="body2">{decisionReason}</Typography>
                </CommentBubble>
            </ListItem>
        </BaseDialog>
    );
};

export default DecisionCommentDialog;
