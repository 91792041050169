import { Button, Tooltip } from "@mui/material";
import { AclNamespaces, AclPermissions, MeasureStatus } from "api-shared";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import ConfirmDialog from "../../../components/dialogues/ConfirmDialog";
import SingleUser from "../../../components/user/SingleUser";
import UserEntryWithPopup from "../../../components/user/UserEntryWithPopup";
import { useProcessName } from "../../../domain/measure-config";
import { useAssignedToIsActive, useMeasureAssignedTo, useUpdateMeasure } from "../../../domain/measure/detail";
import { useIsUserMeasureEditor } from "../../../domain/measure/permission";
import { useUserHasPermissionQuery, useUsersHavingPermissionQuery } from "../../../domain/permissions";
import { isActiveUser, useAllUsers, useCurrentUserId } from "../../../domain/users";
import useDialog from "../../../hooks/useDialog";
import { translationKeys } from "../../../translations/main-translations";
import { useMeasureContext } from "../../MeasureContext";

const AccessControlDialog = () => {
    const { t: translate } = useTranslation();

    const [userToBeAssigned, setUserToBeAssigned] = useState<number | null>(null);
    const confirmDialog = useDialog();

    const measure = useMeasureContext();
    const updateMeasureMutation = useUpdateMeasure();
    const users = useAllUsers();

    const responsibleUsersQuery = useUsersHavingPermissionQuery({
        namespace: AclNamespaces.Process,
        permission: AclPermissions.Responsible,
        fact: {},
    });
    const valuestreamQuery = useUsersHavingPermissionQuery({
        namespace: AclNamespaces.Valuestream,
        permission: AclPermissions.Read,
        fact: { id: measure.measureConfigId },
    });

    const isOpenMeasure = measure.status === MeasureStatus.STATUS_OPEN;

    const canEditMeasureByStateQuery = useUserHasPermissionQuery(
        {
            namespace: AclNamespaces.Process,
            permission: AclPermissions.Update,
            fact: { status: measure.status },
        },
        !isOpenMeasure,
    );

    const processName = useProcessName(measure);

    const assignedToIsActive = useAssignedToIsActive(measure);
    const assignedTo = useMeasureAssignedTo(measure);
    const currentUserId = useCurrentUserId();
    const isMeasureEditor = useIsUserMeasureEditor(measure.id, currentUserId);

    if (!isOpenMeasure && !canEditMeasureByStateQuery.isSuccess) {
        // status based check only applies if the measure is not open
        return null;
    }

    if (!responsibleUsersQuery.isSuccess || !valuestreamQuery.isSuccess || measure === undefined) {
        return null;
    }

    const canBeResponsible =
        currentUserId !== null &&
        responsibleUsersQuery.data.combinedUserIds.includes(currentUserId) &&
        valuestreamQuery.data.combinedUserIds.includes(currentUserId);

    const assignedToHasPermission = assignedTo
        ? responsibleUsersQuery.data.combinedUserIds.includes(assignedTo.id) &&
          valuestreamQuery.data.combinedUserIds.includes(assignedTo.id)
        : false;

    const canEditMeasureByStatus = isOpenMeasure || canEditMeasureByStateQuery.data?.hasPermission;

    const selectableUsers = users.filter((u) => {
        return (
            isActiveUser(u) &&
            responsibleUsersQuery.data.combinedUserIds.includes(u.id) &&
            valuestreamQuery.data.combinedUserIds.includes(u.id)
        );
    });

    const closeConfirmDialog = () => {
        setUserToBeAssigned(measure.assignedToId);
        confirmDialog.close();
    };

    const openConfirmDialog = (userId: number | null) => {
        setUserToBeAssigned(userId);
        confirmDialog.open();
    };

    const updateAssignedTo = () =>
        !updateMeasureMutation.isLoading
            ? updateMeasureMutation.mutate({ measureId: measure.id, changes: { assignedTo: userToBeAssigned } })
            : null;

    const isInvalidAssignedTo = !assignedToIsActive || !assignedToHasPermission;
    const isAllowedToClaim = canBeResponsible && isMeasureEditor && canEditMeasureByStatus;
    const canChangeAssignedTo = currentUserId === measure.assignedToId && measure.status === MeasureStatus.STATUS_OPEN;
    const measureOwner = users.find((user) => user.id === measure.assignedToId);

    return (
        <>
            <ConfirmDialog
                open={confirmDialog.isOpen}
                onClose={closeConfirmDialog}
                title={translate("confirm_change_measure_owner_title")}
                item="measureOwner"
                primary="confirm_change_measure_owner"
                translate={translate}
                onConfirm={updateAssignedTo}
                primaryDanger
            >
                {translate("measureOwnerWarning", { processName: translate(processName) })}
            </ConfirmDialog>
            {isInvalidAssignedTo ? (
                <Tooltip title={!isAllowedToClaim ? translate(translationKeys.VDLANG_MEASURE_ACCESS_CONTROL_CANNOT_BE_CLAIMED) : ""}>
                    <span>
                        <Button
                            color="primary"
                            disabled={!isAllowedToClaim || updateMeasureMutation.isLoading}
                            onClick={() => openConfirmDialog(currentUserId)}
                        >
                            {translate(translationKeys.VDLANG_MEASURE_ACCESS_CONTROL_CLAIM)}
                        </Button>
                    </span>
                </Tooltip>
            ) : null}
            {!isInvalidAssignedTo && canChangeAssignedTo ? (
                <SingleUser
                    users={selectableUsers}
                    user={measureOwner}
                    translate={translate}
                    closeOnSelect
                    variant="avatar"
                    avatarSize={38}
                    update={(userId: number | null) => openConfirmDialog(userId)}
                    hideRemoveIcon={true}
                />
            ) : (
                <Tooltip title={translate(translationKeys.VDLANG_RESPONSIBLE_CURRENT_PERSON)}>
                    <UserEntryWithPopup iconOnly user={measureOwner} avatarProps={{ size: 38 }} />
                </Tooltip>
            )}
        </>
    );
};

export default AccessControlDialog;
