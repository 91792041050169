import CopyIcon from "@mui/icons-material/ContentCopyRounded";
import DeleteIcon from "@mui/icons-material/DeleteRounded";
import DynamicFeedIcon from "@mui/icons-material/DynamicFeed";
import { ListItemIcon, ListItemText, MenuItem } from "@mui/material";
import { AttributeTitle, MeasureCopyOptions, MeasureCopyOptionsList, MeasureDto } from "api-shared";
import { TFunction } from "i18next";
import MoreActionsMenu from "../../components/MoreActionsMenu";
import UnstyledNavLink from "../../components/UnstyledNavLink";
import CopyEntityDialog from "../../components/dialogues/CopyEntityDialog";
import DiscardEntityDialog from "../../components/dialogues/DiscardEntityDialog";
import useDialog from "../../hooks/useDialog";
import { RouteFor } from "../../lib/routes";
import { Language, translationKeys } from "../../translations/main-translations";

interface IMeasureActionsProps {
    isEditable: boolean;
    currentLanguage: Language;
    discardMeasure: (discardReason: number, discardStatement: string) => void;
    copyMeasure: (options: MeasureCopyOptionsList, title: string) => void;
    translate: TFunction;
    measure: MeasureDto;
    processName: string;
}

const MeasureActions = ({
    isEditable,
    currentLanguage,
    discardMeasure,
    translate,
    measure,
    processName,
    copyMeasure,
}: IMeasureActionsProps) => {
    const { isOpen: isDiscardDialogOpen, openDialog: openDiscardModal, closeDialog: closeDiscardModal } = useDialog();
    const { isOpen: isCopyDialogOpen, openDialog: openCopyModal, closeDialog: closeCopyModal } = useDialog();

    const actions = [
        <UnstyledNavLink key={RouteFor.measure.detailViewTab.feedHistory} to={RouteFor.measure.detailViewTab.feedHistory}>
            <MenuItem>
                <ListItemIcon>
                    <DynamicFeedIcon />
                </ListItemIcon>
                <ListItemText
                    primary={translate(translationKeys.VDLANG_MEASURE_TABS_FEED_HISTORY)}
                    primaryTypographyProps={{ variant: "body2" }}
                />
            </MenuItem>
        </UnstyledNavLink>,
        <MenuItem onClick={openCopyModal} key="copy">
            <ListItemIcon>
                <CopyIcon />
            </ListItemIcon>
            <ListItemText>{translate(translationKeys.VDLANG_ENTITY_ACTION_COPY, { entityName: translate(processName) })}</ListItemText>
        </MenuItem>,
    ];
    if (isEditable) {
        actions.push(
            <MenuItem onClick={openDiscardModal} key="discard">
                <ListItemIcon>
                    <DeleteIcon />
                </ListItemIcon>
                <ListItemText>{translate("discardEntity", { entityName: translate(processName) })}</ListItemText>
            </MenuItem>,
        );
    }

    return (
        <>
            <DiscardEntityDialog
                open={isDiscardDialogOpen}
                onClose={closeDiscardModal}
                onDiscard={discardMeasure}
                defaultReason={measure.fields[AttributeTitle.DiscardReason].value}
                defaultStatement={measure.fields[AttributeTitle.DiscardStatement].value}
                translate={translate}
                currentLanguage={currentLanguage}
                entityName={processName}
                isInMeasureContext={true}
            />

            <CopyEntityDialog
                open={isCopyDialogOpen}
                onClose={closeCopyModal}
                entityName={processName}
                options={MeasureCopyOptions}
                optionDefaults={{ "measure.attributes": true, "measure.activities": true }}
                currentTitle={measure.title}
                copyEntity={copyMeasure}
            />

            {actions.length > 0 ? (
                <MoreActionsMenu
                    title={translate(translationKeys.VDLANG_PROCESS_TOOLTIPS_FURTHER_OPTIONS)}
                    actions={actions}
                    buttonProps={{ size: "small" }}
                />
            ) : null}
        </>
    );
};
export default MeasureActions;
