import { Paper, styled } from "@mui/material";
import MeasureCommentList, { IMeasureCommentListProps } from "./MeasureCommentList";

const Root = styled(Paper)(({ theme }) => ({
    padding: theme.spacing(2),
    width: "100%",
}));

const MeasureComments = (props: IMeasureCommentListProps) => (
    <Root>
        <MeasureCommentList {...props} />
    </Root>
);
export default MeasureComments;
