import { Stack } from "@mui/material";
import { useTranslation } from "react-i18next";
import { useMeasureContext } from "../MeasureContext";
import GateList from "./GateList";
import MeasureHints from "./MeasureHints";

const GateListForCurrentMeasure = () => {
    const { t: translate } = useTranslation();
    const measure = useMeasureContext();
    return (
        <Stack spacing={2}>
            <MeasureHints measure={measure} translate={translate} />
            <GateList measure={measure} />
        </Stack>
    );
};
export default GateListForCurrentMeasure;
