import { useCallback, useEffect, useMemo, useState } from "react";
import usePrevious from "../hooks/usePrevious";

let internalId = 0;

interface IdObject {
    id: number;
}

export interface IUsePendingItemsResult<Item> {
    items: Item[];
    pendingItems: Item[];
    addPendingItem: (partialObject: Omit<Partial<Item>, "id">) => void;
    removePendingItem: (id?: number) => void;
}

function usePendingItems<Item extends IdObject>(items: Item[]) {
    const [pendingItems, setPendingItems] = useState<Item[]>([]);
    const previousItems = usePrevious(items);

    useEffect(() => {
        if (items != null && previousItems != null && items.length > previousItems.length) {
            // when new items arrive, remove first numOfNewItems pending items
            const numOfNewItems = items.length - previousItems.length;
            setPendingItems((oldPendingItems) => oldPendingItems.slice(numOfNewItems));
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [items]);

    const addItem = useCallback((partialObject: Omit<Partial<Item>, "id">) => {
        internalId--;
        const newItem = { ...partialObject, id: internalId } as Item;
        setPendingItems((oldPendingItems) => [...oldPendingItems, newItem]);
    }, []);

    const removeItem = useCallback((id?: number) => {
        setPendingItems((oldPentingItems) => (id != null ? oldPentingItems.filter((i: Item) => i.id !== id) : oldPentingItems.slice(1)));
    }, []);

    const augmentedList = useMemo(
        () => (items != null && pendingItems.length > 0 ? [...items, ...pendingItems] : (items ?? [])),
        [items, pendingItems],
    );

    return {
        items: augmentedList,
        pendingItems: pendingItems,
        addPendingItem: addItem,
        removePendingItem: removeItem,
    };
}

export default usePendingItems;
