import { Divider, Paper, Stack, styled } from "@mui/material";
import {
    AclNamespaces,
    AclPermissions,
    DecisionResult,
    DecisionType,
    FeatureFlags,
    GateStatus,
    MeasureConfigDto,
    UserStatus,
} from "api-shared";
import React, { Children, ReactNode, useMemo } from "react";
import { useTranslation } from "react-i18next";
import LoadingAnimation from "../../components/loading/LoadingAnimation";
import { useClientHasFeature } from "../../domain/client";
import { useDecisions } from "../../domain/decision";
import { useEffectCategories } from "../../domain/effect-category";
import {
    IUpdateGateTaskDto,
    useCompleteGateTaskMutation,
    useGateTaskIsActive,
    usePredecessorGateTask,
    useUpdateGateTaskMutation,
} from "../../domain/gatetasks";
import { useHasSuccessorGateTaskConfig } from "../../domain/measure-config";
import { useCurrentUserCanEditMeasure, useCurrentUserIsResponsible, useMeasureFields } from "../../domain/measure/detail";
import { useMeasureEditorsQuery, useMeasurePermissionsQuery } from "../../domain/measure/permission";
import { useUserHasPermissionQuery, useUsersHavingPermissionQuery } from "../../domain/permissions";
import { useAllUsers, useCurrentUserId, useUser } from "../../domain/users";
import { useMeasureContext } from "../MeasureContext";
import { completionHintClass } from "./GateCompletionHint";
import GateFooter from "./GateFooter";
import GateHeader from "./GateHeader";

export interface GateTaskUpdateDto {
    startDate: string | null;
    startRemindAt: string | null;
    duedate: string | null;
    remindAt: string | null;
    assignedToId: number | null;
}

const Root = styled(Paper)(({ theme }) => ({
    [theme.breakpoints.down("sm")]: {
        borderRadius: 0,
        boxShadow: "none",
        borderTop: `1px solid ${theme.palette.divider}`,
        borderBottom: `1px solid ${theme.palette.divider}`,
    },
    [`&:hover .${completionHintClass}`]: {
        opacity: 1,
        transition: theme.transitions.create("opacity", { duration: theme.transitions.duration.leavingScreen }),
    },
    "@media (hover: none)": {
        [`& .${completionHintClass}`]: {
            opacity: 1,
            transition: theme.transitions.create("opacity", { duration: theme.transitions.duration.leavingScreen }),
        },
    },
}));

export interface IGateProps {
    gateTaskConfigId: number;
    children: ReactNode;
    gateIndex: number;
    name: string;
    measureConfig: MeasureConfigDto;
}

const Gate = ({ children, gateIndex, name, gateTaskConfigId, measureConfig }: IGateProps) => {
    const measure = useMeasureContext();

    const { id: measureId, invalidProperties, measureConfigId } = measure;

    const gateTaskConfig = measureConfig.gateTaskConfigs.find(({ id }) => id === gateTaskConfigId);
    const gateTask = measure.gateTasks.find((gt) => gt.gateTaskConfigId === gateTaskConfigId);

    const measureFields = useMeasureFields(measure);
    const currentUserCanEditMeasure = useCurrentUserCanEditMeasure(measure);
    const isActive = useGateTaskIsActive(measure.gateTasks, measureConfigId, gateTaskConfigId);
    const predecessor = usePredecessorGateTask(measure.gateTasks, measureConfigId, gateTaskConfigId);
    const editorsQuery = useMeasureEditorsQuery(measure.id);
    const assignedTo = useUser(gateTask?.assignedToId ?? null) ?? null;
    const allUsers = useAllUsers();
    const activeUsers = allUsers.filter((u) => u.status === UserStatus.STATUS_ACTIVE);
    const currentUserId = useCurrentUserId();
    const userIsMeasureOwner = useCurrentUserIsResponsible(measure);
    const hasCompletionPermissionQuery = useUserHasPermissionQuery({
        permission: AclPermissions.Complete,
        fact: { id: measureId },
        namespace: AclNamespaces.GateTask,
    });

    const decisionsQuery = useDecisions(measureId);
    const decision = decisionsQuery.data?.find((d) => d.gateTaskId === gateTask?.id) ?? null;
    const firstGateTaskConfig = measureConfig.gateTaskConfigs.reduce(
        (previous, current) => (previous.order < current.order ? previous : current),
        measureConfig.gateTaskConfigs[0] ?? null,
    );
    const fields = useMemo(
        () => measureFields.filter((x) => x.gateTaskConfigId === gateTaskConfigId && x.isVisible !== false),
        [measureFields, gateTaskConfigId],
    );

    const hasSuccessorGateTask = useHasSuccessorGateTaskConfig(measureConfigId, gateTaskConfigId);
    const deciderId = decision !== null ? decision?.deciderId : null;
    const decider = useUser(deciderId);
    const decidersQuery = useMeasurePermissionsQuery({ permission: AclPermissions.Decide, measureId: measure.id });
    const usersHavingValuestreamPermissionQuery = useUsersHavingPermissionQuery({
        namespace: AclNamespaces.Valuestream,
        permission: AclPermissions.Read,
        fact: { id: measure.measureConfigId },
    });
    const hasSelfDecisionPermissionQuery = useUserHasPermissionQuery({
        namespace: AclNamespaces.Process,
        permission: AclPermissions.SelfDecision,
        fact: {},
    });

    const effectCategories = useEffectCategories(measureId).data;
    const updateGateTaskMutation = useUpdateGateTaskMutation();
    const completeGateTaskMutation = useCompleteGateTaskMutation();

    const { t: translate } = useTranslation();

    const hasRestrictedCompletionFeature = useClientHasFeature(FeatureFlags.FEATURE_RESTRICTED_EFFECTIVENESS_COMPLETION);

    const predecessorCompletedAt = predecessor?.completedAt ?? null;

    if (gateTask == null || gateTaskConfig == null || hasSuccessorGateTask == null) {
        return null;
    }

    const isDiscard = decision !== null && !decision?.isApproved;
    const canMakePublic =
        gateTaskConfig != null &&
        firstGateTaskConfig != null &&
        gateTaskConfig.id === firstGateTaskConfig.id &&
        userIsMeasureOwner &&
        gateTask.status === GateStatus.STATUS_OPEN;

    const isDecisionRequestedFromOtherUser =
        decision !== null && decision.selection === DecisionType.TYPE_REQUEST && decision.deciderId !== currentUserId;

    const handleCompletion = () => {
        completeGateTaskMutation.mutate({
            gateTaskId: gateTask.id,
            decision: isDiscard ? DecisionResult.RESULT_DISCARD : undefined,
        });
    };

    const updateAssignedTo = (userId: number | null) => {
        updateGateTaskMutation.mutate({ gateTaskId: gateTask.id, assignedToId: userId });
    };

    if (
        !editorsQuery.isSuccess ||
        !decidersQuery.isSuccess ||
        !hasCompletionPermissionQuery.isSuccess ||
        !hasSelfDecisionPermissionQuery.isSuccess ||
        !usersHavingValuestreamPermissionQuery.isSuccess
    ) {
        return <LoadingAnimation />;
    }

    const canSelfDecide =
        hasSelfDecisionPermissionQuery.data.hasPermission && measure.assignedToId === deciderId && measure.assignedToId !== null;

    const hasCompletionPermission = hasCompletionPermissionQuery.data.hasPermission;
    const canCompleteEffectiveness = !hasRestrictedCompletionFeature || hasCompletionPermission;
    const isAllowedToComplete = hasSuccessorGateTask || canCompleteEffectiveness;

    const assignableUsers = activeUsers.filter((u) => {
        return (
            editorsQuery.data.combinedUserIds.includes(u.id) && usersHavingValuestreamPermissionQuery.data.combinedUserIds.includes(u.id)
        );
    });
    const deciders = activeUsers.filter(
        (u) =>
            decidersQuery.data.combinedUserIds.includes(u.id) && usersHavingValuestreamPermissionQuery.data.combinedUserIds.includes(u.id),
    );
    // This can be simplified to just check the deciderId instead of querying all data of all users.
    // However this is done in the DecisionGate anyhow and this will be refactored with decision 2.0 so don't touch this for now.
    const userCanDecide = decider !== undefined ? deciders.some((d) => d.id === decider?.id) : true;
    const canCompleteDecision = canSelfDecide || userCanDecide;

    return (
        <Root data-testid={name}>
            <Stack divider={<Divider />}>
                <GateHeader
                    gateTask={gateTask}
                    gateTaskConfig={gateTaskConfig}
                    gateIndex={gateIndex}
                    isActive={isActive}
                    translate={translate}
                    disabled={!currentUserCanEditMeasure}
                    updateGateTask={(change: Omit<IUpdateGateTaskDto, "gateTaskId">) =>
                        updateGateTaskMutation.mutate({ gateTaskId: gateTask.id, ...change })
                    }
                    assignableUsers={assignableUsers}
                    updateAssignedTo={updateAssignedTo}
                    assignedTo={assignedTo}
                    name={name}
                    hintText={undefined}
                />
                {React.Children.only(
                    React.cloneElement(Children.toArray(children)[0] as any, {
                        fields,
                        gateTask: gateTask,
                        measureId: measureId,
                        name: name,
                    }),
                )}
                <GateFooter
                    canMakePublic={canMakePublic}
                    disabled={!currentUserCanEditMeasure || isDecisionRequestedFromOtherUser}
                    gateTask={gateTask}
                    allGateTasks={measure.gateTasks}
                    fields={measureFields}
                    translate={translate}
                    users={allUsers}
                    completeGate={handleCompletion}
                    invalidProperties={invalidProperties}
                    effectCategories={effectCategories ?? []}
                    measureNotEditable={!currentUserCanEditMeasure}
                    predecessorCompletedAt={predecessorCompletedAt}
                    isActive={isActive}
                    decision={decision}
                    userCanDecide={canCompleteDecision}
                    gateTaskConfigs={measureConfig.gateTaskConfigs}
                    isAllowedToComplete={isAllowedToComplete}
                    isGateRequestInProgress={completeGateTaskMutation.isLoading || completeGateTaskMutation.isSuccess}
                />
            </Stack>
        </Root>
    );
};
export default React.memo(Gate);
