import { Stack, Typography } from "@mui/material";
import { CurrencyDto } from "api-shared";
import Effect from "../../../components/Effect";

interface IEffectCategoryEffectProps {
    title: string;
    value: number;
    currency: CurrencyDto;
}

const EffectCategoryEffect = ({ title, value, currency }: IEffectCategoryEffectProps) => {
    const isEmpty = value == null;
    const textColor = isEmpty ? "text.disabled" : undefined;
    return (
        <Stack alignItems="flex-end" spacing={0.5}>
            <Typography color={textColor}>{title}</Typography>
            <Typography color={textColor}>
                <strong>{!isEmpty ? <Effect value={value} currency={currency} /> : "-"}</strong>
            </Typography>
        </Stack>
    );
};

export default EffectCategoryEffect;
