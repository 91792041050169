import { Breadcrumbs, BreadcrumbsProps, Link, Typography } from "@mui/material";
import { ValueLeverDto } from "api-shared";
import { TFunction } from "i18next";
import { translationKeys } from "../../translations/main-translations";

interface ILeverHelpBreadcrumbsProps extends Omit<BreadcrumbsProps, "translate"> {
    translate: TFunction;
    clientName: string;
    valueLever?: ValueLeverDto | null;
    onClose: () => void;
}
const LeverHelpBreadcrumbs = ({ translate, clientName, valueLever, onClose, ...breadcrumbsProps }: ILeverHelpBreadcrumbsProps) => {
    const parent =
        valueLever != null ? (
            // render as button because link does not have href, see: https://github.com/jsx-eslint/eslint-plugin-jsx-a11y/blob/master/docs/rules/anchor-is-valid.md
            <Link variant="body1" color="inherit" component="button" onClick={onClose}>
                {translate(translationKeys.VDLANG_METHOD_GUIDE_MAIN_TITLE)}
            </Link>
        ) : null;
    const activeItemLabel =
        valueLever != null
            ? valueLever.code === "XX"
                ? clientName
                : translate(valueLever.alias, { code: valueLever.code })
            : translate(translationKeys.VDLANG_METHOD_GUIDE_MAIN_TITLE);

    return (
        <Breadcrumbs aria-label={translate(translationKeys.VDLANG_BREADCRUMB)} {...breadcrumbsProps}>
            {parent}
            <Typography variant="body1" color="textPrimary" fontWeight="medium">
                {activeItemLabel}
            </Typography>
        </Breadcrumbs>
    );
};

export default LeverHelpBreadcrumbs;
