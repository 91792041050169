import ChevronRightRoundedIcon from "@mui/icons-material/ChevronRightRounded";
import SupervisorAccountIcon from "@mui/icons-material/SupervisorAccount";
import { Button, IconButton, Stack, StackProps } from "@mui/material";
import { AclNamespaces, AclPermissions, MeasureCopyOptionsList, UserDto } from "api-shared";
import { uniq } from "lodash";
import React, { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { UserGroupPermissionsDialog } from "../../components/usergrouppermissions/UserGroupPermissionsDialog";
import { useGetMeasureConfigsPermissions } from "../../domain/measure-config";
import { useCreateMeasure, useCurrentUserCanEditMeasure, useDiscardMeasure } from "../../domain/measure/detail";
import {
    useMeasurePermissionsAddGroupMutation,
    useMeasurePermissionsAddUserMutation,
    useMeasurePermissionsQuery,
    useMeasurePermissionsRemoveGroupMutation,
    useMeasurePermissionsRemoveUserMutation,
} from "../../domain/measure/permission";
import { useInviteContributor } from "../../domain/measure/useInviteContributor";
import { useUsersHavingAccessPermissionQuery } from "../../domain/permissions";
import { useAllUsers, useCurrentUserId } from "../../domain/users";
import useDialog from "../../hooks/useDialog";
import { useLanguage } from "../../hooks/useLanguage";
import { trackEvent } from "../../infrastructure/tracking";
import { useIsDesktop } from "../../lib/mobile";
import { noOp } from "../../lib/utils";
import { translationKeys } from "../../translations/main-translations";
import { useMeasureContext } from "../MeasureContext";
import MeasureActions from "./MeasureActions";
import MeasureNotificationsMenu from "./MeasureNotificationsMenu";
import AccessControlDialog from "./access-control/AccessControlDialog";

interface MeasureSettingsProps extends StackProps {
    dense?: boolean;
    closeMenu?: () => void;
}

function MeasureSettings({ dense, closeMenu, ...stackProps }: Readonly<MeasureSettingsProps>) {
    const navigate = useNavigate();
    const isDesktop = useIsDesktop();
    const measure = useMeasureContext();
    const discardMeasureMutation = useDiscardMeasure();
    const createMeasureMutation = useCreateMeasure(({ id }) => navigate(`/measure/${id}`));
    const currentUserCanEdit = useCurrentUserCanEditMeasure(measure);
    const { t: translate } = useTranslation();
    const currentLanguage = useLanguage();
    const measurePermissionsAddGroup = useMeasurePermissionsAddGroupMutation();
    const measurePermissionsAddUser = useMeasurePermissionsAddUserMutation();
    const measurePermissionsDeleteGroup = useMeasurePermissionsRemoveGroupMutation();
    const measurePermissionsDeleteUser = useMeasurePermissionsRemoveUserMutation();
    const userGroupPermissionsDialog = useDialog();
    const currentUserId = useCurrentUserId();

    function handleDiscardMeasure(reason: number, statement: string) {
        discardMeasureMutation.mutate({ measureId: measure.id, reason, statement });
    }

    function handleCopyMeasure(options: MeasureCopyOptionsList, title: string) {
        trackEvent({ category: "Measure", action: "Measure Copy", name: options.join(",") });

        createMeasureMutation.mutate({
            title,
            measureConfigId: measure.measureConfigId,
            sourceMeasureId: measure.id,
            copyOptions: options,
        });
    }

    const permissionsRead = useMeasurePermissionsQuery({
        permission: AclPermissions.Read,
        measureId: measure.id,
    });

    const permissionsWrite = useMeasurePermissionsQuery({
        permission: AclPermissions.Update,
        measureId: measure.id,
    });

    const permissions = useMemo(
        () => ({
            combinedUserIds: [...(permissionsRead.data?.combinedUserIds ?? []), ...(permissionsWrite.data?.combinedUserIds ?? [])],
            userIds: [
                ...(permissionsRead.data?.userAcls.map((acl) => acl.userId) ?? []),
                ...(permissionsWrite.data?.userAcls.map((acl) => acl.userId) ?? []),
            ],
            groupIds: [
                ...(permissionsRead.data?.groupAcls.map((acl) => acl.groupId) ?? []),
                ...(permissionsWrite.data?.groupAcls.map((acl) => acl.groupId) ?? []),
            ],
            userAcls: [...(permissionsRead.data?.userAcls ?? []), ...(permissionsWrite.data?.userAcls ?? [])],
            groupAcls: [...(permissionsRead.data?.groupAcls ?? []), ...(permissionsWrite.data?.groupAcls ?? [])],
        }),
        [permissionsRead.data, permissionsWrite.data],
    );

    const isMeasureResponsible = measure?.assignedToId === currentUserId;

    const getMeasureConfigsPermissionsQuery = useGetMeasureConfigsPermissions({
        permission: AclPermissions.Read,
        measureConfigId: measure?.measureConfig.id ?? -1,
        enabled: measure !== undefined,
    });

    const allUsers = useAllUsers();

    const inviteUserMutation = useInviteContributor();

    const havingProcessAccessPermissionQuery = useUsersHavingAccessPermissionQuery(AclNamespaces.Process);

    if (!getMeasureConfigsPermissionsQuery.isSuccess || !havingProcessAccessPermissionQuery.isSuccess) {
        return null;
    }

    const filterAddableUsersPre = (u: UserDto) => havingProcessAccessPermissionQuery.data.combinedUserIds.some((id) => u.id === id);

    const mergeAllGroupsAndUsersToUsers = () => {
        if (!getMeasureConfigsPermissionsQuery.isSuccess) {
            return [];
        }

        // We want all users that have access to the measure config via direct access or groups
        // However, we only want to show users that have the minimum permissions to access the measure
        // This means access permission and user tier that allows access to the measure
        const filteredUsers = allUsers.filter(filterAddableUsersPre);
        const filteredUserIds = new Set(filteredUsers.map((u) => u.id));
        const allUsersWithConfigAccess = [...getMeasureConfigsPermissionsQuery.data.combinedUserIds];

        const userIds = allUsersWithConfigAccess.filter((id) => filteredUserIds.has(id));
        return uniq(userIds);
    };

    const allGroupsAndUsersToUsers = mergeAllGroupsAndUsersToUsers();
    const filterAddableUsers = (u: UserDto) => allGroupsAndUsersToUsers.includes(u.id);

    const onInviteClick = (email: string) => {
        inviteUserMutation.mutate({ email, metadata: { measureId: measure.id } });
    };

    const handleOpenAccessDialog = () => {
        trackEvent({ category: "Measure", action: "Access Dialog Opened" });

        userGroupPermissionsDialog.open();
    };

    return (
        <Stack direction="row" alignItems="center" {...stackProps}>
            <AccessControlDialog />
            {permissionsRead.data !== undefined && permissionsWrite.data !== undefined && measure !== undefined ? (
                <UserGroupPermissionsDialog
                    open={userGroupPermissionsDialog.isOpen}
                    onClose={userGroupPermissionsDialog.close}
                    permissions={permissions}
                    entityId={measure.id}
                    ownerId={measure.assignedToId ?? undefined}
                    allowUpdatePermission={true}
                    filterAddableUsers={filterAddableUsers}
                    addGroup={(e, g, p) => measurePermissionsAddGroup.mutate({ entityId: e, id: g, permission: p })}
                    addUser={(e, g, p) => measurePermissionsAddUser.mutate({ entityId: e, id: g, permission: p })}
                    deleteGroup={(e, g) => measurePermissionsDeleteGroup.mutate({ entityId: e, id: g })}
                    deleteUser={(e, g) => measurePermissionsDeleteUser.mutate({ entityId: e, id: g })}
                    readonly={!isMeasureResponsible}
                    showInvite={true}
                    onInviteClick={onInviteClick}
                />
            ) : null}
            {measure !== undefined && (
                <Button
                    size="small"
                    startIcon={<SupervisorAccountIcon fontSize="medium" />}
                    color="primary"
                    variant="contained"
                    onClick={handleOpenAccessDialog}
                    sx={{ alignSelf: "stretch" }}
                >
                    {translate(translationKeys.VDLANG_MEASURE_ACCESS_CONTROL_BUTTON)}
                </Button>
            )}
            <MeasureNotificationsMenu measureId={measure.id} translate={translate} />
            <MeasureActions
                isEditable={currentUserCanEdit}
                currentLanguage={currentLanguage}
                discardMeasure={handleDiscardMeasure}
                translate={translate}
                measure={measure}
                processName={measure.measureConfig.name}
                copyMeasure={handleCopyMeasure}
            />
            {!isDesktop ? (
                <IconButton onClick={closeMenu ?? noOp}>
                    <ChevronRightRoundedIcon />
                </IconButton>
            ) : null}
        </Stack>
    );
}

export default React.memo(MeasureSettings);
