import { Alert, Divider } from "@mui/material";
import { ClientDto, EffectType, type EffectCategoryFields } from "api-shared";
import { TFunction } from "i18next";
import FieldList from "../../../components/FieldList";
import Form from "../../../components/Form";
import { useCurrencies } from "../../../domain/currencies";
import type { FilledField } from "../../../lib/fields";
import { Language, translationKeys } from "../../../translations/main-translations";
import CalculationForm from "./effect-category/CalculationForm";
import CurrencyForm from "./effect-category/CurrencyForm";

interface IEffectCategoryFormProps {
    translate: TFunction;
    categoryFields: FilledField[];
    updateCategoryFields: (changes: EffectCategoryFields) => void;
    processName: string;
    measureId: number;
    client: ClientDto;
    onSubmit: () => void;
    lang: Language;
    disabled?: boolean;
    effectType: EffectType;
    showLinearAlert: boolean;
    showCategoryFields: boolean;
    showCalculationFields: boolean;
    currencyId: number;
    currencyUpdated: (newValue: number) => void;
}

const EffectCategoryForm = ({
    translate,
    updateCategoryFields,
    processName,
    client,
    categoryFields,
    onSubmit,
    lang,
    disabled = false,
    effectType,
    showLinearAlert,
    showCategoryFields: showCategoryFieldsProps,
    showCalculationFields,
    measureId,
    currencyId,
    currencyUpdated,
}: IEffectCategoryFormProps) => {
    const showCategoryFields = categoryFields.length > 0 && showCategoryFieldsProps;
    const currencies = useCurrencies();

    return (
        <Form onSubmit={onSubmit}>
            {showCategoryFields && (
                <>
                    <FieldList
                        client={client}
                        translate={translate}
                        // updateFieldHandler is incorrectly coupled to MeasureUpdateDto which is not correct in this context
                        updateFieldHandler={(changes) => updateCategoryFields(changes as EffectCategoryFields)}
                        fields={categoryFields}
                        preSelectOnlyValue={true}
                        processName={processName}
                        lang={lang}
                        disabled={disabled}
                        isClearable={false}
                        menuPortalTarget={document.body}
                        measureId={measureId}
                    />

                    {currencies.length > 1 && (
                        <CurrencyForm currencyId={currencyId} currencyUpdated={currencyUpdated} disabled={disabled} />
                    )}
                </>
            )}
            {showCategoryFields && showCalculationFields && <Divider sx={{ my: 2, mx: -3 }} />}
            {showLinearAlert && showCalculationFields && (
                <Alert severity="warning" sx={{ mt: 3, mb: 2 }}>
                    {translate(translationKeys.VDLANG_GENERATION_LINEAR_TABLE_HINT)}
                </Alert>
            )}
            {showCalculationFields && <CalculationForm disabled={disabled} effectType={effectType} />}
        </Form>
    );
};

export default EffectCategoryForm;
