import ClearRoundedIcon from "@mui/icons-material/ClearRounded";
import DoneRoundedIcon from "@mui/icons-material/DoneRounded";
import { Link, Typography, styled } from "@mui/material";
import { DecisionType, MeasureConfigDto, UserDto } from "api-shared";
import { TFunction } from "i18next";
import useDialog from "../../../hooks/useDialog";
import useTimezone from "../../../hooks/useTimezone";
import { formatUser, formatUserFromId } from "../../../lib/formatters";
import { translationKeys } from "../../../translations/main-translations";
import DecisionStep from "./DecisionStep";
import DecisionCommentDialog from "./DecisionCommentDialog";

const RootDecisionStep = styled(DecisionStep)(({ theme }) => ({
    color: theme.palette.common.white,
    flexGrow: 1,
    flexBasis: "25%",
}));

const DecisionCommentLink = styled(Link)(({ theme }) => ({
    color: "inherit",
    textDecoration: "underline",
    cursor: "pointer",
    marginLeft: theme.spacing(0.5),
}));

const SELF_DECISION = "SELF_DECISION";

const Messages = {
    [DecisionType.TYPE_DOCUMENT]: [
        translationKeys.VDLANG_DECISION_COMPLETED_NEGATIVE_DOCUMENTED,
        translationKeys.VDLANG_DECISION_COMPLETED_POSITIVE_DOCUMENTED,
    ],
    [DecisionType.TYPE_REQUEST]: [
        translationKeys.VDLANG_DECISION_COMPLETED_NEGATIVE_REQUESTED,
        translationKeys.VDLANG_DECISION_COMPLETED_POSITIVE_REQUESTED,
    ],
    [SELF_DECISION]: [translationKeys.VDLANG_DECISION_COMPLETED_NEGATIVE, translationKeys.VDLANG_DECISION_COMPLETED_POSITIVE],
};

interface IDecisionFinishStepProps {
    isApproved: boolean;
    completedAt: Date;
    decider: UserDto;
    translate: TFunction;
    measureConfig: MeasureConfigDto;
    selection: DecisionType;
    users: UserDto[];
    completedById: number;
    requesterId: number | null;
    requestedDeciderId: number | null;
    decisionReason: string | null;
}

const DecisionFinishStep = ({
    isApproved,
    completedById,
    completedAt,
    decider,
    translate,
    measureConfig,
    selection,
    requestedDeciderId,
    requesterId,
    users,
    decisionReason,
}: IDecisionFinishStepProps) => {
    const { formatDate, formatTime } = useTimezone();
    const commentDialog = useDialog();

    const deciderName = decider != null ? formatUser(decider, { translate }) : "";
    const completedByName = formatUserFromId(completedById, users, { translate });
    const requesterName = requesterId != null ? formatUserFromId(requesterId, users, { translate }) : "";

    const date = completedAt != null ? formatDate(completedAt) : "";
    const time = completedAt != null ? formatTime(completedAt) : "";
    const Icon = isApproved ? DoneRoundedIcon : ClearRoundedIcon;

    let messageType: keyof typeof Messages = SELF_DECISION;
    if (selection === DecisionType.TYPE_REQUEST && requestedDeciderId === decider?.id) {
        // Show only as request, if decider and selection have not been changed after the request
        messageType = DecisionType.TYPE_REQUEST;
    }
    if (selection === DecisionType.TYPE_DOCUMENT && completedById !== decider?.id) {
        // Only show as documented, if selection has not changed and the decision was completed by a different user that the decider
        messageType = DecisionType.TYPE_DOCUMENT;
    }
    const message = Messages[messageType][isApproved ? 1 : 0];

    const variant = isApproved ? "success" : "error";
    const processName = measureConfig.name;

    return (
        <>
            <DecisionCommentDialog
                open={commentDialog.isOpen}
                onClose={commentDialog.close}
                users={users}
                deciderId={decider.id}
                decisionReason={decisionReason ?? ""}
            />
            <RootDecisionStep item variant={variant} alignItems="center" noArrow>
                <Icon sx={{ fontSize: "6rem" }} />
                <Typography variant="caption">
                    {translate(message, {
                        decider: deciderName,
                        completedBy: completedByName,
                        requester: requesterName,
                        date,
                        time,
                        processName: translate(processName),
                    })}
                    {decisionReason !== null && decisionReason !== "" && (
                        <DecisionCommentLink onClick={commentDialog.open}>
                            {translate(translationKeys.VDLANG_DECISION_COMPLETED_SHOW_COMMENT)}
                        </DecisionCommentLink>
                    )}
                </Typography>
            </RootDecisionStep>
        </>
    );
};

export default DecisionFinishStep;
