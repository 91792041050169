import { useMutation, useQueryClient } from "@tanstack/react-query";
import { AclPermissions, CreateUserRequestBody, UserDto } from "api-shared";
import { useDispatch } from "react-redux";
import { showNotificationEvent } from "../../infrastructure/notifications";
import { apiPost } from "../../lib/api";
import { NotificationType } from "../../lib/notifications";
import { FeedbackTranslationKeys } from "../../translations/notification-translations";
import { UsersQueryKeys } from "../users";
import { useMeasurePermissionsAddUserMutation } from "./permission";

export const useInviteContributor = () => {
    const addMeasureUserMutation = useMeasurePermissionsAddUserMutation();
    const queryClient = useQueryClient();
    const dispatch = useDispatch();
    return useMutation({
        mutationFn: (data: CreateUserRequestBody) => apiPost<UserDto>("users", data),
        onSuccess: (response, createUser) => {
            queryClient.invalidateQueries(UsersQueryKeys.list());
            addMeasureUserMutation.mutate({
                entityId: createUser.metadata.measureId,
                id: response.id,
                permission: AclPermissions.Update,
            });
            dispatch(showNotificationEvent(NotificationType.SUCCESS, FeedbackTranslationKeys.VDLANG_FEEDBACK_USER_INVITED));
        },
    });
};
