import { Box, Stack, styled } from "@mui/material";
import { CurrencyDto, EffectField, EffectTotalValues, mergeCamelized } from "api-shared";
import { sortBy } from "lodash";
import { useTranslation } from "react-i18next";
import { translationKeys } from "../../../translations/main-translations";
import EffectCategoryEffect from "./EffectCategoryEffect";

const Root = styled("div")(() => ({
    maxWidth: "100%",
    overflow: "hidden", // let scrollContainer also scroll in IE
}));

const HorizontalScrollContainer = styled("div", { shouldForwardProp: (name) => name !== "noData" && name !== "isEmpty" })<{
    isEmpty: boolean;
}>(({ theme, isEmpty }) => ({
    padding: theme.spacing(2, 2, 2.5),
    overflowX: "auto",
    ...(isEmpty && {
        padding: theme.spacing(3),
        color: theme.palette.text.disabled,
    }),
}));

interface EffectItem {
    title: string;
    value: any;
}

interface IEffectCategoryDetailsProps {
    calculationIdentifier: string | null;
    currency: CurrencyDto;
    effectValues: EffectTotalValues;
    isSummary: boolean;
}
const EffectCategoryDetails = ({ calculationIdentifier, currency, effectValues, isSummary }: IEffectCategoryDetailsProps) => {
    const { t } = useTranslation();

    const effectItems: EffectItem[] = [
        {
            title: t(isSummary ? mergeCamelized("total", calculationIdentifier, EffectField.Initial) : EffectField.Initial),
            value: effectValues?.initial,
        },
        {
            title: t(isSummary ? mergeCamelized("total", calculationIdentifier, EffectField.Target) : EffectField.Target),
            value: effectValues?.target,
        },
        {
            title: t(isSummary ? mergeCamelized("total", calculationIdentifier, EffectField.PriceHike) : EffectField.PriceHike),
            value: effectValues?.priceHike,
        },
        {
            title: t(isSummary ? mergeCamelized("total", calculationIdentifier, "costAvoidance") : "costAvoidance"),
            value: effectValues?.costAvoidance,
        },
        ...sortBy(effectValues?.effectPerFiscalYear ?? [], "year").map((effect) => ({
            title: `${t("FY")} ${effect.year}`,
            value: effect.value,
        })),
    ];

    const nonEmptyEffectItems = effectItems.filter((item) => item.value != null);

    const hasData = nonEmptyEffectItems.length > 0;
    return (
        <Root>
            <HorizontalScrollContainer isEmpty={!hasData}>
                <Stack direction="row">
                    {hasData ? (
                        nonEmptyEffectItems
                            .filter((item) => item.value != null)
                            .map((item) => (
                                <Box flexGrow={1} flexShrink={0} px={2} key={item.title}>
                                    <EffectCategoryEffect {...item} currency={currency} />
                                </Box>
                            ))
                    ) : (
                        <span>{t(translationKeys.VDLANG_NO_CALCULATION_DATA)}</span>
                    )}
                </Stack>
            </HorizontalScrollContainer>
        </Root>
    );
};

export default EffectCategoryDetails;
