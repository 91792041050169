import ChevronLeftIcon from "@mui/icons-material/ChevronLeftRounded";
import ChevronRightIcon from "@mui/icons-material/ChevronRightRounded";
import {
    Button,
    List,
    ListItemButton,
    listItemButtonClasses,
    ListItemIcon,
    ListItemText,
    ListSubheader,
    listSubheaderClasses,
    Stack,
    styled,
    Typography,
} from "@mui/material";
import { CostLeverDto, FeatureFlags, MethodSegment, nonNullable, ValueLeverDto } from "api-shared";
import { groupBy } from "lodash";
import { useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import ValueLeverIcon from "../../components/icons/method-types/ValueLeverIcon";
import SearchInput from "../../components/input/SearchInput";
import LoadingAnimation from "../../components/loading/LoadingAnimation";
import SidebarGutters from "../../components/sidebar/SidebarGutters";
import { useClientHasFeature, useClientName } from "../../domain/client";
import { useValueLevers } from "../../domain/endpoint";
import { useUpdateMeasure } from "../../domain/measure/detail";
import { useCostLeverSearch, useCostLeversQuery } from "../../domain/methods/cost-lever";
import { useDebounce } from "../../hooks/useDebounce";
import { useLanguage } from "../../hooks/useLanguage";
import { compareMethodByCode, compareValueLever } from "../../lib/sort";
import { translationKeys } from "../../translations/main-translations";
import { useMeasureContext } from "../MeasureContext";
import CostLeverList from "./CostLeverList";
import LeverHelpBreadcrumbs from "./LeverHelpBreadcrumbs";

const SectionHeader = styled(ListSubheader)(({ theme }) => ({
    [` .${listSubheaderClasses.gutters}`]: {
        paddingLeft: theme.spacing(3),
        paddingRight: theme.spacing(3),
    },
}));

const ValueLeverListItem = styled(ListItemButton)(({ theme }) => ({
    [` .${listItemButtonClasses.gutters}`]: {
        paddingLeft: theme.spacing(3),
        paddingRight: theme.spacing(3),
    },
}));

const LeverTypeSections: Record<number, string> = {
    1: "customer specific method",
    2: "purchasing measure",
    3: "product measure",
};

interface ILeverHelpProps {
    title: string | null;
    params: unknown;
    onClose: () => void;
}

const LeverHelp = ({ title, params, onClose }: ILeverHelpProps) => {
    const { t: translate } = useTranslation();
    const language = useLanguage();

    const valueLevers = useValueLevers();
    const costLeversQuery = useCostLeversQuery();
    const costLevers = costLeversQuery.data ?? [];
    const clientName = useClientName();
    const isMethodSectionEnabled = useClientHasFeature(FeatureFlags.FEATURE_METHOD_SECTION);

    const updateMeasureMutation = useUpdateMeasure();
    const measure = useMeasureContext();

    const [searchKey, setSearchKey] = useState("");
    const debouncedSearchKey = useDebounce(searchKey);

    const defaultCostLever = useMemo(
        () => (Array.isArray(params) && params[0] != null ? new Set([+params[0]]) : new Set<number>()),
        [params],
    );
    const [expandedCostlevers, setExpandedCostlevers] = useState(defaultCostLever);

    const defaultValueLever = useMemo(() => {
        if (defaultCostLever !== null) {
            const costLeversId = defaultCostLever.values().next().value;
            const costLever = (costLeversQuery.data ?? []).filter((cl) => cl.id === costLeversId);
            return costLever[0]?.valueLeverId;
        }
        return undefined;
    }, [costLeversQuery.data, defaultCostLever]);
    const [valueLeverId, setValueLeverId] = useState<number | undefined>(defaultValueLever);

    const searchQuery = useCostLeverSearch({ searchKey: debouncedSearchKey, valueLeverId });
    const searchResult = searchQuery.data ?? null;

    useEffect(() => {
        setValueLeverId(defaultValueLever);
    }, [defaultValueLever]);

    useEffect(() => {
        setExpandedCostlevers(defaultCostLever);
    }, [defaultCostLever]);

    const closeSection = () => setValueLeverId(undefined);

    const saveFieldValue = (value: number) => {
        if (title != null) {
            const changes = { [title]: value };
            updateMeasureMutation.mutate({ measureId: measure.id, changes });
        }
        onClose();
    };

    const updateExpandedCostlevers = (id: number, isOpen: boolean) => {
        setExpandedCostlevers((oldExpanded) => {
            const expandedCostlevers = new Set(oldExpanded);
            if (isOpen) {
                expandedCostlevers.add(id);
            } else {
                expandedCostlevers.delete(id);
            }
            return expandedCostlevers;
        });
    };

    const getContent = (visibleCostLevers: CostLeverDto[] | null) => {
        if (searchKey !== "" && visibleCostLevers == null) {
            return <LoadingAnimation />;
        }

        const selectedCostLever = Array.isArray(params) && params[0] != null ? +params[0] : null;
        return (
            <>
                {searchKey !== "" && visibleCostLevers != null ? (
                    <Typography color="textSecondary" sx={{ p: 3, pt: 1.5 }}>
                        {translate(translationKeys.VDLANG_METHOD_GUIDE_SEARCH_RESULTS, { count: visibleCostLevers.length })}
                    </Typography>
                ) : null}
                {visibleCostLevers != null ? (
                    <CostLeverList
                        expandedCostlevers={expandedCostlevers}
                        updateExpandedCostlevers={updateExpandedCostlevers}
                        selectedCostlever={selectedCostLever}
                        language={language}
                        costLevers={visibleCostLevers}
                        onSelect={saveFieldValue}
                        clientName={clientName}
                        isMethodSectionEnabled={isMethodSectionEnabled}
                        translate={translate}
                    />
                ) : null}
            </>
        );
    };

    const valueLeverSections = groupBy(valueLevers, (vl) => vl.segment);
    const valueLever = valueLevers.find(({ id }) => id === valueLeverId);

    let visibleCostLevers: CostLeverDto[] | null = [];
    if (searchKey !== "") {
        visibleCostLevers = searchResult?.map((id) => costLevers.find((cl) => cl.id === id)).filter(nonNullable) ?? null;
    } else if (valueLeverId != null) {
        visibleCostLevers = costLevers.filter((cl) => cl.valueLeverId === valueLeverId).sort(compareMethodByCode);
    }

    const showValueLevers = searchKey === "" && valueLeverId == null;

    const getValueLeverAlias = (item: ValueLeverDto) => {
        return item.code === "XX" ? clientName : translate(item.alias, { code: item.code });
    };

    const customValueLever = valueLevers.find((value) => value.code === "XX");
    const hasCustomCostLever = costLevers.find((costlever) => costlever.valueLeverId === customValueLever?.id);

    const section = Object.keys(valueLeverSections).filter((sectionKey) => {
        if (!hasCustomCostLever) {
            return +sectionKey !== MethodSegment.CUSTOM;
        }
        return +sectionKey;
    });

    return (
        <div>
            <SidebarGutters>
                <Stack spacing={2} pb={2} alignItems="flex-start">
                    <Button onClick={onClose} startIcon={<ChevronLeftIcon />} variant="text">
                        {translate(translationKeys.VDLANG_BACK)}
                    </Button>
                    <LeverHelpBreadcrumbs translate={translate} clientName={clientName} valueLever={valueLever} onClose={closeSection} />
                    <SearchInput searchKey={searchKey} onChange={setSearchKey} translate={translate} />
                </Stack>
            </SidebarGutters>
            <div>
                {showValueLevers
                    ? section.map((sectionKey) => (
                          <List
                              key={sectionKey}
                              subheader={<SectionHeader sx={{ px: 3 }}>{translate(LeverTypeSections[+sectionKey])}</SectionHeader>}
                              sx={{ pt: 1 }}
                          >
                              {[...valueLeverSections[sectionKey]].sort(compareValueLever).map((item) => (
                                  <ValueLeverListItem key={item.id} onClick={() => setValueLeverId(item.id)}>
                                      <ListItemIcon>
                                          <ValueLeverIcon type={item.alias} />
                                      </ListItemIcon>
                                      <ListItemText primary={getValueLeverAlias(item)} />
                                      <ChevronRightIcon />
                                  </ValueLeverListItem>
                              ))}
                          </List>
                      ))
                    : getContent(visibleCostLevers)}
            </div>
        </div>
    );
};

export default LeverHelp;
