import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { AclPermissions, DecisionDto, DecisionListDto, ErrorConstantKeys } from "api-shared";
import { useDispatch } from "react-redux";
import { showNotificationEvent } from "../infrastructure/notifications";
import { apiGet, apiPatch, apiPost } from "../lib/api";
import { NotificationType } from "../lib/notifications";
import { FeedbackTranslationKeys } from "../translations/notification-translations";
import { NotificationQueryKeys } from "./measure-notifications";
import { MeasureDetailQueryKeys } from "./measure/detail";
import { MeasurePermissionsQueryKeys } from "./measure/permission";

const DECISIONS_PATH = "decisions";

export const decisionKeys = {
    forMeasure: (measureId: number) => [`measures/${measureId}/decisions`],
};

export type UpdateChangesInput = Partial<Pick<DecisionDto, "deciderId" | "selection" | "reason" | "isApproved">>;
export interface IRequestDecisionInputDto {
    measureId: number;
    decisionId: number;
}

export interface IUpdateDecisionInputDto {
    measureId: number;
    decisionId: number;
    changes: UpdateChangesInput;
}

export const useDecisions = (measureId: number) => {
    return useQuery({
        queryKey: decisionKeys.forMeasure(measureId),
        queryFn: ({ signal }) => apiGet<DecisionListDto>(`measures/${measureId}/decisions`, { signal }),
    });
};

export const useCurrentDecision = (measureId: number) => {
    const decisions = useDecisions(measureId).data ?? [];
    const [currentDecision] = decisions;
    // The initial placeholder decision created alongside the measure has no decider id
    return currentDecision?.deciderId != null ? currentDecision : null;
};

export const useUpdateDecision = () => {
    const queryClient = useQueryClient();
    return useMutation({
        mutationFn: ({ decisionId, changes }: IUpdateDecisionInputDto) => apiPatch<DecisionDto>(`${DECISIONS_PATH}/${decisionId}`, changes),
        onSuccess: (response, { measureId }) => {
            queryClient.invalidateQueries(decisionKeys.forMeasure(measureId));
            queryClient.invalidateQueries(MeasureDetailQueryKeys.byId(measureId));
            queryClient.invalidateQueries(MeasurePermissionsQueryKeys.entity({ permission: AclPermissions.Read, measureId }));
            queryClient.invalidateQueries(MeasurePermissionsQueryKeys.entity({ permission: AclPermissions.Update, measureId }));
            queryClient.invalidateQueries(NotificationQueryKeys.forMeasure(measureId));
        },
        meta: {
            skipReportToSentry: ErrorConstantKeys.VDERROR_CONFLICT_DECISION_ALREADY_COMPLETED,
        },
    });
};

export const useRequestDecision = () => {
    const queryClient = useQueryClient();
    const dispatch = useDispatch();
    return useMutation({
        mutationFn: ({ decisionId }: IRequestDecisionInputDto) => apiPost<DecisionDto>(`${DECISIONS_PATH}/${decisionId}/request`),
        onSuccess: (response, { measureId }) => {
            queryClient.invalidateQueries(decisionKeys.forMeasure(measureId));
            queryClient.invalidateQueries(MeasureDetailQueryKeys.byId(measureId));
            queryClient.invalidateQueries(MeasurePermissionsQueryKeys.entity({ permission: AclPermissions.Read, measureId }));
            queryClient.invalidateQueries(MeasurePermissionsQueryKeys.entity({ permission: AclPermissions.Update, measureId }));
            queryClient.invalidateQueries(NotificationQueryKeys.forMeasure(measureId));
            dispatch(showNotificationEvent(NotificationType.SUCCESS, FeedbackTranslationKeys.VDLANG_FEEDBACK_DECISION_REQUESTED));
        },
        meta: {
            skipReportToSentry: ErrorConstantKeys.VDERROR_CONFLICT_DECISION_ALREADY_COMPLETED,
        },
    });
};
