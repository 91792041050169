import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { CalculationEffectListDto, EffectDeleteDto, EffectUpdateDto } from "api-shared";
import queryString from "query-string";
import { apiDelete, apiGet, apiPost } from "../lib/api";
import { effectCategoriesKeys } from "./effect-category";
import { MeasureDetailQueryKeys } from "./measure/detail";
import { ReportingQueryKeys } from "./reporting";

const EFFECTS_PATH = "effects";

export const effectKeys = {
    forMeasure: (measureId: number) => [`${EFFECTS_PATH}?${queryString.stringify({ measureId })}`],
};

export const useProcessEffects = (measureId: number) => {
    return useQuery({
        queryKey: effectKeys.forMeasure(measureId),
        queryFn: ({ signal }) => apiGet<CalculationEffectListDto>(`${EFFECTS_PATH}?${queryString.stringify({ measureId })}`, { signal }),
    });
};

export const useUpdateProcessEffects = () => {
    const queryClient = useQueryClient();
    return useMutation({
        mutationFn: ({ measureId, ...payload }: EffectUpdateDto & { measureId: number }) =>
            apiPost<CalculationEffectListDto>(EFFECTS_PATH, payload),
        onSuccess: (response, { measureId }) => {
            queryClient.invalidateQueries(effectKeys.forMeasure(measureId));
            queryClient.invalidateQueries(ReportingQueryKeys.all);
            queryClient.invalidateQueries(MeasureDetailQueryKeys.byId(measureId));
            queryClient.invalidateQueries(effectCategoriesKeys.forMeasure(measureId));
        },
    });
};

export const useDeleteProcessEffects = () => {
    const queryClient = useQueryClient();
    return useMutation({
        mutationFn: ({ measureId, ...payload }: EffectDeleteDto & { measureId: number }) => apiDelete(EFFECTS_PATH, payload),
        onSuccess: (response, { measureId }) => {
            queryClient.invalidateQueries(effectKeys.forMeasure(measureId));
            queryClient.invalidateQueries(ReportingQueryKeys.all);
            queryClient.invalidateQueries(MeasureDetailQueryKeys.byId(measureId));
            queryClient.invalidateQueries(effectCategoriesKeys.forMeasure(measureId));
        },
    });
};
