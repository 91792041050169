import { Alert, Collapse, Paper, Tab, Tabs, styled } from "@mui/material";
import { DecisionDto, DecisionResult, UserDto } from "api-shared";
import { TFunction } from "i18next";
import DiscardEntityForm from "../../../components/dialogues/DiscardEntityForm";
import MarkdownEditorUserMentionsMeasure from "../../../components/markdowneditor/MarkdownEditorUserMentionsMeasure";
import { Language } from "../../../translations/main-translations";
import DecisionRequestMessage from "./DecisionRequestMessage";
import DecisionStep from "./DecisionStep";

const decisionResultOrder = [DecisionResult.RESULT_IMPLEMENT, DecisionResult.RESULT_DISCARD];

const ScrollablePaper = styled(Paper)(({ theme }) => ({
    maxHeight: theme.spacing(20),
    overflowY: "auto",
    padding: theme.spacing(3),
}));

const RootDecisionStep = styled(DecisionStep)(({ theme }) => ({
    flexGrow: 1,
    flexBasis: "50%",
}));

interface IDecisionBoxProps {
    currentLanguage: Language;
    disabled?: boolean;
    discardReason: number | null;
    discardStatement: string;
    translate: TFunction;
    updatedIsApproved: (value: boolean) => void;
    updateDiscard: (key: string, value: number | string, isValid: boolean) => void;
    updateReason: (reason: string) => void;
    currentUser: UserDto | null;
    decision: DecisionDto;
    users: UserDto[];
    noArrow: boolean;
}

const DecisionBox = ({
    decision,
    discardReason,
    disabled,
    discardStatement,
    updateDiscard,
    updatedIsApproved,
    translate,
    currentLanguage,
    currentUser,
    updateReason: updateReasonProps,
    users,
    noArrow,
}: IDecisionBoxProps) => {
    const { requestedDeciderId, isApproved, reason, requestedAt, requesterId } = decision;

    const updateReason = (newReason: string) => {
        if (reason === null && newReason.trim() === "") {
            return;
        }
        if (newReason.trim() !== reason) {
            updateReasonProps(newReason);
        }
    };

    return (
        <RootDecisionStep item variant="paper" noArrow={noArrow} justifyContent="flex-start">
            {requestedDeciderId != null && requestedAt != null && requesterId != null && (
                <Alert severity="info" sx={{ mt: -1 }}>
                    <DecisionRequestMessage
                        currentUser={currentUser}
                        requestedAt={requestedAt}
                        requesterId={requesterId}
                        requestedDeciderId={requestedDeciderId}
                        translate={translate}
                        users={users}
                    />
                </Alert>
            )}
            <Tabs
                value={String(isApproved ? DecisionResult.RESULT_IMPLEMENT : DecisionResult.RESULT_DISCARD)}
                onChange={(e, newValue) => updatedIsApproved(newValue === String(DecisionResult.RESULT_IMPLEMENT))}
                variant="fullWidth"
            >
                {decisionResultOrder.map((k) => (
                    <Tab key={k} disabled={disabled} value={String(k)} label={translate(`decision_result_${k}`)} />
                ))}
            </Tabs>
            <Collapse in={isApproved}>
                <MarkdownEditorUserMentionsMeasure
                    disabled={disabled}
                    value={reason ?? ""}
                    updateValue={updateReason}
                    placeholder={translate("decision_reason_placeholder")}
                />
            </Collapse>
            <Collapse in={!isApproved}>
                <ScrollablePaper>
                    <DiscardEntityForm
                        updateValue={updateDiscard}
                        disabled={disabled}
                        reason={discardReason}
                        statement={discardStatement}
                        currentLanguage={currentLanguage}
                        translate={translate}
                        margin="dense"
                        isInMeasureContext={true}
                    />
                </ScrollablePaper>
            </Collapse>
        </RootDecisionStep>
    );
};

export default DecisionBox;
