import { styled } from "@mui/material";
import calculatorImage from "../../static/images/calculator.svg";

const Image = styled("img")({
    width: 300,
    maxWidth: "100%",
});

const CalculatorImage = () => {
    return <Image src={calculatorImage} alt="" />;
};

export default CalculatorImage;
