import { AttributeTitle } from "api-shared";
import LeverHelp from "./LeverHelp";

interface IFieldHelpProps {
    title: string | null;
    // LeverHelpProps
    params: any[];
    onClose: () => void;
}

const FieldHelp = (props: IFieldHelpProps) => {
    return props.title === AttributeTitle.CostLeverId ? <LeverHelp {...props} /> : null;
};

export default FieldHelp;
