import { Grid } from "@mui/material";
import { useTranslation } from "react-i18next";
import CurrencySelect from "../../../../components/input/select/CurrencySelect";
import { useCurrencies } from "../../../../domain/currencies";
import { useIsDesktop } from "../../../../lib/mobile";
import { translationKeys } from "../../../../translations/main-translations";

interface CurrencyFormProps {
    currencyId: number;
    currencyUpdated: (newValue: number) => void;
    disabled?: boolean;
}

const CurrencyForm = ({ currencyId, currencyUpdated, disabled }: CurrencyFormProps) => {
    const { t: translate } = useTranslation();
    const currencies = useCurrencies();
    const isDesktop = useIsDesktop();

    const onCurrencyUpdate = (value: number | null) => {
        if (value != null) {
            currencyUpdated(value);
        }
    };

    return (
        <Grid container spacing={isDesktop ? 2 : 0}>
            <Grid item xs={12}>
                <CurrencySelect
                    currencies={currencies}
                    disabled={disabled}
                    value={currencyId}
                    onChange={onCurrencyUpdate}
                    label={translate(translationKeys.VDLANG_EFFECT_CATEGORY_MODAL_CURRENCY_SELECT_LABEL)}
                    required={true}
                    inputId="currencyId"
                    menuPortalTarget={document.body}
                />
            </Grid>
        </Grid>
    );
};

export default CurrencyForm;
