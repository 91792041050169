import { Button, ButtonBase, Divider, Grid, Popover, Stack, Typography, styled, useTheme } from "@mui/material";
import { GateTaskDto, SubTaskDto, SubTaskStatus } from "api-shared";
import React, { ReactNode, useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { Cell, Label, Pie, PieChart } from "recharts";
import type { ContentType } from "recharts/types/component/Label";
import SubtaskList from "../../../components/tasks/SubtaskList";
import { useCreateSubtask } from "../../../domain/subtasks";
import { useAllUsers } from "../../../domain/users";
import useMenu from "../../../hooks/useMenu";
import { translationKeys } from "../../../translations/main-translations";

const CountText = styled("text")(({ theme }) => ({
    ...theme.typography.caption,
    letterSpacing: 0,
}));

const ActivityLevelPieChart = styled(PieChart)({
    // Required to overwrite cursor styling in the pie chart
    cursor: "inherit !important",
    "& .recharts-layer": {
        // Required to suppress black pie selection border
        outline: "none !important",
    },
});

// https://github.com/recharts/recharts/issues/19
const renderLabel = (totalCount: ReactNode, completedCount: ReactNode): ContentType => {
    const marginLeft = Number(completedCount) > 10 ? 1 : 0;
    return ({ viewBox }) => {
        const positioningProps = {
            x: viewBox != null && "cx" in viewBox ? (viewBox.cx ?? 0) : 0,
            y: viewBox != null && "cy" in viewBox ? (viewBox.cy ?? 0) : 0,
            textAnchor: "middle",
        };
        return (
            <svg>
                <clipPath id="clipPath">
                    <circle cy={positioningProps.y} cx={positioningProps.x} r="13.5" />
                </clipPath>
                <CountText {...positioningProps} y={positioningProps.y + 5} x={positioningProps.x + marginLeft} clipPath="url(#clipPath)">
                    {completedCount}/{totalCount}
                </CountText>
            </svg>
        );
    };
};

interface IActivityLevelChartProps {
    disabled?: boolean;
    activities: SubTaskDto[];
    gateTask: GateTaskDto;
}

const ActivityLevelChart = ({ activities, disabled, gateTask }: IActivityLevelChartProps) => {
    const { t: translate } = useTranslation();
    const theme = useTheme();
    const contextMenu = useMenu();

    const [activeIndex, setActiveIndex] = useState<number | undefined>(undefined);
    const navigate = useNavigate();
    const showActivity = (selectedTask: SubTaskDto, setTaskTitle = false) => {
        return navigate(`/measure/${selectedTask.measureId}/activities/${selectedTask.id}`, { state: { setTaskTitle } });
    };

    const [data, setData] = useState([{ name: translate(translationKeys.VDLANG_ACTIVITY_OPEN), value: 0, color: 1 }]);
    const colors = [theme.palette.natureGreen.main, theme.palette.text.disabled, "#F5F5F5"];
    const completedCount = activities.filter(({ status }) => status === SubTaskStatus.STATUS_COMPLETED).length;
    const totalCount = activities.length;
    const onClick = (event: React.MouseEvent<HTMLElement>) => {
        contextMenu.open(event);
    };
    const users = useAllUsers();

    const createSubTask = useCreateSubtask().mutate;

    const updateData = useCallback(() => {
        const arrayData = [...Array(totalCount)].map((activity, index) => {
            return { name: `activity${index}`, value: 1, color: index < completedCount ? 0 : 1 };
        });
        setData(arrayData);
    }, [totalCount, completedCount]);

    useEffect(() => {
        updateData();
    }, [updateData]);

    const onSectorEnter = (data: any, index: number | undefined) => {
        setActiveIndex(index);
    };
    const onSectorLeave = (data: any, index: number | undefined) => {
        setActiveIndex(undefined);
    };
    const emptyData = [{ name: "empty", value: 10 }];

    const addActivityForLevel = () => {
        createSubTask(
            {
                title: translate(translationKeys.VDLANG_ACTIVITIES_NEW_ACTIVITY_TEXTFIELD_DEFAULT),
                measureId: gateTask.measureId,
                gateTaskId: gateTask.id,
            },
            { onSuccess: (subTask) => showActivity(subTask, true) },
        );
    };

    const fractionalLabel = `${completedCount}/${totalCount}`;

    return (
        <>
            <Popover {...contextMenu.menuProps}>
                <Grid container>
                    <Stack direction="row" alignItems="center" justifyContent="space-between" sx={{ width: "100%" }}>
                        <Stack direction="row">
                            <Typography sx={{ ml: 2, my: 2, mr: 0.5 }} variant="body1">
                                {translate(translationKeys.VDLANG_ACTIVITY_CHART)}
                            </Typography>
                            <Typography sx={{ mt: 2, color: theme.palette.text.disabled }} variant="body1">
                                ({fractionalLabel})
                            </Typography>
                        </Stack>
                        <Button disabled={disabled} onClick={addActivityForLevel} sx={{ mr: 2 }} variant="text">
                            <Typography variant="body1">{translate(translationKeys.VDLANG_MEASURE_TABS_ADD_ACTIVITY)}</Typography>
                        </Button>
                    </Stack>
                    <Grid item xs={12}>
                        <Divider />
                    </Grid>

                    <Grid item xs={12} sx={{ p: 1 }}>
                        <SubtaskList
                            isCreatable={false}
                            disabled={disabled}
                            tasks={activities}
                            translate={translate}
                            users={users}
                            onOpen={showActivity}
                            disablePadding
                            noActivitiesMessage={translate(translationKeys.VDLANG_ACTIVITIES_NO_OPEN_ACTIVITIES)}
                        />
                    </Grid>
                </Grid>
            </Popover>
            <ButtonBase onClick={onClick} aria-label={fractionalLabel}>
                <ActivityLevelPieChart width={32} height={32}>
                    <Pie
                        activeIndex={activeIndex}
                        dataKey="value"
                        data={emptyData}
                        outerRadius={14}
                        fill={colors[2]}
                        stroke=""
                        style={{ outline: "none" }}
                    />
                    {activities.length > 0 ? (
                        <Pie
                            activeIndex={activeIndex}
                            dataKey="value"
                            data={data}
                            innerRadius={14}
                            outerRadius={16}
                            onMouseEnter={onSectorEnter}
                            onMouseLeave={onSectorLeave}
                            startAngle={90}
                            endAngle={-270}
                            style={{ outline: "none" }}
                        >
                            {data.map((entry, index) => (
                                <Cell key={entry.name} fill={colors[entry.color]} strokeWidth={1} />
                            ))}
                            <Label content={renderLabel(totalCount, completedCount)} position="center" />
                        </Pie>
                    ) : (
                        /* Pseudo empty Pie for 0 activities */
                        <Pie
                            dataKey="value"
                            data={[{ name: translate(translationKeys.VDLANG_ACTIVITY_OPEN), value: 1 }]}
                            innerRadius={15}
                            outerRadius={16}
                            stroke=""
                            fill={colors[2]}
                        >
                            <Label content={renderLabel(totalCount, completedCount)} position="center" />
                        </Pie>
                    )}
                </ActivityLevelPieChart>
            </ButtonBase>
        </>
    );
};

export default ActivityLevelChart;
