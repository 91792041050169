import { Typography, styled } from "@mui/material";
import { GateTaskConfigDto, GateTaskDto, UserDto } from "api-shared";
import { TFunction } from "i18next";
import useTimezone from "../../hooks/useTimezone";
import { formatUser } from "../../lib/formatters";
import { getDaysBetween } from "./GateFooter";

const Root = styled("div")(({ theme }) => ({
    padding: theme.spacing(2.5, 2),
}));

interface IGateCompleteMessageProps {
    gateTask: GateTaskDto;
    translate: TFunction;
    startedAt: Date;
    users: UserDto[];
    gateTaskConfigs: GateTaskConfigDto[];
}

const GateCompleteMessage = ({ gateTask, translate, startedAt, users, gateTaskConfigs }: IGateCompleteMessageProps) => {
    const { formatDate, formatTime } = useTimezone();

    const activeGateTaskConfig = gateTaskConfigs.find((gtc) => gtc.id === gateTask.gateTaskConfigId);
    const data = {
        date: "",
        time: "",
        gate: activeGateTaskConfig ? translate(activeGateTaskConfig.name) : "",
        daysInLevel: 0,
    };

    if (gateTask.completedAt != null) {
        data.date = formatDate(gateTask.completedAt);
        data.time = formatTime(gateTask.completedAt);
        data.daysInLevel = getDaysBetween(startedAt, gateTask.completedAt);
    }

    // Sometimes users is empty, in this case we show the message without the names
    const completedBy = users.find((user) => user.id === gateTask.completedById);

    return (
        <Root>
            <Typography variant="caption" color="textSecondary">
                {completedBy == null
                    ? translate("completed on date", data)
                    : translate("completed on date by", {
                          ...data,
                          name: formatUser(completedBy, { translate }),
                      })}
            </Typography>
        </Root>
    );
};
export default GateCompleteMessage;
