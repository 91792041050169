import LockIcon from "@mui/icons-material/LockRounded";
import { Button, ButtonProps } from "@mui/material";
import { TFunction } from "i18next";
import { Link } from "react-router-dom";
import Tooltip from "../../components/Tooltip";
import { translationKeys } from "../../translations/main-translations";

interface MethodLinkProps extends Pick<ButtonProps, "variant" | "size"> {
    code: string;
    clientName: string;
    disabled: boolean;
    translate: TFunction;
}

function MethodLink({ code, clientName, disabled, translate, ...buttonProps }: Readonly<MethodLinkProps>) {
    return (
        <Tooltip
            title={translate(translationKeys.VDLANG_METHOD_GUIDE_METHOD_DETAILS_DISABLED_HINT, { clientName })}
            disableFocusListener={!disabled}
            disableHoverListener={!disabled}
            disableTouchListener={!disabled}
        >
            <div>
                <Button
                    disabled={disabled}
                    component={Link}
                    to={`/methods/${code}`}
                    startIcon={disabled ? <LockIcon /> : null}
                    {...buttonProps}
                >
                    {translate(translationKeys.VDLANG_METHOD_GUIDE_METHOD_DETAILS)}
                </Button>
            </div>
        </Tooltip>
    );
}

export default MethodLink;
