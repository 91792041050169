import { AclNamespaces } from "api-shared";
import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { connect, ConnectedProps, useDispatch } from "react-redux";
import { useParams } from "react-router-dom";
import ItemNotFound from "../../components/ItemNotFound";
import LoadingAnimation from "../../components/loading/LoadingAnimation";
import SidebarLayout from "../../components/SidebarLayout";
import { useAttributeCategoriesQuery } from "../../domain/attributes";
import { useCurrentClient } from "../../domain/client";
import { useCurrencies } from "../../domain/currencies";
import { useIdeaQuery } from "../../domain/ideas";
import { resetCurrentMeasureAction, useMeasureQuery, useMeasureViewTracking, useUpdateMeasure } from "../../domain/measure/detail";
import { useUserHasAccessPermissionQuery } from "../../domain/permissions";
import useDialog from "../../hooks/useDialog";
import { useDocumentTitle } from "../../hooks/useDocumentTitle";
import { useLanguage } from "../../hooks/useLanguage";
import { AppState } from "../../infrastructure/store";
import { defaultCurrency } from "../../lib/defaults";
import { useFieldDataSubscription } from "../../lib/field-options";
import { useIsDesktop } from "../../lib/mobile";
import { translationKeys } from "../../translations/main-translations";
import { CurrencyContextProvider } from "../CurrencyContext";
import IdeaDialog from "../ideas/IdeaDialog";
import { MeasureContextProvider } from "../MeasureContext";
import Measureframe from "./Measureframe";
import MeasureHeader from "./MeasureHeader";
import MeasureSidebar from "./MeasureSidebar";

const MEASURE_SIDEBAR_WIDTH = 400;

interface IMeasureProps extends ConnectedProps<typeof connector> {
    measureId?: number;
}

const Measure = ({ measureId: measureIdProps }: IMeasureProps) => {
    const isDesktop = useIsDesktop();
    const { t: translate } = useTranslation();
    const language = useLanguage();
    const client = useCurrentClient();
    const currencies = useCurrencies();

    // Field data from redux store is used in both MeasureSidebar and CalculationLevel
    useFieldDataSubscription();

    const updateMeasureMutation = useUpdateMeasure();

    const sidebar = useDialog(isDesktop);
    const { isOpen: isIdeaModalOpen, closeDialog: closeIdeaModal, openDialog: openIdeaModal } = useDialog();

    const params = useParams<{ id: string }>();
    const measureIdParam = params.id !== undefined ? +params.id : measureIdProps;
    const hasValidMeasureId = typeof measureIdParam === "number" && !Number.isNaN(measureIdParam);
    const measureId = hasValidMeasureId ? measureIdParam : undefined;

    const hasIdeaAccessPermissionQuery = useUserHasAccessPermissionQuery(AclNamespaces.Idea);

    const dispatch = useDispatch();

    const attributeCategoriesQuery = useAttributeCategoriesQuery();
    const measureQuery = useMeasureQuery(measureId);
    useMeasureViewTracking(measureId);

    const ideaQuery = useIdeaQuery(
        hasIdeaAccessPermissionQuery.data?.hasPermission && measureQuery.data != undefined ? measureQuery.data?.ideaId : null,
    );

    useDocumentTitle(
        measureQuery.data != null ? `${translate(measureQuery.data.measureConfig.name)}: ${measureQuery.data.title}` : "",
        false,
    );

    useEffect(
        () => () => {
            // set current measure id to null when component is unmounted
            dispatch(resetCurrentMeasureAction());
        },
        // run only on unmount
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [],
    );

    if (!hasValidMeasureId || measureQuery.isError) {
        return <ItemNotFound toolTip={translationKeys.VDLANG_PROCESS_DETAIL_PROCESS_NOT_FOUND} />;
    }

    if (!measureQuery.isSuccess || !hasIdeaAccessPermissionQuery.isSuccess || !attributeCategoriesQuery.isSuccess) {
        return <LoadingAnimation />;
    }

    const measure = measureQuery.data;

    const processCurrency = currencies.find(({ id }) => id === measure.currencyId) ?? defaultCurrency;

    return (
        <MeasureContextProvider measure={measure}>
            <CurrencyContextProvider currency={processCurrency}>
                <SidebarLayout
                    sidebarWidth={MEASURE_SIDEBAR_WIDTH}
                    collapsedHeader={
                        <MeasureHeader
                            measure={measure}
                            ideaDisplayId={ideaQuery.data?.displayId}
                            updateMeasure={updateMeasureMutation.mutate}
                            openIdeaModal={() => openIdeaModal()}
                        />
                    }
                    sidebar={
                        <MeasureSidebar
                            measure={measure}
                            attributeCategories={attributeCategoriesQuery.data}
                            client={client}
                            language={language}
                            updateMeasure={updateMeasureMutation.mutate}
                            translate={translate}
                            closeMenu={sidebar.close}
                            ideaDisplayId={ideaQuery.data?.displayId}
                            openIdeaModal={() => openIdeaModal()}
                        />
                    }
                    onOpen={sidebar.open}
                    onClose={sidebar.close}
                    open={sidebar.isOpen}
                >
                    <Measureframe translate={translate} measure={measure} />
                    {ideaQuery.isSuccess && isIdeaModalOpen && (
                        <IdeaDialog
                            open={isIdeaModalOpen}
                            measureCreatorId={measure.createdById}
                            onClose={closeIdeaModal}
                            currentIdea={ideaQuery.data}
                            isReadonly={true}
                            hideProcessButton={true}
                        />
                    )}
                </SidebarLayout>
            </CurrencyContextProvider>
        </MeasureContextProvider>
    );
};

const mapStateToProps = (state: AppState) => ({});

const mapDispatchToProps = {};

const connector = connect(mapStateToProps, mapDispatchToProps);
export default connector(Measure);
