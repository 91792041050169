import { UserDto } from "api-shared";
import { TFunction } from "i18next";
import useTimezone from "../../../hooks/useTimezone";
import { formatUserFromId } from "../../../lib/formatters";

interface IDecisionRequestMessageProps {
    currentUser: UserDto | null;
    requestedAt: Date;
    requesterId: number;
    requestedDeciderId: number;
    translate: TFunction;
    users: UserDto[];
}
const DecisionRequestMessage = ({
    requestedAt,
    currentUser,
    translate,
    users,
    requesterId,
    requestedDeciderId,
}: IDecisionRequestMessageProps) => {
    const { formatDate, formatTime } = useTimezone();

    const data = {
        by: formatUserFromId(requesterId, users, { translate }),
        from: formatUserFromId(requestedDeciderId, users, { translate }),
        date: formatDate(requestedAt),
        time: formatTime(requestedAt),
    };

    return (
        // pure strings cannot be used with decorators, so return a fragment here
        <>
            {currentUser != null && requestedDeciderId === currentUser.id
                ? translate("decision_request_info_self", data)
                : translate("decision_request_info", data)}
        </>
    );
};

export default DecisionRequestMessage;
