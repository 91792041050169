import { DataTypeProvider } from "@devexpress/dx-react-grid";
import { Grid, Table, TableHeaderRow } from "@devexpress/dx-react-grid-material-ui";
import { styled } from "@mui/material";
import {
    CalculationHistoryEntryDto,
    CalculationHistoryType,
    EffectCategoryHistoryEntryDto,
    EffectField,
    EffectType,
    HistoryEventType,
    type GlobalCalculationIdentifier,
} from "api-shared";
import { TFunction } from "i18next";
import { useMemo } from "react";
import LoadingAnimation from "../../../../components/loading/LoadingAnimation";
import { useClientFiscalYear } from "../../../../domain/client";
import { getOrderedCalculationIdentifiers } from "../../../../domain/measure-config";
import { useAllUsers } from "../../../../domain/users";
import useCurrency, { CurrencyFormatter } from "../../../../hooks/useCurrency";
import useTimezone, { DateTimeFormatter } from "../../../../hooks/useTimezone";
import { reportError } from "../../../../infrastructure/sentry";
import { formatUserFromId } from "../../../../lib/formatters";
import { attributeName, captureType, effectDate, formatCalculationValue, isMatching } from "../../../../lib/history";
import { translationKeys } from "../../../../translations/main-translations";
import { useMeasureContext } from "../../../MeasureContext";
import TooltipFormatter from "./TooltipFormatter";

const TableHeaderCell = styled(TableHeaderRow.Cell)(({ theme }) => ({
    borderRight: `1px solid ${theme.palette.divider}`,
}));

const TableCell = styled(Table.Cell)(({ theme }) => ({
    padding: theme.spacing(1),
}));

function determineEffectSign(value: string | number | null, effectType: EffectType): number | null {
    if (value === null) {
        return value;
    }
    return effectType === EffectType.ChangeoverCosts ? +value * -1 : +value;
}

function getCalculationIdentifierLabel(
    entry: EffectCategoryHistoryEntryDto | CalculationHistoryEntryDto,
    firstCalculationIdentifier: GlobalCalculationIdentifier,
    translate: TFunction,
) {
    const calculationIdentifier =
        entry.historyType === CalculationHistoryType.Calculation ? entry.calculationIdentifier : firstCalculationIdentifier;
    return translate(`${translationKeys.VDLANG_CALCULATION_IDENTIFIER}.${calculationIdentifier}`);
}

function getMessageFromEntry(
    item: EffectCategoryHistoryEntryDto | CalculationHistoryEntryDto,
    effectType: EffectType,
    translate: TFunction,
    formatMonthYear: DateTimeFormatter,
    formatCurrency: CurrencyFormatter,
    currencyIsoCode: string | undefined,
    fiscalYearStart: number,
) {
    let event = null;

    if (item.historyType === CalculationHistoryType.EffectCategory) {
        event = getEventFromEffectCategoryEntry(item, translate);
    } else if (item.effectStartDate == null && item.effectEndDate == null) {
        event = getEventFromLinearEntry(item, translate, formatMonthYear, formatCurrency, currencyIsoCode);
    } else {
        event = getEventFromNonLinearEntry(item, translate, formatCurrency, formatMonthYear, currencyIsoCode, fiscalYearStart);
    }

    if (event == null) {
        // maybe a case has been forgotten, or there is bad history data. Report this, so it can be investigated
        const { historyType, operationType } = item;
        reportError(new Error("Unknown calculation history entry"), {
            extras: {
                historyType: String(historyType),
                operationType: String(operationType),
                previousValue: String(item.previousValue),
                newValue: String(item.newValue),
            },
        });
        // this entry will be hidden
        return null;
    }

    const placeholderData = event.getData != null ? event.getData(item as any, effectType) : {};
    return translate(`calculation_history.${event.key}`, placeholderData);
}

function getEventFromEffectCategoryEntry(item: EffectCategoryHistoryEntryDto, translate: TFunction) {
    // order of items here is important, the first match is used later
    // -> events should be ordered from most specific to least specific
    const events = [
        {
            type: HistoryEventType.INSERT,
            key: translationKeys.VDLANG_CALCULATION_HISTORY_EFFECT_CATEGORY_INSERT_SAVINGS,
            attribute: "effect_type",
            newValue: "1",
        },
        {
            type: HistoryEventType.INSERT,
            key: translationKeys.VDLANG_CALCULATION_HISTORY_EFFECT_CATEGORY_INSERT_EXTRACOSTS,
            attribute: "effect_type",
            newValue: "2",
        },
        {
            type: HistoryEventType.INSERT,
            key: translationKeys.VDLANG_CALCULATION_HISTORY_EFFECT_CATEGORY_INSERT_CURRENCY,
            attribute: "currency_id",
            getData: (item: EffectCategoryHistoryEntryDto) => ({
                newValue: item.newValue,
            }),
        },
        {
            type: HistoryEventType.UPDATE,
            key: translationKeys.VDLANG_CALCULATION_HISTORY_EFFECT_CATEGORY_UPDATE_CURRENCY,
            attribute: "currency_id",
            getData: (item: EffectCategoryHistoryEntryDto) => ({
                newValue: item.newValue,
                oldValue: item.previousValue,
            }),
        },
        {
            type: HistoryEventType.INSERT,
            key: translationKeys.VDLANG_CALCULATION_HISTORY_EFFECT_CATEGORY_INSERT_VALUE,
            getData: (item: EffectCategoryHistoryEntryDto) => ({
                attributeName: attributeName(item, translate),
                newValue: item.newValue,
            }),
        },
        {
            type: HistoryEventType.UPDATE,
            key: translationKeys.VDLANG_CALCULATION_HISTORY_EFFECT_CATEGORY_UPDATE_VALUE,
            getData: (item: EffectCategoryHistoryEntryDto) => ({
                attributeName: attributeName(item, translate),
                newValue: item.newValue,
                previousValue: item.previousValue,
            }),
        },
        {
            type: HistoryEventType.COPY,
            key: translationKeys.VDLANG_CALCULATION_HISTORY_EFFECT_CATEGORY_COPY,
            getData: (item: EffectCategoryHistoryEntryDto) => ({
                source: translate(`${translationKeys.VDLANG_CALCULATION_IDENTIFIER}.${item.previousValue}`),
                target: translate(`${translationKeys.VDLANG_CALCULATION_IDENTIFIER}.${item.newValue}`),
            }),
        },
    ];

    return events.find(
        (event) =>
            event.type === item.operationType &&
            isMatching(event.attribute, item.attribute) &&
            isMatching(event.newValue, `${item.newValue}`),
    );
}

function getEventFromNonLinearEntry(
    item: CalculationHistoryEntryDto,
    translate: TFunction,
    formatCurrency: CurrencyFormatter,
    formatMonthYear: DateTimeFormatter,
    currencyIsoCode: string | undefined,
    fiscalYearStart: number,
) {
    const events = [
        // Order matters here: First match is used, so put most specific events first

        // EffectField.Effect (should be rendered as Savings/Extra Costs and with switched sign)
        {
            type: HistoryEventType.INSERT,
            key: translationKeys.VLDANG_CALCULATION_HISTORY_EFFECT_FROM_EMPTY,
            attribute: EffectField.Effect,
            getData: (item: CalculationHistoryEntryDto, effectType: EffectType) => ({
                attributeName: translate(`effect_type_${effectType}`),
                newValue: formatCalculationValue(
                    determineEffectSign(item.newValue, effectType),
                    formatMonthYear,
                    formatCurrency,
                    currencyIsoCode,
                ),
                effectDate: effectDate(item, fiscalYearStart, translate),
            }),
        },
        {
            type: HistoryEventType.UPDATE,
            key: translationKeys.VLDANG_CALCULATION_HISTORY_EFFECT_UPDATE,
            attribute: EffectField.Effect,
            getData: (item: CalculationHistoryEntryDto, effectType: EffectType) => ({
                attributeName: translate(`effect_type_${effectType}`),
                previousValue: formatCalculationValue(
                    determineEffectSign(item.previousValue, effectType),
                    formatMonthYear,
                    formatCurrency,
                    currencyIsoCode,
                ),
                newValue: formatCalculationValue(
                    determineEffectSign(item.newValue, effectType),
                    formatMonthYear,
                    formatCurrency,
                    currencyIsoCode,
                ),
                effectDate: effectDate(item, fiscalYearStart, translate),
            }),
        },
        {
            type: HistoryEventType.DELETE,
            key: translationKeys.VLDANG_CALCULATION_HISTORY_EFFECT_TO_EMPTY,
            attribute: EffectField.Effect,
            getData: (item: CalculationHistoryEntryDto, effectType: EffectType) => ({
                attributeName: translate(`effect_type_${effectType}`),
                previousValue: formatCalculationValue(
                    determineEffectSign(item.previousValue, effectType),
                    formatMonthYear,
                    formatCurrency,
                    currencyIsoCode,
                ),
                effectDate: effectDate(item, fiscalYearStart, translate),
            }),
        },
        // Other attributes besides EffectField.Effect
        {
            type: HistoryEventType.INSERT,
            key: translationKeys.VLDANG_CALCULATION_HISTORY_EFFECT_FROM_EMPTY,
            getData: (item: CalculationHistoryEntryDto) => ({
                attributeName: attributeName(item, translate),
                newValue: formatCalculationValue(item.newValue, formatMonthYear, formatCurrency, currencyIsoCode),
                effectDate: effectDate(item, fiscalYearStart, translate),
            }),
        },
        {
            type: HistoryEventType.UPDATE,
            key: translationKeys.VLDANG_CALCULATION_HISTORY_EFFECT_UPDATE,
            getData: (item: CalculationHistoryEntryDto) => ({
                attributeName: attributeName(item, translate),
                previousValue: formatCalculationValue(item.previousValue, formatMonthYear, formatCurrency, currencyIsoCode),
                newValue: formatCalculationValue(item.newValue, formatMonthYear, formatCurrency, currencyIsoCode),
                effectDate: effectDate(item, fiscalYearStart, translate),
            }),
        },
        {
            type: HistoryEventType.DELETE,
            key: translationKeys.VLDANG_CALCULATION_HISTORY_EFFECT_TO_EMPTY,
            getData: (item: CalculationHistoryEntryDto) => ({
                attributeName: attributeName(item, translate),
                previousValue: formatCalculationValue(item.previousValue, formatMonthYear, formatCurrency, currencyIsoCode),
                effectDate: effectDate(item, fiscalYearStart, translate),
            }),
        },
    ];
    return events.find((event) => event.type === item.operationType && isMatching(event.attribute, item.attribute));
}

function getEventFromLinearEntry(
    item: CalculationHistoryEntryDto,
    translate: TFunction,
    formatMonthYear: DateTimeFormatter,
    formatCurrency: CurrencyFormatter,
    currencyIsoCode: string | undefined,
) {
    const getEffectData = (item: CalculationHistoryEntryDto, effectType: EffectType) => ({
        attributeName: translate(`effect_type_${effectType}`),
        newValue: formatCalculationValue(determineEffectSign(item.newValue, effectType), formatMonthYear, formatCurrency, currencyIsoCode),
        previousValue: formatCalculationValue(
            determineEffectSign(item.previousValue, effectType),
            formatMonthYear,
            formatCurrency,
            currencyIsoCode,
        ),
    });

    const getNonEffectData = (item: CalculationHistoryEntryDto) => ({
        attributeName: attributeName(item, translate),
        newValue: formatCalculationValue(item.newValue, formatMonthYear, formatCurrency, currencyIsoCode),
        previousValue: formatCalculationValue(item.previousValue, formatMonthYear, formatCurrency, currencyIsoCode),
    });
    const events = [
        // EffectField.HasInitial
        {
            type: HistoryEventType.INSERT,
            key: translationKeys.VDLANG_CALCULATION_HISTORY_GENERATION_INSERT_HAS_INITIAL,
            attribute: EffectField.HasInitial,
            getData: (item: CalculationHistoryEntryDto, effectType: EffectType) => ({
                captureType: captureType(item.newValue, effectType, translate),
            }),
        },
        {
            type: HistoryEventType.UPDATE,
            key: translationKeys.VDLANG_CALCULATION_HISTORY_GENERATION_UPDATE_HAS_INITIAL,
            attribute: EffectField.HasInitial,
            getData: (item: CalculationHistoryEntryDto, effectType: EffectType) => ({
                oldCaptureType: captureType(item.previousValue, effectType, translate),
                newCaptureType: captureType(item.newValue, effectType, translate),
            }),
        },

        // EffectField.Effect
        {
            type: HistoryEventType.INSERT,
            key: translationKeys.VDLANG_CALCULATION_HISTORY_GENERATION_UPDATE_FROM_EMPTY,
            attribute: EffectField.Effect,
            getData: getEffectData,
        },

        {
            type: HistoryEventType.UPDATE,
            key: translationKeys.VDLANG_CALCULATION_HISTORY_GENERATION_UPDATE_FROM_EMPTY,
            attribute: EffectField.Effect,
            previousValue: "",
            getData: getEffectData,
        },
        {
            type: HistoryEventType.UPDATE,
            key: translationKeys.VDLANG_CALCULATION_HISTORY_GENERATION_UPDATE_TO_EMPTY,
            attribute: EffectField.Effect,
            newValue: "",
            getData: getEffectData,
        },
        {
            type: HistoryEventType.UPDATE,
            key: translationKeys.VDLANG_CALCULATION_HISTORY_GENERATION_UPDATE_FIELD,
            attribute: EffectField.Effect,
            getData: getEffectData,
        },
        {
            type: HistoryEventType.DELETE,
            key: translationKeys.VDLANG_CALCULATION_HISTORY_GENERATION_DELETE_FROM_EMPTY,
            attribute: EffectField.Effect,
            previousValue: "",
            newValue: "",
            getData: getEffectData,
        },
        {
            type: HistoryEventType.DELETE,
            key: translationKeys.VDLANG_CALCULATION_HISTORY_GENERATION_UPDATE_TO_EMPTY,
            attribute: EffectField.Effect,
            newValue: "",
            getData: getEffectData,
        },
        // Everything besides EffectField.Effect and EffectField.HasInitial
        {
            type: HistoryEventType.INSERT,
            key: translationKeys.VDLANG_CALCULATION_HISTORY_GENERATION_UPDATE_FROM_EMPTY,
            getData: getNonEffectData,
        },
        {
            type: HistoryEventType.UPDATE,
            key: translationKeys.VDLANG_CALCULATION_HISTORY_GENERATION_UPDATE_FROM_EMPTY,
            previousValue: "",
            getData: getNonEffectData,
        },
        {
            type: HistoryEventType.UPDATE,
            key: translationKeys.VDLANG_CALCULATION_HISTORY_GENERATION_UPDATE_TO_EMPTY,
            newValue: "",
            getData: getNonEffectData,
        },
        {
            type: HistoryEventType.UPDATE,
            key: translationKeys.VDLANG_CALCULATION_HISTORY_GENERATION_UPDATE_FIELD,
            getData: getNonEffectData,
        },
        {
            type: HistoryEventType.DELETE,
            key: translationKeys.VDLANG_CALCULATION_HISTORY_GENERATION_DELETE_FROM_EMPTY,
            previousValue: "",
            newValue: "",
            getData: getNonEffectData,
        },
        {
            type: HistoryEventType.DELETE,
            key: translationKeys.VDLANG_CALCULATION_HISTORY_GENERATION_UPDATE_TO_EMPTY,
            newValue: "",
            getData: getNonEffectData,
        },
    ];

    return events.find(
        (event) =>
            event.type === item.operationType &&
            isMatching(event.attribute, item.attribute) &&
            isMatching(event.previousValue, item.previousValue ?? "") &&
            isMatching(event.newValue, item.newValue ?? ""),
    );
}

const getCurrencyIsoCodeForEffectEntry = (
    entry: EffectCategoryHistoryEntryDto | CalculationHistoryEntryDto,
    sortedByChangedAtCurrencyChanges: CurrencyChange[],
) => {
    if (entry.historyType == CalculationHistoryType.EffectCategory) {
        return undefined;
    }

    const newestCurrencyAtEntryCreation = sortedByChangedAtCurrencyChanges.find((change) => change.changedAt < entry.updatedAt);
    return newestCurrencyAtEntryCreation?.isoCode;
};

interface CurrencyChange {
    isoCode: string;
    changedAt: Date;
}

interface CalculationHistoryTableProps {
    historyEntries: (EffectCategoryHistoryEntryDto | CalculationHistoryEntryDto)[];
    translate: TFunction;
    effectType: EffectType;
    currencyChanges: CurrencyChange[];
}

const CalculationHistoryTable = ({ historyEntries, translate, effectType, currencyChanges }: CalculationHistoryTableProps) => {
    const { formatDate, formatMonthYear, formatTime } = useTimezone();
    const { formatCurrency } = useCurrency();
    const users = useAllUsers();

    const measure = useMeasureContext();
    const firstCalculationIdentifier = getOrderedCalculationIdentifiers(measure.measureConfig.gateTaskConfigs)[0];

    const fiscalYearStart = useClientFiscalYear();

    const rows = useMemo(() => {
        const unformattedRows = historyEntries.map((entry) => ({
            userId: entry.updatedById,
            dateTime: entry.updatedAt,
            date: formatDate(entry.updatedAt),
            time: formatTime(entry.updatedAt),
            user: formatUserFromId(entry.updatedById, users, { translate }),
            calculationIdentifier: getCalculationIdentifierLabel(entry, firstCalculationIdentifier, translate),
            message: getMessageFromEntry(
                entry,
                effectType,
                translate,
                formatMonthYear,
                formatCurrency,
                getCurrencyIsoCodeForEffectEntry(entry, currencyChanges),
                fiscalYearStart,
            ),
        }));

        return unformattedRows.map((row, index) => {
            const sameDate =
                unformattedRows[index - 1]?.dateTime !== undefined && row.date === formatDate(unformattedRows[index - 1]?.dateTime);
            if (!sameDate) {
                return row;
            }
            row.date = "";
            if (row.userId !== unformattedRows[index - 1]?.userId) {
                return row;
            }
            row.user = "";
            const sameTime =
                unformattedRows[index - 1]?.dateTime !== undefined && row.time === formatTime(unformattedRows[index - 1]?.dateTime);
            if (!sameTime) {
                return row;
            }
            row.time = "";
            if (row.calculationIdentifier !== unformattedRows[index - 1]?.calculationIdentifier) {
                return row;
            }
            row.calculationIdentifier = "";
            return row;
        });
    }, [
        historyEntries,
        formatDate,
        formatMonthYear,
        formatTime,
        users,
        translate,
        firstCalculationIdentifier,
        effectType,
        formatCurrency,
        currencyChanges,
        fiscalYearStart,
    ]);

    const columns = [
        { name: "date", title: translate("history_column_date") },
        { name: "user", title: translate("history_column_user") },
        { name: "time", title: translate("history_column_time") },
        { name: "calculationIdentifier", title: translate("history_calculationIdentifier") },
        { name: "message", title: translate("history_column_entry") },
    ];

    return historyEntries.length > 0 ? (
        <Grid columns={columns} rows={rows}>
            <DataTypeProvider formatterComponent={TooltipFormatter} for={columns.map((c) => c.name)} />
            <Table columnExtensions={[{ columnName: "message", width: "50%" }]} cellComponent={TableCell} />
            <TableHeaderRow cellComponent={TableHeaderCell} />
        </Grid>
    ) : (
        <LoadingAnimation />
    );
};

export default CalculationHistoryTable;
