import { zodResolver } from "@hookform/resolvers/zod";
import {
    CalculationType,
    ClientDto,
    EffectCategoryAttributeDto,
    EffectCategoryDto,
    EffectField,
    EffectFilterCurrencyField,
    EffectFilterDateField,
    EffectType,
    EffectValueType,
    GlobalCalculationIdentifier,
    MeasureDto,
    SpanEffectListDto,
    buildEffectKey,
    isDateBetween,
    roundCurrency,
    type EffectCategoryFields,
    type SpanEffectDto,
    type SpanEffectUpdateDto,
} from "api-shared";
import { TFunction } from "i18next";
import moment from "moment";
import { useState } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { z } from "zod";
import ActionItemDialog from "../../../components/dialogues/ActionItemDialog";
import { useCurrencies } from "../../../domain/currencies";
import { useCurrentUser } from "../../../domain/users";
import { Language, translationKeys } from "../../../translations/main-translations";
import useCurrencyContext, { CurrencyContextProvider } from "../../CurrencyContext";
import CalculationIdentifierChip from "./CalculationIdentifierChip";
import EffectCategoryForm from "./EffectCategoryForm";
import type { SaveCategoryPayload } from "./effect-category/useCalculation";

export const zCurrencyInput = z.object({
    value: z.number().nullable(),
    formula: z.string().nullable(),
});

const zDate = z.string().refine((value) => {
    const m = moment(value);

    // only show error when input is a valid moment, but does not match allowed date range
    return !(m.isValid() && !isDateBetween(m));
});

const zDateRange = z
    .object({
        start: zDate.nullable(),
        end: zDate.nullable(),
    })
    .refine((value) => {
        if (value.start == null || value.end == null) {
            return true;
        }
        // Show error only, when both values are set but the start is not >= end
        return moment(value.start).isSameOrBefore(moment(value.end));
    });

export const zLinearCalculationForm = z.object({
    hasInitial: z.boolean(),
    initial: zCurrencyInput,
    target: zCurrencyInput,
    effect: zCurrencyInput,
    priceHike: zCurrencyInput.optional(),
    pl: zDateRange,
});

export type LinearCalculationForm = z.infer<typeof zLinearCalculationForm>;

/**
 * Flatten the EffectCategoryValues.
 *
 * @param {(EffectCategoryDto | undefined)} effectCategory
 * @param {EffectCategoryAttributeDto[]} effectCategoryFields
 * @param {EffectType} [defaultEffectType]
 * @returns {EffectCategoryFields}
 */
function getInitialCategoryFields(
    effectCategory: EffectCategoryDto | undefined,
    effectCategoryFields: EffectCategoryAttributeDto[],
): EffectCategoryFields {
    if (effectCategory == null) {
        return {};
    }

    const flattenedDefaultValues: EffectCategoryFields = {};
    effectCategoryFields.forEach((field) => {
        const value = effectCategory.effectCategoryValues?.find((ecv) => ecv.effectCategoryAttributeId === field.id);
        if (value !== undefined) {
            flattenedDefaultValues[field.title] = value.value;
        }
    });
    return flattenedDefaultValues;
}

function getMappedCurrencyValue(
    spanEffect: SpanEffectDto,
    calculationIdentifier: GlobalCalculationIdentifier,
    field: EffectFilterCurrencyField,
) {
    return {
        value: spanEffect[buildEffectKey(calculationIdentifier, field, EffectValueType.Input)],
        formula: spanEffect[buildEffectKey(calculationIdentifier, field, EffectValueType.Input, "Formula")],
    };
}

function getMappedAggregatedCurrencyValue(
    effectCategory: EffectCategoryDto,
    calculationIdentifier: GlobalCalculationIdentifier,
    field: EffectFilterCurrencyField,
) {
    const value = effectCategory.aggregatedInputEffects[calculationIdentifier]?.[field];
    return {
        // aggregated effects can have higher precision because of floating point numbers but the form only allows 4 fractional digits
        value: value != null ? roundCurrency(value) : null,
        formula: null,
    };
}

function getCalculationFormValues(
    spanEffect: SpanEffectDto | undefined,
    calculationIdentifier: GlobalCalculationIdentifier,
    defaultEffectType: EffectType,
    effectCategory: EffectCategoryDto | undefined,
    calculationType: CalculationType | undefined,
) {
    if (spanEffect == null || effectCategory == null || calculationType == null) {
        return {
            hasInitial: defaultEffectType === EffectType.Savings,
            initial: { value: null, formula: null },
            target: { value: null, formula: null },
            effect: { value: null, formula: null },
            priceHike: { value: null, formula: null },
            pl: { start: null, end: null },
        };
    }

    const hasInitial = spanEffect[buildEffectKey(calculationIdentifier, EffectField.HasInitial)];

    if (calculationType === CalculationType.NonLinear) {
        const startEffectDate = effectCategory.aggregatedInputEffects[calculationIdentifier]?.startDate;
        const endEffectDate = effectCategory.aggregatedInputEffects[calculationIdentifier]?.endDate;
        const start = startEffectDate != null ? moment({ ...startEffectDate, day: 1 }).format("YYYY-MM-DD") : null;
        const end =
            endEffectDate != null
                ? moment({ ...endEffectDate, day: 1 })
                      .endOf("month")
                      .format("YYYY-MM-DD")
                : null;

        return {
            hasInitial,
            initial: getMappedAggregatedCurrencyValue(effectCategory, calculationIdentifier, EffectFilterCurrencyField.Initial),
            target: getMappedAggregatedCurrencyValue(effectCategory, calculationIdentifier, EffectFilterCurrencyField.Target),
            effect: getMappedAggregatedCurrencyValue(effectCategory, calculationIdentifier, EffectFilterCurrencyField.Effect),
            priceHike: getMappedAggregatedCurrencyValue(effectCategory, calculationIdentifier, EffectFilterCurrencyField.PriceHike),
            pl: { start, end },
        };
    }

    return {
        hasInitial,
        initial: getMappedCurrencyValue(spanEffect, calculationIdentifier, EffectFilterCurrencyField.Initial),
        target: getMappedCurrencyValue(spanEffect, calculationIdentifier, EffectFilterCurrencyField.Target),
        effect: getMappedCurrencyValue(spanEffect, calculationIdentifier, EffectFilterCurrencyField.Effect),
        priceHike: getMappedCurrencyValue(spanEffect, calculationIdentifier, EffectFilterCurrencyField.PriceHike),
        pl: {
            start: spanEffect[buildEffectKey(calculationIdentifier, EffectFilterDateField.StartDate)],
            end: spanEffect[buildEffectKey(calculationIdentifier, EffectFilterDateField.EndDate)],
        },
    };
}

// Map calculation form values to SpanEffectUpdateDto
function mapCalculationValuesToSpanEffectUpdate(
    calculationValues: LinearCalculationForm,
    calculationIdentifier: GlobalCalculationIdentifier,
): SpanEffectUpdateDto {
    const spanEffectUpdate: SpanEffectUpdateDto = { calculationIdentifier };
    const { hasInitial, initial, target, effect, priceHike, pl } = calculationValues;

    spanEffectUpdate[EffectField.HasInitial] = hasInitial;

    spanEffectUpdate[EffectFilterCurrencyField.Initial] = initial.formula ?? initial.value ?? null;
    spanEffectUpdate[EffectFilterCurrencyField.Target] = target.formula ?? target.value ?? null;
    spanEffectUpdate[EffectFilterCurrencyField.Effect] = effect.formula ?? effect.value ?? null;

    if (priceHike != null) {
        spanEffectUpdate[EffectFilterCurrencyField.PriceHike] = priceHike.formula ?? priceHike.value ?? null;
    }

    spanEffectUpdate[EffectFilterDateField.StartDate] = pl.start;
    spanEffectUpdate[EffectFilterDateField.EndDate] = pl.end;

    if (spanEffectUpdate[EffectField.HasInitial]) {
        // remove effect property from request
        spanEffectUpdate[EffectField.Effect] = undefined;
    } else {
        // When only effects are entered, the initial value should be wiped on save
        spanEffectUpdate[EffectField.Initial] = null;
        spanEffectUpdate[EffectField.Target] = null;

        if (spanEffectUpdate[EffectField.PriceHike] != null) {
            spanEffectUpdate[EffectField.Effect] = null;
        }
    }

    return spanEffectUpdate;
}

interface IEffectCategoryDialogProps {
    open: boolean;
    onClose: () => void;
    translate: TFunction;
    processName: string;
    client: ClientDto;
    lang: Language;
    effectCategory?: EffectCategoryDto;
    onSave: (effectCategory: SaveCategoryPayload) => void;
    calculationIdentifier: GlobalCalculationIdentifier;
    disabled?: boolean;
    effectCategoryFields: EffectCategoryAttributeDto[];
    allEffectCategories: EffectCategoryDto[];
    showCategoryFields: boolean;
    showCalculationFields: boolean;
    defaultEffectType: EffectType;
    spanEffects: SpanEffectListDto;
    withBadge: boolean;
    measure: MeasureDto;
}

export const isExistingCategory = (
    ecs: EffectCategoryDto[],
    categoryFields: { id: number; value: number | undefined }[],
    currencyId: number,
    effectType: EffectType,
): boolean => {
    return ecs.some((ec) => {
        const areAttributesIdentical = ec.effectCategoryValues?.every(
            (value) =>
                categoryFields.find(
                    (field) => field.id === value.effectCategoryAttributeId && field.value !== undefined && field.value === value.value,
                ) !== undefined,
        );
        return areAttributesIdentical && ec.effectType === effectType && ec.currencyId === currencyId;
    });
};

const EffectCategoryDialog = ({
    open,
    onClose,
    onSave,
    translate,
    processName,
    client,
    lang,
    effectCategory,
    calculationIdentifier,
    disabled = false,
    effectCategoryFields,
    showCategoryFields,
    showCalculationFields,
    defaultEffectType,
    spanEffects,
    withBadge = true,
    measure,
    allEffectCategories,
}: IEffectCategoryDialogProps) => {
    const initial = getInitialCategoryFields(effectCategory, effectCategoryFields);

    // contains the full set of (possibly changed) category fields in the form { [fieldTitle: string]: number | undefined }
    const [categoryChanges, setCategoryChanges] = useState<EffectCategoryFields>({ ...initial });

    const updateCategoryFields = (update: EffectCategoryFields) => setCategoryChanges((prev) => ({ ...prev, ...update }));

    const currencies = useCurrencies();
    const processCurrency = useCurrencyContext();

    const categoryFields = effectCategoryFields.map((field) => ({
        ...field,
        value: categoryChanges[field.title],
    }));

    // Might be undefined when creating a new EffectCategory
    const spanEffect = spanEffects.find((se) => effectCategory?.id === se.effectCategoryId);

    const calculationType = effectCategory?.calculationTypes[calculationIdentifier];

    const formProps = useForm({
        mode: "onChange",
        resolver: zodResolver(zLinearCalculationForm),
        defaultValues: getCalculationFormValues(spanEffect, calculationIdentifier, defaultEffectType, effectCategory, calculationType),
    });

    const {
        formState: { isValid: hasValidCalculationFields },
        getValues,
    } = formProps;

    const isEdit = effectCategory != null;

    const effectType = effectCategory?.effectType ?? defaultEffectType;

    const user = useCurrentUser();

    const defaultCurrencyId = effectCategory?.currencyId ?? measure.measureConfig.currencyId ?? user?.currencyId ?? processCurrency.id;

    const [selectedCurrency, setSelectedCurrency] = useState<number>(defaultCurrencyId);

    const ecAlreadyExists = isExistingCategory(
        allEffectCategories.filter((ec) => ec.id !== effectCategory?.id),
        categoryFields,
        selectedCurrency,
        effectType,
    );

    const isCategoryValid = categoryFields.every((sel) => sel.value != null) && !ecAlreadyExists;

    const hasValidCalculation = !showCalculationFields || hasValidCalculationFields;

    const onCurrencyUpdated = (newValue: number) => {
        setSelectedCurrency(newValue);
    };

    const contextCurrency = currencies.find((c) => c.id === selectedCurrency) ?? processCurrency;

    const saveEffectCategory = () => {
        // Do nothing, if form not completed yet
        if (!isCategoryValid || !hasValidCalculation || disabled) {
            return;
        }
        // Only send actually changed values
        const changesToSend = Object.fromEntries(
            Object.entries(categoryChanges).filter(([fieldName]) => initial[fieldName] !== categoryChanges[fieldName]),
        );

        // Strict boolean check needed so that no changes are not interpreted as false
        const spanEffectUpdate = showCalculationFields
            ? mapCalculationValuesToSpanEffectUpdate(getValues(), calculationIdentifier)
            : undefined;

        if (effectCategory == null) {
            onSave({
                effectType,
                categoryFields: changesToSend,
                calculationFields: spanEffectUpdate,
                currencyId: selectedCurrency,
            });
        } else {
            // only provide currency if it has actually changed. Without this check the saveCategoryFields would always be called
            const currencyId = selectedCurrency !== effectCategory?.currencyId ? selectedCurrency : undefined;
            onSave({
                effectCategoryId: effectCategory.id,
                categoryFields: changesToSend,
                calculationValues: spanEffectUpdate,
                currencyId,
            });
        }
        onClose();
    };

    return (
        <ActionItemDialog
            open={open}
            onClose={onClose}
            translate={translate}
            primary={isEdit ? translationKeys.VDLANG_SAVE : "Add"}
            primaryDisabled={!isCategoryValid || !hasValidCalculation || disabled}
            onPrimary={saveEffectCategory}
            action={isEdit ? "edit" : "create"}
            item={`effect_type_${effectType}`}
            titleLabel={
                withBadge ? (
                    <CalculationIdentifierChip
                        variant="light"
                        label={translate(`${translationKeys.VDLANG_CALCULATION_IDENTIFIER}.${calculationIdentifier}`)}
                    />
                ) : null
            }
            shapedHeader
            hintText={
                ecAlreadyExists
                    ? translate(translationKeys.VDLANG_EFFECT_CATEGORY_MODAL_EC_ALREADY_EXISTS, {
                          effectType: translate(`effect_type_${effectType}`),
                      })
                    : undefined
            }
            hintSeverity="warning"
        >
            <CurrencyContextProvider currency={contextCurrency}>
                <FormProvider {...formProps}>
                    <EffectCategoryForm
                        categoryFields={categoryFields}
                        updateCategoryFields={updateCategoryFields}
                        translate={translate}
                        processName={processName}
                        lang={lang}
                        client={client}
                        onSubmit={saveEffectCategory}
                        disabled={disabled}
                        showCategoryFields={showCategoryFields}
                        showCalculationFields={showCalculationFields}
                        effectType={effectType}
                        showLinearAlert={calculationType === CalculationType.NonLinear}
                        measureId={measure.id}
                        currencyId={selectedCurrency}
                        currencyUpdated={onCurrencyUpdated}
                    />
                </FormProvider>
            </CurrencyContextProvider>
        </ActionItemDialog>
    );
};
export default EffectCategoryDialog;
