import {
    EffectType,
    SpanEffectListDto,
    type CreateEffectCategoryDto,
    type GlobalCalculationIdentifier,
    type SpanEffectUpdateDto,
} from "api-shared";
import { isEmpty } from "lodash";
import { useCallback, useState } from "react";
import {
    useAddEffectCategory,
    useCopyValuesFromPreviousCalculationLevel,
    useRemoveEffectCategory,
    useUpdateEffectCategory,
    type UpdateEffectCategoryInput,
} from "../../../../domain/effect-category";
import { useUpdateSpanEffect } from "../../../../domain/span-effect";

export type SaveCategoryPayload =
    | Omit<CreateEffectCategoryDto, "measureId">
    | (UpdateEffectCategoryInput & {
          calculationValues?: SpanEffectUpdateDto;
      });

export interface IUseCalculationProps {
    spanEffects: SpanEffectListDto;
    measureId: number;
    onAddEffectCategorySuccess?: (categoryId: number) => void;
}

export const useCalculation = ({ measureId, spanEffects, onAddEffectCategorySuccess }: IUseCalculationProps) => {
    const removeEffectCategoryFromMeasure = useRemoveEffectCategory().mutate;
    const copyValuesFromPreviousCalculationLevel = useCopyValuesFromPreviousCalculationLevel().mutate;
    const updateEffectCategory = useUpdateEffectCategory().mutate;
    const addEffectCategoryToMeasure = useAddEffectCategory().mutate;
    const updateSpanEffect = useUpdateSpanEffect().mutate;

    const [categoryToEdit, setCategoryToEdit] = useState<number | null>(null);
    const [categoryToDelete, setCategoryToDelete] = useState<number | null>(null);
    const [defaultEffectType, setDefaultEffectType] = useState<EffectType>(EffectType.Savings);

    const addCategory = (effectType: EffectType) => {
        setDefaultEffectType(effectType);
        setCategoryToEdit(-1);
    };

    const removeEffectCategory = () => {
        if (categoryToDelete != null) {
            removeEffectCategoryFromMeasure({ measureId, id: categoryToDelete });
            setCategoryToDelete(null);
        }
    };

    const saveCategory = (changes: SaveCategoryPayload) => {
        if (!("effectCategoryId" in changes)) {
            // Send calculation values directly with the create request
            addEffectCategoryToMeasure({ ...changes, measureId }, { onSuccess: (response) => onAddEffectCategorySuccess?.(response.id) });
            return;
        }

        const { effectCategoryId, calculationValues, categoryFields, currencyId } = changes;
        const spanEffect = spanEffects.find((spanEffect) => spanEffect.effectCategoryId === effectCategoryId);

        function updateCalculationValues() {
            if (spanEffect == null || calculationValues == null || Object.keys(calculationValues).length == 0) {
                return;
            }
            updateSpanEffect({ measureId, spanEffectId: spanEffect.id, payload: calculationValues });
        }

        // An update to the EffectCategory's currency will also recalculate all effects on all levels
        // Execute effect update after category update to avoid the race between the effect updates of both requests
        if (!isEmpty(categoryFields) || currencyId != null) {
            updateEffectCategory({ effectCategoryId, categoryFields, currencyId }, { onSuccess: updateCalculationValues });
        } else {
            updateCalculationValues();
        }
    };

    const copyDataForLevel = useCallback(
        (targetCalculationIdentifier: GlobalCalculationIdentifier, effectCategoryId?: number) => {
            if (effectCategoryId != null) {
                return copyValuesFromPreviousCalculationLevel({ targetCalculationIdentifier, effectCategoryId });
            } else {
                return copyValuesFromPreviousCalculationLevel({ measureId, targetCalculationIdentifier });
            }
        },
        [measureId, copyValuesFromPreviousCalculationLevel],
    );

    return {
        categoryToEdit,
        setCategoryToEdit,
        categoryToDelete,
        setCategoryToDelete,
        defaultEffectType,
        addCategory,
        removeEffectCategory,
        copyDataForLevel,
        saveCategory,
    };
};
