import ExpandMoreIcon from "@mui/icons-material/ExpandMoreRounded";
import { Accordion, AccordionDetails, AccordionSummary, Button, Grid, Typography } from "@mui/material";
import { AclNamespaces, CostLeverDto } from "api-shared";
import { TFunction } from "i18next";
import Authorization from "../../components/Authorization";
import { translateFromProperty } from "../../lib/translate";
import { Language, translationKeys } from "../../translations/main-translations";
import MethodLink from "../method/MethodLink";

interface CostLeverListProps {
    language: Language;
    costLevers: CostLeverDto[];
    translate: TFunction;
    updateExpandedCostlevers: (id: number, isOpen: boolean) => void;
    expandedCostlevers: Set<number>;
    selectedCostlever: number | null;
    clientName: string;
    isMethodSectionEnabled: boolean;
    onSelect: (costLeverId: number, valueLeverId: number) => void;
}

const CostLeverList = ({
    costLevers,
    language,
    translate,
    selectedCostlever,
    expandedCostlevers,
    updateExpandedCostlevers,
    clientName,
    isMethodSectionEnabled,
    onSelect,
}: CostLeverListProps) => {
    // return Fragment because returning directly an array of react elements is not supported by TypeScript
    return (
        <>
            {costLevers
                .map((cl) => ({
                    id: cl.id,
                    valueLeverId: cl.valueLeverId,
                    name: translateFromProperty(cl, "name", language),
                    hint: translateFromProperty(cl, "hint", language),
                    code: cl.code,
                }))
                .map(({ id, valueLeverId, name, hint, code }) => (
                    <Accordion
                        key={code}
                        elevation={0}
                        square
                        expanded={expandedCostlevers.has(id)}
                        onChange={(e, isOpen) => updateExpandedCostlevers(id, isOpen)}
                    >
                        <AccordionSummary expandIcon={<ExpandMoreIcon />} sx={{ px: 3 }}>
                            <Typography>
                                <strong>{code}</strong> - {name}
                            </Typography>
                        </AccordionSummary>
                        <AccordionDetails sx={{ px: 3 }}>
                            <Grid container spacing={1}>
                                <Grid item xs={12}>
                                    <Typography color="textSecondary">{hint}</Typography>
                                </Grid>
                                <Authorization namespace={AclNamespaces.Method}>
                                    <Grid item sx={{ display: { xs: "none", lg: "block" } }}>
                                        <MethodLink
                                            variant="text"
                                            size="small"
                                            code={code}
                                            clientName={clientName}
                                            disabled={!isMethodSectionEnabled}
                                            translate={translate}
                                        />
                                    </Grid>
                                </Authorization>
                                {selectedCostlever !== id ? (
                                    <Grid item>
                                        <Button variant="contained" size="small" onClick={() => onSelect(id, valueLeverId)}>
                                            {translate(translationKeys.VDLANG_METHOD_GUIDE_APPLY_METHOD_TO_MEASURE)}
                                        </Button>
                                    </Grid>
                                ) : null}
                            </Grid>
                        </AccordionDetails>
                    </Accordion>
                ))}
        </>
    );
};

export default CostLeverList;
