import AddIcon from "@mui/icons-material/AddRounded";
import { Button, Grid, styled } from "@mui/material";
import { EffectCategoryDto, EffectType } from "api-shared";
import { TFunction } from "i18next";
import Tooltip from "../../components/Tooltip";
import { IEntity } from "../../lib/field-options";
import { translationKeys } from "../../translations/main-translations";

const AddEffectCategoryButton = styled(Button)({
    whiteSpace: "nowrap",
});

interface IAddEffectCategoryButtonsProps {
    translate: TFunction;
    disabled: boolean;
    onAdd: (effectType: EffectType) => void;
    effectCategories: EffectCategoryDto[];
    allValues: IEntity[][];
}

const AddEffectCategoryButtons = ({ onAdd, translate, disabled, effectCategories, allValues }: IAddEffectCategoryButtonsProps) => {
    const maxPerEffectType = allValues.reduce((acc, cur) => acc * cur.length, 1);

    if (effectCategories.length === maxPerEffectType * 2) {
        return null;
    }

    return (
        <Grid container spacing={1} wrap="nowrap">
            {[EffectType.Savings, EffectType.ChangeoverCosts].map((type) => {
                const allForTypeAdded = effectCategories.filter((ec) => ec.effectType === type).length >= maxPerEffectType;
                const hint = translate(translationKeys.VDLANG_EFFECT_CATEGORY_GATE_ADD_BUTTON_DISABLED_HINT, {
                    effectType: translate(`effect_type_${type}`),
                });
                return (
                    <Grid item key={type}>
                        <Tooltip title={allForTypeAdded ? hint : ""}>
                            <span>
                                <AddEffectCategoryButton
                                    disabled={allForTypeAdded || disabled}
                                    startIcon={<AddIcon />}
                                    onClick={() => onAdd(type)}
                                    data-testid={`addEffectCategory_${type}`}
                                >
                                    {translate(`effect_type_${type}`)}
                                </AddEffectCategoryButton>
                            </span>
                        </Tooltip>
                    </Grid>
                );
            })}
        </Grid>
    );
};

export default AddEffectCategoryButtons;
