import { Typography, styled } from "@mui/material";
import { MeasureConfigDto } from "api-shared";
import { TFunction } from "i18next";
import { translationKeys } from "../../translations/main-translations";

interface IEffectivenessGateProps {
    measureConfig: MeasureConfigDto;
    translate: TFunction;
}

const Root = styled("div")(({ theme }) => ({
    padding: theme.spacing(3),
}));

const EffectivenessGate = ({ measureConfig, translate }: IEffectivenessGateProps) => (
    <Root>
        <Typography align="center">
            {translate(translationKeys.VDLANG_PROCESS_COMPLETE_HINT, { name: translate(measureConfig.name) })}
        </Typography>
    </Root>
);

export default EffectivenessGate;
