import { Button, Typography, styled } from "@mui/material";
import {
    EffectCategoryDto,
    FeatureFlags,
    GateStatus,
    GateTaskConfigDto,
    GateTaskDto,
    GateTaskType,
    InvalidMeasurePropertiesDto,
} from "api-shared";
import classNames from "classnames";
import { TFunction } from "i18next";
import { isEmpty } from "lodash";
import moment from "moment";
import Alert from "../../components/Alert";
import ActionItemDialog from "../../components/dialogues/ActionItemDialog";
import { useClientHasFeature } from "../../domain/client";
import useDialog from "../../hooks/useDialog";
import { MeasureField } from "../../lib/fields";
import { translationKeys } from "../../translations/main-translations";
import { useMeasureContext } from "../MeasureContext";

interface GateCompletionHintProps {
    invalidProperties: InvalidMeasurePropertiesDto;
    fields: MeasureField[];
    gateTask: GateTaskDto;
    allGateTasks: GateTaskDto[];
    effectCategories: EffectCategoryDto[];
    gateTaskConfigs: GateTaskConfigDto[];
    translate: TFunction;
    className?: string;
    isAllowedToComplete: boolean;
    userCanDecide: boolean;
}

const getPredecessorHint = (
    incompleteGates: number[],
    gateTaskConfigs: GateTaskConfigDto[],
    gateTaskConfig: GateTaskConfigDto,
    translate: TFunction,
) => {
    const incompletePredecessors = gateTaskConfigs.filter(({ id, order }) => order < gateTaskConfig.order && incompleteGates.includes(id));

    if (incompletePredecessors.length === 0) {
        return null;
    }

    if (incompletePredecessors.length === 1) {
        // if theres only on incomplete gate before, it can be named explicitly
        return translate(translationKeys.VDLANG_COMPLETION_HINT_PREDECESSOR, {
            gate: translate(`${incompletePredecessors[0].name}`),
        });
    }

    // generic message when multiple gates are not yet completed
    return translate(translationKeys.VDLANG_COMPLETION_HINT_PREDECESSORS);
};

function getMandatoryFieldsHints(
    fields: MeasureField[],
    invalidFieldIds: number[],
    translate: TFunction,
    forGateTaskConfig?: GateTaskConfigDto,
) {
    const notFilledMandatoryFields: string[] = [];
    const mandatoryIds = forGateTaskConfig?.mandatoryAttributeIds ?? [];

    fields
        .filter((field) => forGateTaskConfig === undefined || mandatoryIds.includes(field.id))
        .forEach((field) => {
            if (invalidFieldIds.includes(field.id)) {
                notFilledMandatoryFields.push(field.title);
            }
        });

    return notFilledMandatoryFields.map((f) =>
        translate(translationKeys.VDLANG_COMPLETION_HINT_MANDATORY_FIELD_NOT_FILLED, { field: translate(f) }),
    );
}

function getEffectCategoryHint(
    calculations: Partial<Record<number, number[]>>,
    firstCalculationGateTaskConfig: GateTaskConfigDto,
    effectCategories: EffectCategoryDto[],
    translate: TFunction,
    gateTaskId?: number,
) {
    if (isEmpty(calculations)) {
        // measure has no gate tasks of type calculation
        return null;
    }

    if (effectCategories.length === 0) {
        // No EffectCategory yet
        return translate(translationKeys.VDLANG_COMPLETION_HINT_ATLEAST_ONE_EFFECT_CATEGORY, {
            gate: translate(firstCalculationGateTaskConfig.name),
        });
    }

    const allInvalidEffectCategories = [...new Set(Object.values(calculations).flatMap((values) => values ?? [], []))];

    // If a gate is provided, only show those that are invalid in that gate.
    // If no gate is provided, show all invalid EffectCategories of the whole measure
    const invalidEffectCategories = gateTaskId !== undefined ? calculations[gateTaskId] : allInvalidEffectCategories;

    if (invalidEffectCategories == null || invalidEffectCategories.length === 0) {
        return null;
    }

    if (invalidEffectCategories.length > 1) {
        return translate(translationKeys.VDLANG_COMPLETION_HINT_COMPANY_SPLIT_MULTIPLE);
    }

    const invalidCategory = effectCategories.find(({ id }) => id === invalidEffectCategories[0]);
    const effectType = invalidCategory !== undefined ? translate(`effect_type_${invalidCategory.effectType}`) : "";

    return translate(translationKeys.VDLANG_COMPLETION_HINT_COMPANY_SPLIT, { effectType });
}

function getGateTaskDateHints(orderedGateTasks: GateTaskDto[], gateTaskConfigs: GateTaskConfigDto[], translate: TFunction): string[] {
    const notFilledDatesGateTasks: string[] = [];

    function addHint(gateTaskConfigId: number, translationKey: translationKeys) {
        const gateTaskConfig = gateTaskConfigs.find((gtc) => gtc.id === gateTaskConfigId);
        if (gateTaskConfig !== undefined) {
            notFilledDatesGateTasks.push(translate(translationKey, { gate: translate(gateTaskConfig.name) }));
        }
    }

    let lastStartDate = new Date(0);
    let lastDueDate = new Date(0);
    orderedGateTasks.forEach(({ startDate, duedate, gateTaskConfigId }) => {
        if (startDate === null || duedate === null) {
            addHint(gateTaskConfigId, translationKeys.VDLANG_COMPLETION_HINT_LEVEL_DATES_INCOMPLETE);
            return;
        }
        // Start date of gate task cannot be before start date of predecessor
        if (moment.utc(startDate).isBefore(moment.utc(lastStartDate), "day")) {
            addHint(gateTaskConfigId, translationKeys.VDLANG_COMPLETION_HINT_LEVEL_DATES_START_DATE_BEFORE_PREDECESSOR);
        }
        // Due date of gate task cannot be before due date of predecessor
        if (moment.utc(duedate).isBefore(moment.utc(lastDueDate), "day")) {
            addHint(gateTaskConfigId, translationKeys.VDLANG_COMPLETION_HINT_LEVEL_DATES_DUE_DATE_BEFORE_PREDECESSOR);
        }
        lastStartDate = new Date(startDate);
        lastDueDate = new Date(duedate);
    });

    return notFilledDatesGateTasks;
}

export const completionHintClass = "VdGateCompletionHint-completionHint";

const DialogButton = styled(Button)(({ theme }) => ({
    padding: 0,
    minWidth: 0,
    lineHeight: 1.5,
    fontWeight: "normal",
    textDecoration: "underline",
    [`:hover`]: {
        backgroundColor: "transparent",
        textDecoration: "underline",
    },
}));

const FlatList = styled("ol")(() => ({
    padding: 0,
    margin: 0,
    listStylePosition: "inside",
}));

const GateCompletionHint = ({
    invalidProperties,
    fields,
    gateTask,
    allGateTasks,
    effectCategories,
    gateTaskConfigs,
    translate,
    isAllowedToComplete,
    className,
    userCanDecide,
}: GateCompletionHintProps) => {
    const hintDialog = useDialog();
    const measure = useMeasureContext();
    const hasMandatoryLevelDates = useClientHasFeature(FeatureFlags.FEATURE_MANDATORY_LEVEL_DATES);
    const orderedCalculationGateTaskConfigs = gateTaskConfigs
        .filter(({ type }) => type === GateTaskType.Calculation)
        .sort((a, b) => a.order - b.order);
    const firstCalculationGateTaskConfig = orderedCalculationGateTaskConfigs[0] ?? null;

    const gateTaskConfig = gateTaskConfigs.find((gtc) => gtc.id === gateTask.gateTaskConfigId);

    if (gateTaskConfig === undefined) {
        // GateTaskConfig for current gate not found. This can actually not happen
        return null;
    }

    const isLastGate = gateTaskConfigs.every((gtc) => gtc.order <= gateTaskConfig.order);

    const predecessorError = getPredecessorHint(invalidProperties.incompleteGates, gateTaskConfigs, gateTaskConfig, translate);

    const effectCategoryError = getEffectCategoryHint(
        invalidProperties.calculations,
        firstCalculationGateTaskConfig,
        effectCategories,
        translate,
        isLastGate ? undefined : gateTask.id,
    );

    const notAllowedError = !isAllowedToComplete ? translate(translationKeys.VDLANG_COMPLETION_HINT_NO_PERMISSION) : null;

    const invalidDeciderError = !userCanDecide ? translate(translationKeys.VDLANG_COMPLETION_HINT_DECIDER_HAS_NO_PERMISSION) : null;

    const mandatoryFieldsErrors = getMandatoryFieldsHints(
        fields,
        invalidProperties.fields,
        translate,
        isLastGate ? undefined : gateTaskConfig,
    );

    const hints = [...mandatoryFieldsErrors, predecessorError, effectCategoryError, notAllowedError, invalidDeciderError];

    if (hasMandatoryLevelDates) {
        const orderedGateTasks = allGateTasks.toSorted((a, b) => {
            const aOrder = gateTaskConfigs.find((gtc) => gtc.id === a.gateTaskConfigId)?.order ?? 0;
            const bOrder = gateTaskConfigs.find((gtc) => gtc.id === b.gateTaskConfigId)?.order ?? 0;
            return aOrder - bOrder;
        });

        if (measure.currentGateTaskConfigId === gateTask.gateTaskConfigId) {
            hints.push(
                ...getGateTaskDateHints(
                    orderedGateTasks.filter((gate) => gate.status === GateStatus.STATUS_OPEN),
                    gateTaskConfigs,
                    translate,
                ),
            );
        }
    }

    const filteredHints = hints.filter((hint) => hint !== null);

    const getGateCompletionHint = () => {
        // Allow for overwriting this translation in the database for "special" customers
        const translatedText = translate(translationKeys.VDLANG_GATE_TASK_FOOTER_LAST_GATE_HINT);
        if (isLastGate && filteredHints.length === 1 && !isAllowedToComplete && translatedText !== "") {
            return translate(translationKeys.VDLANG_GATE_TASK_FOOTER_LAST_GATE_HINT);
        }

        return filteredHints[0];
    };

    return filteredHints.length > 0 ? (
        <>
            {filteredHints.length > 1 && (
                <ActionItemDialog
                    title={translate(translationKeys.VDLANG_COMPLETION_HINT_DIALOG_TITLE)}
                    secondary="close"
                    open={hintDialog.isOpen}
                    onClose={hintDialog.closeDialog}
                    translate={translate}
                >
                    <FlatList>
                        {filteredHints.map((hint) => (
                            <Typography key={hint} component="li">
                                {hint}
                            </Typography>
                        ))}
                    </FlatList>
                </ActionItemDialog>
            )}
            <Alert dense severity="warning" className={classNames(className, completionHintClass)}>
                {filteredHints.length > 1 ? (
                    <>
                        {translate(translationKeys.VDLANG_COMPLETION_HINT_MULTIPLE, { count: filteredHints.length })}{" "}
                        <DialogButton variant="text" color="inherit" onClick={() => hintDialog.openDialog()}>
                            {translate(translationKeys.VDLANG_COMPLETION_HINT_SHOW_DIALOG_LABEL)}
                        </DialogButton>
                    </>
                ) : (
                    getGateCompletionHint()
                )}
            </Alert>
        </>
    ) : null;
};

export default GateCompletionHint;
