import { Stack, styled } from "@mui/material";
import React from "react";

const GrowingStack = styled(Stack)({
    flexGrow: 1,
    height: 0, // Usually minHeight is sufficient, but not for Safari
});

/**
 * Create DOM wrappers, such that children elements are able to use "height: 100%" to stretch to the full available height.
 *
 * @param {React.PropsWithChildren<unknown>} { children }
 */
const MeasureFullHeightTab = ({ children }: React.PropsWithChildren<unknown>) => (
    // Create a double nested, growing flex container so that height auto-sizing works in activities and documents/history tables
    // Be careful when changing anything here, thoroughly test all measure tabs in all browsers (looking at you, Safari!)
    // You have been warned...
    <GrowingStack>
        <GrowingStack>{children}</GrowingStack>
    </GrowingStack>
);

export default MeasureFullHeightTab;
