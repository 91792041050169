import React from "react";
import { useCurrentUserCanEditMeasure, useMeasureFields, useMethodIdFieldValue } from "../../domain/measure/detail";
import { useAllUsers } from "../../domain/users";
import { useMeasureContext } from "../MeasureContext";
import MeasureFullHeightTab from "./MeasureFullHeightTab";
import SubtasksForMeasure from "./SubtasksForMeasure";

const SubtasksForCurrentMeasure = () => {
    const measure = useMeasureContext();
    const currentUserCanEditMeasure = useCurrentUserCanEditMeasure(measure);
    const fields = useMeasureFields(measure);
    const methodId = useMethodIdFieldValue(fields);
    const users = useAllUsers();

    return (
        <MeasureFullHeightTab>
            <SubtasksForMeasure
                measure={measure}
                measureId={measure.id}
                methodId={methodId ?? undefined}
                measureNotEditable={!currentUserCanEditMeasure}
                users={users}
            />
        </MeasureFullHeightTab>
    );
};
export default React.memo(SubtasksForCurrentMeasure);
