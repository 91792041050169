import { Grid, styled, Typography } from "@mui/material";
import { GateTaskDto } from "api-shared";
import React from "react";
import { useTranslation } from "react-i18next";
import LoadingAnimation from "../../../components/loading/LoadingAnimation";
import { useSubTasksByMeasure } from "../../../domain/subtasks";
import { translationKeys } from "../../../translations/main-translations";
import ActivityGateChart from "./ActivityGateChart";

const StyledTitle = styled(Typography)(({ theme }) => ({
    ...theme.typography.body1,
    fontWeight: theme.typography.fontWeightMedium,
    margin: theme.spacing(2, 0, 2, 2),
}));

const StyledGrid = styled(Grid)(({ theme }) => ({
    marginBottom: theme.spacing(1),
    [theme.breakpoints.up("sm")]: {
        borderRight: `1px solid ${theme.palette.divider}`,
    },
    [theme.breakpoints.only("xs")]: {
        borderBottom: `1px solid ${theme.palette.divider}`,
    },
}));

interface IActivityGateProps {
    gateTask: GateTaskDto;
    name: string;
}

const ActivityGate = ({ gateTask, name }: IActivityGateProps) => {
    const { t: translate } = useTranslation();
    const allActivitiesQuery = useSubTasksByMeasure(gateTask.measureId);
    const allActivities = allActivitiesQuery.data ?? [];
    const activitiesForGate = allActivities.filter(({ gateTaskId }) => gateTaskId === gateTask.id);
    if (!allActivitiesQuery.isSuccess) {
        return <LoadingAnimation />;
    }

    return (
        <Grid container>
            <Grid item xs={12}>
                <StyledTitle>
                    {translate(translationKeys.VDLANG_ACTIVITY_GATE_LIST_TITLE, {
                        activities: activitiesForGate.length,
                        gateType: translate(name),
                    })}
                </StyledTitle>
            </Grid>
            <StyledGrid item xs={12}>
                <ActivityGateChart activities={activitiesForGate} translate={translate} />
            </StyledGrid>
        </Grid>
    );
};

export default React.memo(ActivityGate);
