import CircleIcon from "@mui/icons-material/Circle";
import { Divider, Grid, Popover, Stack, styled, Typography, useTheme } from "@mui/material";

import { SubTaskDto } from "api-shared";
import { TFunction } from "i18next";
import { findLastIndex } from "lodash";
import { Fragment, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Bar, BarChart, BarProps, Cell, Rectangle, XAxis, YAxis } from "recharts";
import ResponsiveContainer from "../../../components/ResponsiveContainer";
import SubtaskList from "../../../components/tasks/SubtaskList";
import { useAllUsers } from "../../../domain/users";
import useMenu from "../../../hooks/useMenu";
import { translationKeys } from "../../../translations/main-translations";
import { useActivityLegends, useActivityLevels } from "./useActivityLevel";

const StyledIcon = styled(CircleIcon)(({ theme }) => ({
    marginTop: theme.spacing(0.75),
    marginRight: theme.spacing(0.5),
    fontSize: "0.5rem",
}));

interface IActivityGateChartProps {
    disabled?: boolean;
    translate: TFunction;
    activities: SubTaskDto[];
}

enum GateSubTaskStatus {
    COMPLETED = "completed",
    DUESOON = "duesoon",
    OVERDUE = "overdue",
    OPEN = "open",
}

const barRadius = (isFirstParentBar: boolean, isLastParentBar: boolean, barIndex: number, totalBars: number) => {
    const firstBar = 0;
    const lastBar = totalBars - 1;
    let leftRadius = 0;
    let rightRadius = 0;

    if (isFirstParentBar && barIndex === firstBar) {
        leftRadius = 4;
    }
    if (isLastParentBar && barIndex === lastBar) {
        rightRadius = 4;
    }
    return { topLeft: leftRadius, bottomLeft: leftRadius, topRight: rightRadius, bottomRight: rightRadius };
};

const renderActiveShape = (
    props: BarProps,
    hoverIndex: GateSubTaskStatus | undefined,
    colorsDark: string[],
    numOfBars: number,
    hasFirstBarRadius: boolean,
    hasLastBarRadius: boolean,
) => {
    const { id, fill, x = 0, y = 0, width = 1, height = 1 } = props;
    const colorIndex: number = hoverIndex ? Object.values(GateSubTaskStatus).indexOf(hoverIndex) : -1;
    const colorFill = id === hoverIndex ? colorsDark[colorIndex] : fill;
    const barSpace = width / 100 / numOfBars;
    return (
        <>
            {[...Array(numOfBars)].map((value, index) => {
                const { topLeft, bottomLeft, topRight, bottomRight } = barRadius(hasFirstBarRadius, hasLastBarRadius, index, numOfBars);
                const newXPosition = index === 0 ? Number(x) : Number(x) + (width / numOfBars) * index;
                return (
                    <Rectangle
                        key={`${String(id)}_${index}`}
                        fill={colorFill}
                        x={newXPosition}
                        y={Number(y)}
                        width={width / numOfBars - barSpace}
                        height={height}
                        style={{ cursor: "pointer" }}
                        radius={
                            (index === 0 && hasFirstBarRadius) || (hasLastBarRadius && numOfBars === index + 1)
                                ? [topLeft, topRight, bottomRight, bottomLeft]
                                : 0
                        }
                    />
                );
            })}
        </>
    );
};

const ActivityGateChart = ({ activities, translate, disabled }: IActivityGateChartProps) => {
    const theme = useTheme();
    const activityMenu = useMenu();
    const navigate = useNavigate();
    const showActivity = (selectedTask: SubTaskDto) => navigate(`/measure/${selectedTask.measureId}/activities/${selectedTask.id}`);

    const [openDueSegment, setOpenDueSegment] = useState<GateSubTaskStatus | undefined>(undefined);

    const [hoverIndex, setHoverIndex] = useState<GateSubTaskStatus | undefined>(undefined);

    const users = useAllUsers();

    const colors = [
        theme.palette.dashboardSeaGreen.main,
        theme.palette.dashboardOrange.main,
        theme.palette.dashboardRed.main,
        theme.palette.grey[300],
    ];
    const colorsDark = [
        theme.palette.dashboardSeaGreen.dark,
        theme.palette.dashboardOrange.dark,
        theme.palette.dashboardRed.dark,
        theme.palette.grey[400],
    ];

    const { completed, overdue, duesoon, open, isEmpty, barData } = useActivityLevels(activities);
    const legendData = useActivityLegends(activities);
    const barTypes = [GateSubTaskStatus.COMPLETED, GateSubTaskStatus.DUESOON, GateSubTaskStatus.OVERDUE, GateSubTaskStatus.OPEN];

    const barValues: number[] = !isEmpty ? Object.values(barData[0]).slice(0, 4) : [];

    const firstBarIndex = barValues.findIndex((element) => element > 0);
    const lastBarIndex = findLastIndex(barValues, (element) => element > 0);

    const onSectorEnter = (data: any, index: number | undefined) => {
        setHoverIndex(data.tooltipPayload[0].id);
    };
    const onSectorLeave = (data: any, index: number | undefined) => {
        setHoverIndex(undefined);
    };

    const getTooltipData = (index: GateSubTaskStatus | undefined): [string, SubTaskDto[]] => {
        switch (index) {
            case GateSubTaskStatus.COMPLETED:
                return [translationKeys.VDLANG_GATE_ACTIVITY_TOOLTIP_COMPLETED, completed];
            case GateSubTaskStatus.DUESOON:
                return [translationKeys.VDLANG_GATE_ACTIVITY_TOOLTIP_DUESOON, duesoon];
            case GateSubTaskStatus.OVERDUE:
                return [translationKeys.VDLANG_GATE_ACTIVITY_TOOLTIP_OVERDUE, overdue];
            case GateSubTaskStatus.OPEN:
                return [translationKeys.VDLANG_GATE_ACTIVITY_TOOLTIP_OPEN, open];
            default:
                return [translationKeys.VDLANG_ACTIVITY_CHART, activities];
        }
    };

    return (
        <>
            <Popover {...activityMenu.menuProps}>
                <Grid container>
                    <Stack direction="row">
                        <Typography sx={{ ml: 2, my: 2 }} variant="body1">
                            {translate(getTooltipData(openDueSegment)[0])}
                        </Typography>
                    </Stack>
                    <Grid item xs={12}>
                        <Divider />
                    </Grid>

                    <Grid item xs={12} sx={{ p: 1 }}>
                        <SubtaskList
                            isCreatable={false}
                            disabled={disabled}
                            tasks={getTooltipData(openDueSegment)[1]}
                            translate={translate}
                            users={users}
                            onOpen={showActivity}
                            disablePadding
                            noActivitiesMessage={translate(translationKeys.VDLANG_ACTIVITIES_NO_ACTIVITIES)}
                        />
                    </Grid>
                </Grid>
            </Popover>
            <Grid container alignItems="center" justifyContent="center">
                <Grid item xs={12}>
                    <Stack sx={{ mx: 2, flexWrap: "wrap" }} direction="row">
                        {legendData?.map((legends, legendIndex) => (
                            <Fragment key={legendIndex}>
                                <StyledIcon sx={{ color: colors[legendIndex] }} />
                                <Typography sx={{ mr: 1.5 }} variant="body2">
                                    {legends.value} {legends.name}
                                </Typography>
                            </Fragment>
                        ))}
                    </Stack>
                </Grid>
                <Grid item xs={12}>
                    <ResponsiveContainer height={38}>
                        <BarChart
                            data={barData}
                            margin={{
                                top: 10,
                                right: 15,
                                left: 15,
                            }}
                            layout="vertical"
                            stackOffset="expand"
                        >
                            <XAxis hide type="number" />
                            <YAxis hide type="category" dataKey="name" />
                            {activities.length > 0 ? (
                                barTypes.map((dataKey, barIndex) => (
                                    <Fragment key={dataKey}>
                                        <Bar
                                            id={dataKey}
                                            dataKey={dataKey}
                                            stackId="0"
                                            fill={colors[barIndex]}
                                            onClick={(nextState, index, event) => {
                                                activityMenu.open(event);
                                                setOpenDueSegment(dataKey);
                                            }}
                                            shape={(props: unknown) =>
                                                renderActiveShape(
                                                    props as BarProps,
                                                    hoverIndex,
                                                    colorsDark,
                                                    barValues[barIndex],
                                                    barIndex === firstBarIndex,
                                                    barIndex === lastBarIndex,
                                                )
                                            }
                                            onMouseEnter={onSectorEnter}
                                            onMouseLeave={onSectorLeave}
                                        />
                                    </Fragment>
                                ))
                            ) : (
                                <Bar key="empty" dataKey="empty" stackId="0" fill={theme.palette.grey[100]} radius={4} legendType="none">
                                    <Cell stroke={colorsDark[3]} strokeWidth={1} />
                                </Bar>
                            )}
                        </BarChart>
                    </ResponsiveContainer>
                </Grid>
            </Grid>
        </>
    );
};

export default ActivityGateChart;
