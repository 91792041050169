import { Getter, Getters, Template } from "@devexpress/dx-react-core";
import {
    CustomSummary,
    DataTypeProvider,
    IntegratedSorting,
    IntegratedSummary,
    RowDetailState,
    SortingState,
    SummaryItem,
    SummaryState,
} from "@devexpress/dx-react-grid";
import { Grid, Table, TableFixedColumns, TableHeaderRow, TableRowDetail, TableSummaryRow } from "@devexpress/dx-react-grid-material-ui";
import { styled } from "@mui/material";
import {
    buildEffectKey,
    CurrencyDto,
    EffectCategoryAttributeDto,
    EffectCategoryDto,
    EffectField,
    EffectFilterCurrencyField,
    EffectTotalValues,
    EffectValueType,
    GateTaskConfigDto,
    MeasureDto,
    type GlobalCalculationIdentifier,
    type SpanEffectDto,
    type SpanEffectListDto,
} from "api-shared";
import { TFunction } from "i18next";
import moment from "moment";
import { useCallback, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useCurrencies, useDefaultCurrency } from "../../../../domain/currencies";
import useFieldData from "../../../../hooks/useFieldData";
import useTimezone from "../../../../hooks/useTimezone";
import { compareNullableDate } from "../../../../lib/utils";
import { translationKeys } from "../../../../translations/main-translations";
import EffectCategoryDetails from "../EffectCategoryDetails";
import EffectCategoryTableActions from "./EffectCategoryTableActions";
import EffectFormatter from "./EffectFormatter";
import SummaryItemComponent from "./SummaryItem";
import TooltipFormatter from "./TooltipFormatter";

const actionsClass = "EffectCategoryTable-actions";

const TableRow = styled((props: Table.DataRowProps) => <Table.Row {...props} />)({
    [`&:hover .${actionsClass}`]: {
        // show the actions for the hovered row
        visibility: "visible",
    },
});

const TableTotalRow = styled((props: Table.RowProps) => <TableSummaryRow.TotalRow {...props} />)({
    [`&:hover .${actionsClass}`]: {
        // show the actions for the hovered row
        visibility: "visible",
    },
});

const HoverableActions = styled(EffectCategoryTableActions)({
    // hide action cells by default
    visibility: "hidden",
});

interface EffectCategoryTableProps {
    effectCategories: EffectCategoryDto[];
    expandedRowIds: number[];
    disabled?: boolean;
    gateTaskConfig: GateTaskConfigDto;
    isFirstEffectGate: boolean;
    effectCategoryFields: EffectCategoryAttributeDto[];
    onExpandedRowIdsChange: (newIds: number[]) => void;
    onEdit: (effectCategoryId: number) => void;
    onRemove: (effectCategoryId: number) => void;
    onHistory: (effectCategoryId: number) => void;
    onCopy: (effectCategoryId?: number) => void;
    previousLevelName: string;
    invalidEffectCategories: number[];
    measureEffects: MeasureDto["effects"];
    spanEffects: SpanEffectListDto;
}

interface RelativeEffect {
    value: number | null;
    relativeValue: number | null;
    currency: CurrencyDto;
}

enum Columns {
    EffectType = "effectType",
    Effect = "effect",
    TimeRange = "timerange",
    Actions = "actions",
}

interface EffectColumnValue {
    [EffectField.Effect]: RelativeEffect;
}

interface IDateRange {
    start: Date | null;
    end: Date | null;
}

interface TimeRangeColumnValue {
    [Columns.TimeRange]: IDateRange;
}

interface EffectCategoryTableRow {
    id: number;
    isValid: boolean;
    [Columns.EffectType]: string;
    [Columns.Effect]: RelativeEffect;
    [Columns.TimeRange]: IDateRange;
    currency: CurrencyDto;
    effectValues: EffectTotalValues | null;
    [key: string]: string | RelativeEffect | IDateRange | number | boolean | CurrencyDto | EffectTotalValues | null;
}

const DetailCell = styled((props: TableRowDetail.CellProps) => <TableRowDetail.Cell {...props} />)({
    "&.TableDetailCell-active": {
        padding: 0,
    },
});

const TableCell = styled(({ expanded, ...props }: Table.DataCellProps & { expanded?: boolean }) => <Table.Cell {...props} />)(
    ({ expanded }) => ({
        ...(expanded && { borderBottom: "none" }),
    }),
);

const TableToggleCell = styled((props: TableRowDetail.ToggleCellProps) => <TableRowDetail.ToggleCell {...props} />)(({ expanded }) => ({
    ...(expanded && { borderBottom: "none" }),
}));

const TableTotalCell = styled(({ expanded, ...props }: TableSummaryRow.CellProps & { expanded?: boolean }) => (
    <TableSummaryRow.TotalCell {...props} />
))(({ expanded }) => ({
    "&.TableCell-footer": {
        borderTop: "none",
    },
    ...(expanded && { borderBottom: "none" }),
}));

const TableFixedCell = (props: TableFixedColumns.CellProps) => (
    <TableFixedColumns.Cell
        {...props}
        showLeftDivider={false}
        // use style instead of class to override styles set by a selector with high specificity (3 nested classes)
        style={{
            overflow: "visible", // make absolute positioned children visible
            padding: 0, // remove padding so fixed cell does not occupy any space
        }}
    />
);

const isSummaryDetailToggleCell = ({ tableRow, tableColumn }: any) =>
    // only apply/render this table on detail column in summary row
    tableColumn.type === TableRowDetail.COLUMN_TYPE && tableRow.type === TableSummaryRow.TOTAL_ROW_TYPE;

const getFirstColumnName = (effectCategoryFields: EffectCategoryAttributeDto[]) => {
    const fields = effectCategoryFields.toSorted((a, b) => (a.order !== null && b.order !== null ? a.order - b.order : 0));
    return fields.length > 0 ? fields[0].title : "name";
};

function getSpanEffectValue(
    field: EffectFilterCurrencyField,
    spanEffect?: SpanEffectDto | undefined,
    calculationIdentifier?: GlobalCalculationIdentifier | null,
): number | null {
    if (calculationIdentifier == null || spanEffect == null) {
        return null;
    }
    return spanEffect[buildEffectKey(calculationIdentifier, field, EffectValueType.Input)];
}

const getEffectColumnValue = (
    effectValues: EffectTotalValues | null,
    currency: CurrencyDto,
    spanEffect?: SpanEffectDto | undefined,
    calculationIdentifier?: GlobalCalculationIdentifier | null,
): EffectColumnValue => {
    const initial =
        effectValues?.[EffectFilterCurrencyField.Initial] ??
        getSpanEffectValue(EffectFilterCurrencyField.Initial, spanEffect, calculationIdentifier);
    const effect =
        effectValues?.[EffectFilterCurrencyField.Effect] ??
        getSpanEffectValue(EffectFilterCurrencyField.Effect, spanEffect, calculationIdentifier);

    const relativeEffect = initial != null && initial !== 0 && effect != null ? effect / initial : null;

    return {
        [EffectField.Effect]: { value: effect, relativeValue: relativeEffect, currency },
    };
};

const getTimeRangeColumnValue = (effectValues: EffectTotalValues | null): TimeRangeColumnValue => {
    if (effectValues === null) {
        return {
            [Columns.TimeRange]: { start: null, end: null },
        };
    }

    const start = effectValues.startDate != null ? moment.utc({ ...effectValues.startDate, day: 1 }) : null;
    const end = effectValues.endDate != null ? moment.utc({ ...effectValues.endDate, day: 1 }).endOf("month") : null;

    return {
        [Columns.TimeRange]: {
            start: start != null ? start.toDate() : null,
            end: end != null ? end.toDate() : null,
        },
    };
};

const summaryValuesMapper =
    (
        rows: EffectCategoryTableRow[],
        effectCategoryFields: EffectCategoryAttributeDto[],
        currency: CurrencyDto,
        translate: TFunction,
        hasIgnoredValues: boolean,
        measureTotalEffectValues: EffectTotalValues | null,
    ) =>
    ({ columnName, type }: SummaryItem) => {
        const effectColumnValue = getEffectColumnValue(measureTotalEffectValues, currency);
        const timeRangeColumnValue = getTimeRangeColumnValue(measureTotalEffectValues);

        switch (columnName) {
            case Columns.TimeRange:
                return timeRangeColumnValue[Columns.TimeRange];
            case getFirstColumnName(effectCategoryFields):
                return translate(translationKeys.VDLANG_EFFECT_CATEGORY_GATE_TOTAL_EFFECT);
            case Columns.Actions:
                return {};
            case Columns.Effect:
                // only show relative value in summary if all effects have initial and target
                if (rows.some((row) => row.effect.relativeValue === null)) {
                    effectColumnValue[EffectField.Effect].relativeValue = null;
                }

                return {
                    hasIgnoredValues,
                    ...effectColumnValue[EffectField.Effect],
                };
            default:
                return IntegratedSummary.defaultCalculator(type, rows, (row) => row[columnName]);
        }
    };

const currencyColumns = [Columns.Effect];
const timeRangeColumns = [Columns.TimeRange];
const actionColumns = [Columns.Actions];
const standardSummaryColumns = [
    { columnName: Columns.Effect, type: "custom" },
    { columnName: Columns.TimeRange, type: "custom" },
    { columnName: Columns.Actions, type: "custom" },
];

const tableColumnExtensions = [
    { columnName: Columns.EffectType, width: "150" },
    { columnName: Columns.Effect, align: "right" as const },
    { columnName: Columns.TimeRange, width: "220" },
    // by default, the last column is invisible (1px), but will be shown on hover on the hovered row
    // IE11 does not support sticky columns, so show a column width fixed width
    // 100px roughly corresponds to three IconButtons
    { columnName: Columns.Actions, width: "1", align: "right" as const },
];

const sortingStateColumnExtensions = [{ columnName: Columns.Actions, sortingEnabled: false }];
const sortingColumnExtensions: IntegratedSorting.ColumnExtension[] = [
    {
        columnName: Columns.Effect,
        compare: (a: RelativeEffect, b: RelativeEffect) => (a?.value ?? 0) - (b?.value ?? 0),
    },
    {
        columnName: Columns.TimeRange,
        compare: (a: IDateRange, b: IDateRange) => {
            const startDiff = compareNullableDate(a.start, b.start);
            return startDiff !== 0 ? startDiff : compareNullableDate(a.end, b.end);
        },
    },
];

const EffectCategoryTable = ({
    expandedRowIds,
    onExpandedRowIdsChange,
    effectCategories,
    gateTaskConfig,
    disabled,
    isFirstEffectGate,
    onCopy,
    onEdit,
    onRemove,
    onHistory,
    previousLevelName,
    effectCategoryFields,
    invalidEffectCategories,
    measureEffects,
    spanEffects,
}: EffectCategoryTableProps) => {
    const { formatMonthYear } = useTimezone();
    const { t: translate } = useTranslation();
    const defaultCurrency = useDefaultCurrency();
    const currencies = useCurrencies();

    const showSummaryRow = effectCategories.length > 1;

    const summaryColumns = useMemo(
        () => [...standardSummaryColumns, { columnName: getFirstColumnName(effectCategoryFields), type: "custom" }],
        [effectCategoryFields],
    );

    const columns = useMemo(() => {
        return [
            ...effectCategoryFields
                .toSorted((a, b) => (a.order !== null && b.order !== null ? a.order - b.order : 0))
                .map((field) => ({ name: field.title, title: translate(field.title) })),
            { name: Columns.EffectType, title: translate(translationKeys.VDLANG_EFFECT_CATEGORY_GATE_HEADER_EFFECT_TYPE) },
            { name: Columns.Effect, title: translate(translationKeys.VDLANG_EFFECT_CATEGORY_GATE_HEADER_EFFECT) },
            { name: Columns.TimeRange, title: translate(translationKeys.VDLANG_EFFECT_CATEGORY_GATE_HEADER_TIMERANGE) },
            {
                name: Columns.Actions,
                // Display empty title in header for actions column by using a zero-width whitespace here
                title: "​",
            },
        ].map((column, index) => ({ ...column, index }));
    }, [translate, effectCategoryFields]);

    const textColumns = useMemo(() => {
        const specialColumns: string[] = [...currencyColumns, ...actionColumns, ...timeRangeColumns];
        return columns.map(({ name }) => name).filter((name) => !specialColumns.includes(name));
    }, [columns]);

    const fieldData = useFieldData(effectCategoryFields, { fullTreePathValues: true });

    const [expandedSummary, setExpandedSummary] = useState(false);

    const rows: EffectCategoryTableRow[] = useMemo(
        () =>
            effectCategories.map((effectCategory) => {
                const currency = currencies.find((currency) => currency.id === effectCategory.currencyId) ?? defaultCurrency;
                const effectCategoryTotalValues =
                    gateTaskConfig.calculationIdentifier != null
                        ? (effectCategory.aggregatedInputEffects[gateTaskConfig.calculationIdentifier] ?? null)
                        : null;
                const spanEffect = spanEffects.find((spanEffect) => spanEffect.effectCategoryId === effectCategory.id);
                const effectColumnValue = getEffectColumnValue(
                    effectCategoryTotalValues,
                    currency,
                    spanEffect,
                    gateTaskConfig.calculationIdentifier,
                );
                const timeRangeColumnValue = getTimeRangeColumnValue(effectCategoryTotalValues);

                const effectCategoryValues: Record<string, string> = {};
                effectCategoryFields.forEach((attribute, index) => {
                    const value = effectCategory.effectCategoryValues.find((value) => value.effectCategoryAttributeId === attribute.id);
                    const option = fieldData[index].find(({ id }) => id === value?.value);
                    effectCategoryValues[attribute.title] = option?.name ?? "";
                });

                const isValid = !invalidEffectCategories.includes(effectCategory.id);

                return {
                    id: effectCategory.id,
                    isValid,
                    effectType: translate(`effect_type_${effectCategory.effectType}`),
                    currency: currency,
                    effectValues: effectCategoryTotalValues,
                    ...effectCategoryValues,
                    ...effectColumnValue,
                    ...timeRangeColumnValue,
                };
            }),
        [
            effectCategories,
            effectCategoryFields,
            fieldData,
            invalidEffectCategories,
            translate,
            gateTaskConfig.calculationIdentifier,
            spanEffects,
            currencies,
            defaultCurrency,
        ],
    );

    const summaryRowValues = useMemo(() => {
        const hasIgnoredValues = effectCategories.some((effectCategory) => invalidEffectCategories.includes(effectCategory.id));
        const measureTotalValues =
            gateTaskConfig.calculationIdentifier != null ? (measureEffects[gateTaskConfig.calculationIdentifier] ?? null) : null;

        return summaryColumns.map(
            summaryValuesMapper(rows, effectCategoryFields, defaultCurrency, translate, hasIgnoredValues, measureTotalValues),
        );
    }, [
        effectCategories,
        summaryColumns,
        rows,
        effectCategoryFields,
        defaultCurrency,
        translate,
        gateTaskConfig,
        measureEffects,
        invalidEffectCategories,
    ]);

    const updateExpandedRows = useCallback(
        (newIds: (string | number)[]) => onExpandedRowIdsChange(newIds.map((id) => Number(id))),
        [onExpandedRowIdsChange],
    );

    /**
     * Returns an additional row with summary details if the footer summary row is expanded
     */
    const computeSummaryDetailRow = useCallback(
        ({ tableFooterRows: tableSummaryRows }: Getters) => {
            if (!expandedSummary) {
                return tableSummaryRows;
            }
            const gateTotalEffectValues =
                gateTaskConfig.calculationIdentifier != null ? (measureEffects[gateTaskConfig.calculationIdentifier] ?? null) : null;

            return tableSummaryRows.concat({
                key: `${TableRowDetail.ROW_TYPE.toString()}_${String(tableSummaryRows[0].rowId)}`,
                type: TableRowDetail.ROW_TYPE,
                // set values for RowDetailComponent on the row
                row: { effectValues: gateTotalEffectValues, isSummary: true },
            });
        },
        [expandedSummary, gateTaskConfig, measureEffects],
    );

    const RowDetailComponent = useCallback(
        ({ row: { currency, effectValues, isSummary } }: TableRowDetail.ContentProps) => {
            return (
                <EffectCategoryDetails
                    isSummary={isSummary}
                    effectValues={effectValues}
                    calculationIdentifier={gateTaskConfig.calculationIdentifier}
                    currency={currency ?? defaultCurrency}
                />
            );
        },
        [gateTaskConfig, defaultCurrency],
    );

    const ExpandableTableCell = useCallback(
        (props: Table.DataCellProps) => <TableCell expanded={expandedRowIds.includes(Number(props.tableRow.rowId))} {...props} />,
        [expandedRowIds],
    );
    const ExpandableTotalCell = useCallback(
        (props: TableSummaryRow.CellProps) => <TableTotalCell expanded={expandedSummary} {...props} />,
        [expandedSummary],
    );

    const TimeRangeFormatter = useCallback(
        ({ row, value, ...other }: DataTypeProvider.ValueFormatterProps) => {
            const { start, end } = value as IDateRange;
            const text =
                start != null && end != null
                    ? `${formatMonthYear(start, { noTimezone: true })} - ${formatMonthYear(end, { noTimezone: true })}`
                    : "";
            return <TooltipFormatter value={text} row={row} {...other} />;
        },
        [formatMonthYear],
    );

    const ActionsFormatter = useCallback(
        ({ row }: DataTypeProvider.ValueFormatterProps) => {
            const id = row?.id;
            return (
                <HoverableActions
                    isSummary={row === undefined}
                    className={actionsClass}
                    disabled={disabled}
                    onEdit={id !== undefined ? () => onEdit(id) : undefined}
                    onCopy={() => onCopy(id)}
                    onRemove={id !== undefined ? () => onRemove(id) : undefined}
                    onHistory={id !== undefined ? () => onHistory(id) : undefined}
                    isFirstCalculationGate={isFirstEffectGate}
                    previousLevelName={previousLevelName}
                    effectType={row?.effectType}
                />
            );
        },
        [disabled, onEdit, onCopy, onRemove, onHistory, isFirstEffectGate, previousLevelName],
    );

    return (
        <Grid rows={rows} columns={columns} getRowId={(row) => row.id}>
            <DataTypeProvider formatterComponent={EffectFormatter} for={currencyColumns} />
            <DataTypeProvider formatterComponent={TimeRangeFormatter} for={timeRangeColumns} />
            <DataTypeProvider formatterComponent={ActionsFormatter} for={actionColumns} />
            <DataTypeProvider formatterComponent={TooltipFormatter} for={textColumns} />
            {showSummaryRow && <SummaryState totalItems={summaryColumns} />}
            <RowDetailState expandedRowIds={expandedRowIds} onExpandedRowIdsChange={updateExpandedRows} />
            <SortingState columnExtensions={sortingStateColumnExtensions} />
            <IntegratedSorting columnExtensions={sortingColumnExtensions} />
            {showSummaryRow && <CustomSummary totalValues={summaryRowValues} />}
            <Table columnExtensions={tableColumnExtensions} cellComponent={ExpandableTableCell} rowComponent={TableRow} />
            <TableHeaderRow showSortingControls messages={{ sortingHint: "" }} />
            <TableRowDetail contentComponent={RowDetailComponent} cellComponent={DetailCell} toggleCellComponent={TableToggleCell} />
            {showSummaryRow && (
                <TableSummaryRow
                    itemComponent={SummaryItemComponent}
                    totalCellComponent={ExpandableTotalCell}
                    totalRowComponent={TableTotalRow}
                />
            )}
            {showSummaryRow && <Getter name="tableFooterRows" computed={computeSummaryDetailRow} />}
            {showSummaryRow && (
                <Template name="tableCell" predicate={isSummaryDetailToggleCell}>
                    {({ tableRow, tableColumn, row }: any) => (
                        <TableToggleCell
                            tableRow={tableRow}
                            tableColumn={tableColumn}
                            row={row}
                            expanded={expandedSummary}
                            onToggle={() => setExpandedSummary(!expandedSummary)}
                        />
                    )}
                </Template>
            )}
            <TableFixedColumns rightColumns={actionColumns} cellComponent={TableFixedCell} />
        </Grid>
    );
};

export default EffectCategoryTable;
