import NotificationsOffIcon from "@mui/icons-material/NotificationsOffRounded";
import NotificationsIcon from "@mui/icons-material/NotificationsRounded";
import { IconButton, Popover } from "@mui/material";
import { useQueryClient } from "@tanstack/react-query";
import { MeasureNotificationDto, MeasureNotificationState } from "api-shared";
import { TFunction } from "i18next";
import { useCallback } from "react";
import { useDispatch } from "react-redux";
import Tooltip from "../../components/Tooltip";
import { useProcessName } from "../../domain/measure-config";
import { NotificationQueryKeys, useMeasureNotifications } from "../../domain/measure-notifications";
import { useAllUsers, useCurrentUserId } from "../../domain/users";
import useMenu from "../../hooks/useMenu";
import { showNotificationEvent } from "../../infrastructure/notifications";
import { formatUsersFromIds } from "../../lib/formatters";
import { NotificationType } from "../../lib/notifications";
import { translationKeys } from "../../translations/main-translations";
import { useMeasureContext } from "../MeasureContext";
import MeasureNotificationsContent from "./MeasureNotificationsContent";

interface IMeasureNotificationsMenuProps {
    measureId: number;
    translate: TFunction;
}
const diffByUserId = (oldList: MeasureNotificationDto[], newList: MeasureNotificationDto[]) => {
    const oldIds = oldList.map((u) => u.userId);
    const newIds = newList.map((u) => u.userId);
    const enabled = newIds.filter((n) => !oldIds.includes(n));
    const disabled = oldIds.filter((o) => !newIds.includes(o));
    return { enabled, disabled };
};

const MeasureNotificationsMenu = ({ measureId, translate }: IMeasureNotificationsMenuProps) => {
    const { openMenu, menuProps } = useMenu();
    const users = useAllUsers();
    const currentUserId = useCurrentUserId();
    const queryClient = useQueryClient();

    const dispatch = useDispatch();
    const measure = useMeasureContext();
    const processName = useProcessName(measure);

    const onSuccessCallback = useCallback(
        (response: MeasureNotificationDto[], oldNotifications: any) => {
            if (!oldNotifications) {
                return;
            }
            const previouslyActiveNotifications = oldNotifications.filter(
                (n: MeasureNotificationDto) => n.state === MeasureNotificationState.ENABLED,
            );

            const currentlyActiveNotifications = response.filter((n) => n.state === MeasureNotificationState.ENABLED);

            // If meta data exists for this query, check for callback skip
            const meta = queryClient.getQueryCache().find(NotificationQueryKeys.forMeasure(measureId))?.meta;
            if (meta?.skipOnSuccess) {
                return;
            }

            // Trigger notifications
            const { enabled, disabled } = diffByUserId(previouslyActiveNotifications ?? [], currentlyActiveNotifications);

            if (enabled.length > 0) {
                const isCurrentUser = Array.isArray(enabled) && enabled.length === 1 && enabled[0] === currentUserId;
                if (isCurrentUser) {
                    dispatch(
                        showNotificationEvent(
                            NotificationType.SUCCESS,
                            translationKeys.VDLANG_MEASURE_NOTIFICATIONS_OWN_NOTIFICATIONS_ENABLED,
                            { processName: translate(processName) },
                        ),
                    );
                } else {
                    dispatch(
                        showNotificationEvent(
                            NotificationType.SUCCESS,
                            translationKeys.VDLANG_MEASURE_NOTIFICATIONS_OTHER_NOTIFICATIONS_ENABLED,
                            {
                                users: formatUsersFromIds(enabled, users, { translate }),
                            },
                        ),
                    );
                }
            }

            if (disabled.length > 0) {
                const isCurrentUser = Array.isArray(disabled) && disabled.length === 1 && disabled[0] === currentUserId;
                if (isCurrentUser) {
                    dispatch(
                        showNotificationEvent(
                            NotificationType.SUCCESS,
                            translationKeys.VDLANG_MEASURE_NOTIFICATIONS_OWN_NOTIFICATIONS_DISABLED,
                            { processName: translate(processName) },
                        ),
                    );
                } else {
                    dispatch(
                        showNotificationEvent(
                            NotificationType.SUCCESS,
                            translationKeys.VDLANG_MEASURE_NOTIFICATIONS_OTHER_NOTIFICATIONS_DISABLED,
                            {
                                users: formatUsersFromIds(disabled, users, { translate }),
                            },
                        ),
                    );
                }
            }
        },
        [dispatch, processName, currentUserId, measureId, queryClient, translate, users],
    );

    const notifications = useMeasureNotifications(measureId, onSuccessCallback);

    return (
        <>
            <Popover
                {...menuProps}
                anchorOrigin={{
                    vertical: "bottom",
                    horizontal: "center",
                }}
                transformOrigin={{
                    vertical: "top",
                    horizontal: "center",
                }}
            >
                <MeasureNotificationsContent notifications={notifications?.data ?? null} processName={processName} measure={measure} />
            </Popover>
            {notifications.isSuccess && (
                <Tooltip title={translate("notifications")}>
                    <IconButton onClick={openMenu} size="small">
                        {notifications.data.find((n) => n.userId === currentUserId && n.state === MeasureNotificationState.ENABLED) ? (
                            <NotificationsIcon />
                        ) : (
                            <NotificationsOffIcon />
                        )}
                    </IconButton>
                </Tooltip>
            )}
        </>
    );
};

export default MeasureNotificationsMenu;
