import { Box, Checkbox, Divider, FormControlLabel } from "@mui/material";
import {
    GetMeasureNotificationsResponseDto,
    MeasureDto,
    MeasureNotificationActions,
    MeasureNotificationState,
    UserStatus,
} from "api-shared";
import React from "react";
import { useTranslation } from "react-i18next";
import InfoIcon from "../../components/icons/InfoIcon";
import LoadingAnimation from "../../components/loading/LoadingAnimation";
import SelectUsers from "../../components/user/SelectUsers";
import { useCreateMeasureNotification, useUpdateMeasureNotification } from "../../domain/measure-notifications";
import { useMeasureAssignedTo } from "../../domain/measure/detail";
import { useMeasureViewersQuery } from "../../domain/measure/permission";
import { isActiveUser, useAllUsers, useCurrentUserId } from "../../domain/users";
import { formatUser } from "../../lib/formatters";
import { translationKeys } from "../../translations/main-translations";

interface MeasureNotificationsContentProps {
    measure: MeasureDto;
    processName: string;
    notifications: GetMeasureNotificationsResponseDto | null;
    disabled?: boolean;
}
const MeasureNotificationsContent = (props: MeasureNotificationsContentProps) => {
    const currentUserId = useCurrentUserId();
    const createNotification = useCreateMeasureNotification().mutate;
    const updateNotification = useUpdateMeasureNotification().mutate;

    const { measure } = props;
    const { t: translate } = useTranslation();
    const viewersQuery = useMeasureViewersQuery(measure.id);
    const allUsers = useAllUsers();
    const assignedTo = useMeasureAssignedTo(measure);
    const notifications = props.notifications ?? null;

    if (notifications == null || !viewersQuery.isSuccess) {
        return <LoadingAnimation />;
    }

    const processName = props.processName;
    const measureId = measure.id;
    const { disabled = false } = props;

    const enableNotifications = (userId: number) => {
        // Create notification if it does not yet exist for user
        if (notifications.find((n) => n.userId === userId) === undefined) {
            createNotification({ measureId, userId });
        } else {
            updateNotification({ measureId, userId, action: MeasureNotificationActions.ENABLE });
        }
    };

    const disableNotifications = (userId: number) => {
        updateNotification({ measureId, userId, action: MeasureNotificationActions.DISABLE });
    };

    const disableSelfNotifications = () => currentUserId != null && disableNotifications(currentUserId);
    const enableSelfNotifications = () => currentUserId != null && enableNotifications(currentUserId);

    const getSelectedTitle = () => {
        return (
            <>
                {translate(translationKeys.VDLANG_MEASURE_NOTIFICATIONS_ENABLED_NOTIFICATIONS)}{" "}
                <InfoIcon title={translate(translationKeys.VDLANG_MEASURE_NOTIFICATIONS_ENABLED_NOTIFICATIONS_HINT)} />
            </>
        );
    };

    const fixed: number[] = [];

    const viewers = allUsers.filter((u) => viewersQuery.data.combinedUserIds.includes(u.id));

    const viewerIds = viewers.map((viewer) => viewer.id);
    const selected = notifications
        .filter((n) => n.state === MeasureNotificationState.ENABLED)
        .map((n) => n.userId)
        .filter((id) => viewerIds.includes(id));

    const otherUsersWithNotificationsDisabled = notifications
        .filter((n) => currentUserId !== n.userId && n.state === MeasureNotificationState.DISABLED_BY_OWNING_USER)
        .map((n) => n.userId);

    const areSelfNotificationsEnabled = currentUserId != null && selected.includes(currentUserId);

    return (
        <>
            <Box py={1} px={3}>
                <FormControlLabel
                    control={
                        <Checkbox
                            disabled={disabled}
                            checked={areSelfNotificationsEnabled}
                            onChange={areSelfNotificationsEnabled ? disableSelfNotifications : enableSelfNotifications}
                        />
                    }
                    label={translate(translationKeys.VDLANG_MEASURE_NOTIFICATIONS_RECEIVE_NOTIFICATIONS)}
                />
            </Box>
            <Divider />
            <SelectUsers
                available={isActiveUser}
                selected={[...new Set(selected)]}
                fixed={fixed}
                translate={translate}
                disabled={disabled || assignedTo == null || assignedTo.id !== currentUserId}
                disabledUsers={otherUsersWithNotificationsDisabled}
                users={[...new Set(viewers)]}
                addUser={enableNotifications}
                removeUser={disableNotifications}
                selectedTitle={getSelectedTitle()}
                labelChange={translate(translationKeys.VDLANG_MEASURE_NOTIFICATIONS_ADD_USER_TITLE)}
                labelFill={translate(translationKeys.VDLANG_MEASURE_NOTIFICATIONS_ADD_USER_TITLE)}
                disabledMessage={
                    assignedTo != null && assignedTo.status !== UserStatus.STATUS_DELETED
                        ? translate(translationKeys.VDLANG_MEASURE_NOTIFICATIONS_ONLY_MEASURE_OWNER_CAN_CHANGE_WITH_USER, {
                              userName: formatUser(assignedTo, { translate }),
                          })
                        : translate(translationKeys.VDLANG_MEASURE_NOTIFICATIONS_ONLY_MEASURE_OWNER_CAN_CHANGE, {
                              processName: translate(processName),
                          })
                }
                disabledUserMessage={translate(translationKeys.VDLANG_MEASURE_NOTIFICATIONS_USER_DISABLED_HINT)}
            />
        </>
    );
};

export default React.memo(MeasureNotificationsContent);
