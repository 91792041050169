import { Stack, styled } from "@mui/material";
import { MeasureDto } from "api-shared";
import { MeasureBreadcrumb } from "../../components/MeasureBreadcrumb";
import EditableText from "../../components/input/EditableText";
import { IUpdateMeasureOptions, useCurrentUserCanEditMeasure } from "../../domain/measure/detail";

const GrowingEditableText = styled(EditableText)(({ theme }) => ({
    flexGrow: 1,
    flexShrink: 1,
    minWidth: 0,
}));

interface IMeasureHeaderProps {
    measure: MeasureDto;
    ideaDisplayId?: number;
    updateMeasure: (payload: IUpdateMeasureOptions) => void;
    openIdeaModal: () => void;
}

const MeasureHeader = ({ measure, updateMeasure, openIdeaModal, ideaDisplayId }: IMeasureHeaderProps) => {
    const currentUserCanEditMeasure = useCurrentUserCanEditMeasure(measure);
    const saveTitle = (newTitle: string) =>
        newTitle !== measure.title && updateMeasure({ measureId: measure.id, changes: { title: newTitle } });
    return (
        <Stack direction="row" alignItems="center" sx={{ pl: 1.5, pr: 2 }} spacing={1}>
            <MeasureBreadcrumb
                measureDisplayId={measure.clientIid}
                ideaDisplayId={ideaDisplayId}
                ideaChipProps={{
                    onClick: openIdeaModal,
                }}
                ideaChipTestId="ideaOrigin_IdChip"
            />
            <GrowingEditableText
                variant="body1"
                size="small"
                noWrap
                text={measure.title}
                disabled={!currentUserCanEditMeasure}
                onTextSave={saveTitle}
            />
        </Stack>
    );
};

export default MeasureHeader;
