import { arrayMoveImmutable } from "array-move";
import { isEqual } from "lodash";
import { useCallback, useEffect, useMemo, useState } from "react";

interface IOrderable {
    id: number;
    order: number;
}

export interface IUseCachedOrderResult {
    order: number[];
    updateOrder: ({ oldIndex, newIndex }: { oldIndex: number; newIndex: number }) => void;
    resetOrder: () => void;
}

interface IUseCachedOrderProps {
    saveOrder: (oldIndex: number, newIndex: number) => void;
    items: IOrderable[];
}

const computeOrder = (list: IOrderable[]) => [...list].sort((a, b) => a.order - b.order).map(({ id }) => id);

const useCachedOrder = ({ saveOrder, items }: IUseCachedOrderProps) => {
    const [cachedOrder, setCachedOrder] = useState<number[] | null>(null);
    useEffect(() => {
        if (cachedOrder == null) {
            return;
        }
        const orderedIds = computeOrder(items);
        if (isEqual(orderedIds, cachedOrder)) {
            // reset cached order value, because it is equal to the provided order
            // This usually happens when the order has been saved successfully and is returned by the backend
            setCachedOrder(null);
        }
    }, [items, cachedOrder]);

    const orderMemoized = useMemo(() => cachedOrder ?? computeOrder(items), [items, cachedOrder]);

    const updateOrder = useCallback(
        (oldIndex: number, newIndex: number) => {
            const currentOrder = orderMemoized;
            if (oldIndex < currentOrder.length) {
                // update order only for existing items
                const newOrder = arrayMoveImmutable(currentOrder, oldIndex, newIndex);
                setCachedOrder(newOrder);
                saveOrder(oldIndex, newIndex);
            }
        },
        [orderMemoized, saveOrder],
    );

    return {
        order: orderMemoized,
        updateOrder: updateOrder,
    };
};

export default useCachedOrder;
