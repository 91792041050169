import { Stack } from "@mui/material";
import { GateTaskType, MeasureDto, MeasureUpdate } from "api-shared";
import React from "react";
import { useTranslation } from "react-i18next";
import LoadingAnimation from "../../components/loading/LoadingAnimation";
import { useMeasureConfigById } from "../../domain/measure-config";
import { useCurrentUserCanEditMeasure, useUpdateMeasure } from "../../domain/measure/detail";
import EffectivenessGate from "./EffectivenessGate";
import Gate from "./Gate";
import DecisionGate from "./decision/DecisionGate";
import ActivityGate from "./levels/ActivityGate";
import CalculationLevel from "./levels/CalculationLevel";

// TODO:
// Gate component will inject the following props:
// use dummy values here so satisfy compiler
const InjectedProps = {
    fields: [] as any[],
    gateTask: {} as any,
    updateMeasure: (() => {}) as any,
    measureId: 0 as any,
    key: 0 as any,
    name: "" as string,
};

interface IGateListProps {
    measure: MeasureDto;
}
const GateList = ({ measure }: IGateListProps) => {
    const updateMeasureMutation = useUpdateMeasure();
    const currentUserCanEditMeasure = useCurrentUserCanEditMeasure(measure);

    const updateFieldHandler = (changes: MeasureUpdate) => updateMeasureMutation.mutate({ measureId: measure.id, changes });

    const { t: translate } = useTranslation();

    const mapping = {
        [GateTaskType.Calculation]: (
            <CalculationLevel translate={translate} disabled={!currentUserCanEditMeasure} {...InjectedProps} key={measure.id} />
        ),
        [GateTaskType.Decision]: <DecisionGate updateFieldHandler={updateFieldHandler} {...InjectedProps} />,
        [GateTaskType.Activities]: <ActivityGate {...InjectedProps} />,
        [GateTaskType.Effectiveness]: <EffectivenessGate measureConfig={measure.measureConfig} translate={translate} />,
    };

    const orderedGateTaskConfigs = [...measure.measureConfig.gateTaskConfigs].sort((a, b) => a.order - b.order);
    const orderedComponents = orderedGateTaskConfigs.map((gtc) => mapping[gtc.type]);

    const measureConfig = useMeasureConfigById(measure.measureConfigId);

    if (measureConfig == null) {
        return <LoadingAnimation />;
    }

    return (
        <Stack spacing={1}>
            {orderedGateTaskConfigs.map((gateTaskConfig, index) => (
                <Gate
                    key={gateTaskConfig.id}
                    gateTaskConfigId={gateTaskConfig.id}
                    name={gateTaskConfig.name}
                    gateIndex={index}
                    measureConfig={measureConfig}
                >
                    {orderedComponents[index]}
                </Gate>
            ))}
        </Stack>
    );
};

export default React.memo(GateList);
