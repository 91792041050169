import { closestCenter, DndContext } from "@dnd-kit/core";
import { restrictToFirstScrollableAncestor, restrictToVerticalAxis } from "@dnd-kit/modifiers";
import { SortableContext, verticalListSortingStrategy } from "@dnd-kit/sortable";
import { Stack } from "@mui/material";
import { FeatureFlags } from "api-shared";
import { sortBy } from "lodash";
import React, { useCallback } from "react";
import { useTranslation } from "react-i18next";
import LoadingAnimation from "../../components/loading/LoadingAnimation";
import SubtaskMasterDetail, { ISubtaskMasterDetailProps } from "../../components/tasks/SubtaskMasterDetail";
import SubtaskRouter from "../../components/tasks/SubtaskRouter";
import { useClientHasFeature } from "../../domain/client";
import { useActivityTemplate } from "../../domain/methods/activity-template";
import { useCostLeversQuery } from "../../domain/methods/cost-lever";
import { useApplyActivityTemplate, useSubTasksByMeasure, useUpdateSubtaskOrder } from "../../domain/subtasks";
import useCachedOrder from "../../hooks/useCachedOrder";
import { useLanguage } from "../../hooks/useLanguage";
import usePendingItems from "../../hooks/usePendingItems";
import useSortableList from "../../hooks/useSortableList";
import ApplyActivityTemplate from "./ApplyActivityTemplate";
import EffortOverviewForMeasure from "./EffortOverviewForMeasure";

export interface ISubtasksForMeasureProps extends Omit<ISubtaskMasterDetailProps, "tasks"> {
    measureId: number;
    methodId?: number;
    measureNotEditable: boolean;
}

const DragAndDropModifiers = [restrictToVerticalAxis, restrictToFirstScrollableAncestor];

const SubtasksForMeasure = ({ measureId, methodId, measureNotEditable, items, ...otherProps }: ISubtasksForMeasureProps) => {
    const hasEffortEstimationFeature = useClientHasFeature(FeatureFlags.FEATURE_EFFORT_ESTIMATION);
    const updateSubTaskOrder = useUpdateSubtaskOrder().mutate;
    const applyActivityTemplate = useApplyActivityTemplate();
    const subTasks = useSubTasksByMeasure(measureId);

    const language = useLanguage();
    const { t: translate } = useTranslation();

    const { items: itemsWithPending, ...pendingItemProps } = usePendingItems(subTasks.data ?? []);
    const saveOrderMemoized = useCallback(
        (oldOrder: number, newOrder: number) => updateSubTaskOrder({ measureId, oldOrder, newOrder }),
        [measureId, updateSubTaskOrder],
    );

    const { order: cachedOrder, updateOrder: updateCachedOrder } = useCachedOrder({
        items: itemsWithPending,
        saveOrder: saveOrderMemoized,
    });

    const { sensors, onDragEnd, onDragStart } = useSortableList({
        items: sortBy(itemsWithPending, (i) => cachedOrder.indexOf(i.id)),
        updateIndex: updateCachedOrder,
    });

    const costLeverQuery = useCostLeversQuery();
    const activityTemplateQuery = useActivityTemplate({ methodId });

    const applyActivityTemplateToMeasure = (templateId: number, activityIds: number[]) =>
        applyActivityTemplate.mutate({ measureId, templateId, activityIds });

    if (!subTasks.isSuccess) {
        return <LoadingAnimation />;
    }

    const isActivityTemplateRenderable =
        costLeverQuery.isSuccess &&
        activityTemplateQuery.isSuccess &&
        activityTemplateQuery.data.items.length > 0 &&
        !measureNotEditable &&
        methodId;
    const method = isActivityTemplateRenderable ? costLeverQuery.data.find((method) => method.id === methodId) : undefined;

    return (
        <Stack spacing={1} sx={{ height: "100%" }}>
            {isActivityTemplateRenderable && method ? (
                <ApplyActivityTemplate
                    method={method}
                    activityTemplate={activityTemplateQuery.data}
                    applyActivityTemplate={applyActivityTemplateToMeasure}
                    language={language}
                    translate={translate}
                />
            ) : null}
            {hasEffortEstimationFeature && <EffortOverviewForMeasure tasks={itemsWithPending} />}
            <DndContext
                sensors={sensors}
                collisionDetection={closestCenter}
                onDragStart={onDragStart}
                onDragEnd={onDragEnd}
                modifiers={DragAndDropModifiers}
            >
                <SortableContext items={cachedOrder} strategy={verticalListSortingStrategy}>
                    <SubtaskRouter>
                        <SubtaskMasterDetail
                            isSortable
                            tasks={itemsWithPending}
                            disabled={measureNotEditable}
                            hideMeasureInfo // measure context is clear, so no need to repeat that information
                            order={cachedOrder}
                            {...pendingItemProps}
                            {...otherProps}
                        />
                    </SubtaskRouter>
                </SortableContext>
            </DndContext>
        </Stack>
    );
};

export default React.memo(SubtasksForMeasure);
