import { DataTypeProvider } from "@devexpress/dx-react-grid";
import WarningAmber from "@mui/icons-material/WarningAmberRounded";
import { Stack, styled } from "@mui/material";
import Tooltip from "../../../../components/Tooltip";

// Overflow stylings need to be applied in case the cell content is displayed next to the warning icon
const CellContent = styled("span")(() => ({
    minWidth: 0,
    overflow: "hidden",
    textOverflow: "ellipsis",
}));

const TooltipFormatter = ({ value, column, row }: DataTypeProvider.ValueFormatterProps) => {
    const cell = (
        <Tooltip title={value ?? ""}>
            <CellContent>{value}</CellContent>
        </Tooltip>
    );

    // If the row is invalid, the first column should have a warning icon
    const hasWarning = (column as any)?.index === 0 && row !== undefined && !row.isValid;

    if (hasWarning) {
        return (
            <Stack direction="row" alignItems="center" spacing={1}>
                {cell}
                <WarningAmber color="warning" sx={{ fontSize: "1rem" }} />
            </Stack>
        );
    }

    return cell;
};

export default TooltipFormatter;
