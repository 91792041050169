import { alpha, Chip, chipClasses, ChipProps, styled } from "@mui/material";
import { CurrencyDto } from "api-shared";
import { ReactElement } from "react";
import { noOp } from "../lib/utils";
import Currency from "./Currency";
import Tooltip from "./Tooltip";

const TextComponent = styled("span", { shouldForwardProp: (name) => name !== "value" })<{ value: number }>(({ theme, value }) => ({
    ...(value === 0 && { color: theme.palette.text.primary }),
    ...(value > 0 && { color: theme.palette.success.dark }),
    ...(value < 0 && { color: theme.palette.error.dark }),
}));

const ChipComponent = styled(Chip, { shouldForwardProp: (name) => name !== "value" })<ChipProps & { value: number }>(
    ({ theme, value }) => ({
        maxWidth: "100%",
        [`& .${chipClasses.label}`]: {
            ...theme.typography.body2,
            fontWeight: "inherit",
        },
        ...(value === 0 && {
            color: theme.palette.text.primary,
        }),
        ...(value > 0 && {
            color: theme.palette.success.dark,
            backgroundColor: alpha(theme.palette.success.dark, 0.1),
        }),
        ...(value < 0 && {
            color: theme.palette.error.dark,
            backgroundColor: alpha(theme.palette.error.dark, 0.1),
        }),
    }),
);

interface IEffectProps {
    value: number;
    nullValue?: string;
    suffixText?: string;
    suffixIcon?: ReactElement;
    variant?: "text" | "chip";
    withTooltip?: boolean;
    currency: CurrencyDto;
    className?: string;
}

const Effect = ({ variant = "text", value, nullValue, suffixText, suffixIcon, currency, withTooltip, className }: IEffectProps) => {
    const text = (
        <>
            <Currency value={value} nullValue={nullValue} currency={currency} />
            {suffixText ?? null}
        </>
    );

    if (variant === "chip" && suffixIcon != null) {
        // return early to avoid wrapping whole component in tooltip
        // Chip with suffixIcon handles Tooltip itself
        return (
            <ChipComponent
                value={value}
                size="small"
                className={className}
                label={
                    <Tooltip title={withTooltip ? text : ""}>
                        <span>{text}</span>
                    </Tooltip>
                }
                deleteIcon={suffixIcon}
                onDelete={noOp}
                component="span"
            />
        );
    }

    const content =
        variant === "text" ? (
            <TextComponent value={value} className={className}>
                {text}
                {suffixIcon}
            </TextComponent>
        ) : (
            // suffixIcon has been already handled above
            <ChipComponent value={value} size="small" className={className} label={text} component="span" />
        );

    return withTooltip ? <Tooltip title={text}>{content}</Tooltip> : content;
};

export default Effect;
