import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import { Alert, Button } from "@mui/material";
import { ActivityTemplateDto, CostLeverDto } from "api-shared";
import { TFunction } from "i18next";
import Tooltip from "../../components/Tooltip";
import useDialog from "../../hooks/useDialog";
import { translateFromProperty } from "../../lib/translate";
import { Language, translationKeys } from "../../translations/main-translations";
import ApplyActivityTemplateDialog from "./ApplyActivityTemplateDialog";

export interface IApplyActivityTemplate {
    method: CostLeverDto;
    activityTemplate: ActivityTemplateDto;
    applyActivityTemplate: (templateId: number, activityIds: number[]) => void;
    translate: TFunction;
    language: Language;
}

const ApplyActivityTemplate = ({ method, activityTemplate, applyActivityTemplate, translate, language }: IApplyActivityTemplate) => {
    const dialog = useDialog();
    const name = translateFromProperty(method, "name", language);

    return (
        <>
            <Alert
                sx={{ alignItems: "center" }}
                severity="info"
                icon={
                    <Tooltip title={translate(translationKeys.VDLANG_METHODS_ACTIVITY_TEMPLATE_AVAILABLE_HINT)}>
                        <InfoOutlinedIcon fontSize="inherit" />
                    </Tooltip>
                }
                action={
                    <Button sx={{ marginBottom: 1 }} onClick={dialog.toggleDialog}>
                        {translate(translationKeys.VDLANG_METHODS_ACTIVITY_TEMPLATE_PREVIEW_AND_USE)}
                    </Button>
                }
            >
                {`${translate(translationKeys.VDLANG_METHODS_ACTIVITY_TEMPLATE_AVAILABLE, { code: method.code, name })} `}
            </Alert>
            <ApplyActivityTemplateDialog
                activityTemplate={activityTemplate}
                applyActivityTemplate={applyActivityTemplate}
                open={dialog.isOpen}
                onClose={dialog.toggleDialog}
                language={language}
                translate={translate}
            />
        </>
    );
};

export default ApplyActivityTemplate;
