import { Grid } from "@mui/material";
import { GateTaskDto } from "api-shared";
import { TFunction } from "i18next";
import React, { useState } from "react";
import ConfirmDialog from "../../../components/dialogues/ConfirmDialog";
import DeleteDialog from "../../../components/dialogues/DeleteDialog";
import { useCurrentClient } from "../../../domain/client";
import { useEffectCategories } from "../../../domain/effect-category";
import { useEffectCategoryAttributesForMeasure } from "../../../domain/measure-config";
import { useSpanEffects } from "../../../domain/span-effect";
import useFieldData from "../../../hooks/useFieldData";
import { useLanguage } from "../../../hooks/useLanguage";
import { translationKeys } from "../../../translations/main-translations";
import { useMeasureContext } from "../../MeasureContext";
import AddEffectCategoryButtons from "../AddEffectCategoryButtons";
import CalculatorImage from "../CalculatorImage";
import EffectCategoryDialog from "./EffectCategoryDialog";
import CalculationHistoryDialog from "./effect-category/CalculationHistoryDialog";
import EffectCategoryTable from "./effect-category/EffectCategoryTable";
import { useCalculation } from "./effect-category/useCalculation";

interface ICalculationLevelProps {
    gateTask: GateTaskDto;
    translate: TFunction;
    disabled: boolean;
    className?: string;
}

const CalculationLevel = ({ gateTask, translate, disabled: disabledByProp, className }: ICalculationLevelProps) => {
    const [expanded, setExpanded] = useState<number[]>([]);
    const [categoryToShowHistory, setCategoryToShowHistory] = useState<number | null>(null);

    const measure = useMeasureContext();
    const spanEffectsQuery = useSpanEffects(measure.id);
    const effectCategories = useEffectCategories(measure.id);

    // null => no category selected
    // undefined => all categories shall be copied
    const [categoryToCopy, setCategoryToCopy] = useState<number | null | undefined>(null);

    const lang = useLanguage();
    const client = useCurrentClient();
    const effectCategoryFields = useEffectCategoryAttributesForMeasure(measure);
    const allEffectCategoryValues = useFieldData(effectCategoryFields);

    const {
        categoryToEdit,
        setCategoryToEdit,
        categoryToDelete,
        setCategoryToDelete,
        addCategory,
        removeEffectCategory,
        defaultEffectType,
        copyDataForLevel,
        saveCategory,
    } = useCalculation({
        measureId: measure.id,
        spanEffects: spanEffectsQuery.data ?? [],
        onAddEffectCategorySuccess: (id) => {
            effectCategories.refetch();
            setExpanded([id]);
        },
    });

    const gateTaskConfig = measure.measureConfig.gateTaskConfigs.find((gtc) => gtc.id === gateTask.gateTaskConfigId);

    const effectGateTaskConfigs = measure.measureConfig.gateTaskConfigs
        .filter((gtc) => gtc.calculationIdentifier !== null)
        .sort((a, b) => a.order - b.order);
    const isFirstEffectGate = effectGateTaskConfigs.length > 0 && effectGateTaskConfigs[0].id === gateTask.gateTaskConfigId;

    const currentGateTaskConfigIndex = effectGateTaskConfigs.findIndex(({ id }) => id === gateTask.gateTaskConfigId);
    const previousLevelName = currentGateTaskConfigIndex > 0 ? translate(effectGateTaskConfigs[currentGateTaskConfigIndex - 1].name) : "";
    const invalidEffectCategories = measure.invalidProperties.calculations[gateTask.id];

    if (gateTaskConfig == null) {
        return null;
    }

    const { calculationIdentifier } = gateTaskConfig;
    if (calculationIdentifier == null) {
        return null;
    }

    const copyConfirmed = () => {
        if (categoryToCopy !== null) {
            copyDataForLevel(calculationIdentifier, categoryToCopy);
            setCategoryToCopy(null);
        }
    };

    if (!effectCategories.isSuccess || !spanEffectsQuery.isSuccess) {
        return null;
    }

    const hasEffectCategories = effectCategories.data.length > 0;

    const disabled = disabledByProp || gateTask.locked;

    return (
        <div className={className}>
            <EffectCategoryDialog
                key={`${defaultEffectType}${Number(categoryToEdit)}`}
                open={Boolean(categoryToEdit)}
                onClose={() => setCategoryToEdit(null)}
                translate={translate}
                processName={measure.measureConfig.name}
                client={client}
                lang={lang}
                effectCategory={effectCategories.data.find(({ id }) => id === categoryToEdit)}
                onSave={saveCategory}
                calculationIdentifier={calculationIdentifier}
                disabled={disabled}
                effectCategoryFields={effectCategoryFields}
                allEffectCategories={effectCategories.data}
                showCategoryFields={isFirstEffectGate}
                showCalculationFields
                defaultEffectType={defaultEffectType}
                withBadge={true}
                measure={measure}
                spanEffects={spanEffectsQuery.data}
            />
            <DeleteDialog
                item={`effect_type_${effectCategories.data.find(({ id }) => categoryToDelete === id)?.effectType ?? defaultEffectType}`}
                translate={translate}
                open={categoryToDelete != null}
                onClose={() => setCategoryToDelete(null)}
                onDelete={removeEffectCategory}
            />
            <ConfirmDialog
                open={categoryToCopy !== null}
                onClose={() => setCategoryToCopy(null)}
                item="category"
                title={translate("Copy")}
                primary={translationKeys.VDLANG_CONFIRM}
                translate={translate}
                onConfirm={copyConfirmed}
                primaryDanger
            >
                {translate(translationKeys.VDLANG_CALCULATION_TABLE_COPY_DATA_WARNING)}
            </ConfirmDialog>
            {categoryToShowHistory !== null ? (
                <CalculationHistoryDialog
                    open={Boolean(categoryToShowHistory)}
                    categoryId={categoryToShowHistory}
                    translate={translate}
                    onClose={() => setCategoryToShowHistory(null)}
                />
            ) : null}
            {hasEffectCategories ? (
                <EffectCategoryTable
                    effectCategories={effectCategories.data}
                    expandedRowIds={expanded}
                    onExpandedRowIdsChange={setExpanded}
                    onEdit={setCategoryToEdit}
                    onCopy={setCategoryToCopy}
                    onRemove={setCategoryToDelete}
                    onHistory={(id) => setCategoryToShowHistory(id)}
                    gateTaskConfig={gateTaskConfig}
                    disabled={disabled}
                    isFirstEffectGate={isFirstEffectGate}
                    previousLevelName={previousLevelName}
                    effectCategoryFields={effectCategoryFields}
                    invalidEffectCategories={invalidEffectCategories}
                    measureEffects={measure.effects}
                    spanEffects={spanEffectsQuery.data}
                />
            ) : (
                <Grid container justifyContent="center" p={3}>
                    <Grid item>
                        <CalculatorImage />
                    </Grid>
                </Grid>
            )}
            {isFirstEffectGate && !disabled && (
                <Grid container justifyContent={hasEffectCategories ? "flex-start" : "center"} p={3}>
                    <Grid item>
                        <AddEffectCategoryButtons
                            onAdd={addCategory}
                            disabled={disabled}
                            translate={translate}
                            allValues={allEffectCategoryValues}
                            effectCategories={effectCategories.data}
                        />
                    </Grid>
                </Grid>
            )}
        </div>
    );
};

export default React.memo(CalculationLevel);
