import { SubTaskDto, SubTaskStatus } from "api-shared";
import moment from "moment-timezone";
import { useMemo } from "react";
import { useTranslation } from "react-i18next";
import useTimezone from "../../../hooks/useTimezone";
import { translationKeys } from "../../../translations/main-translations";

interface IBarData {
    completed?: number;
    overdue?: number;
    duesoon?: number;
    open?: number;
    empty: number;
}

const segmentActivitiesByDuedate = (activities: SubTaskDto[], timezone: string) => {
    const today = moment.tz(timezone);
    const warning = today.clone().add("3", "days");
    const completed = activities.filter(({ status }) => status === SubTaskStatus.STATUS_COMPLETED);
    const duesoon = activities.filter(
        ({ duedate, status }) =>
            status === SubTaskStatus.STATUS_OPEN && duedate != null && moment.tz(duedate, timezone).endOf("day").isBetween(today, warning),
    );
    const overdue = activities.filter(
        ({ duedate, status }) =>
            status === SubTaskStatus.STATUS_OPEN && duedate != null && moment.tz(duedate, timezone).endOf("day").isBefore(today),
    );
    const open = activities.filter(
        ({ duedate, status }) =>
            status === SubTaskStatus.STATUS_OPEN &&
            ((duedate != null && moment.tz(duedate, timezone).endOf("day").isAfter(warning)) || duedate === null),
    );

    return { completed, overdue, duesoon, open };
};

export const useActivityLevels = (activities: SubTaskDto[] | undefined) => {
    const { timezone } = useTimezone();

    return useMemo(() => {
        if (activities === undefined || activities.length <= 0) {
            const emptyBarData: IBarData = { empty: 1 };
            return { completed: [], overdue: [], duesoon: [], open: [], isEmpty: true, barData: [emptyBarData] };
        }
        const { completed, overdue, duesoon, open } = segmentActivitiesByDuedate(activities, timezone);
        const empty = 0;
        const barData: IBarData = {
            completed: completed.length,
            duesoon: duesoon.length,
            overdue: overdue.length,
            open: open.length,
            empty: empty,
        };

        return { completed, overdue, duesoon, open, isEmpty: false, barData: [barData] };
    }, [activities, timezone]);
};

export const useActivityLegends = (activities: SubTaskDto[] | undefined) => {
    const { t: translate } = useTranslation();
    const activitySegment = useActivityLevels(activities);
    return useMemo(() => {
        if (activities === undefined) {
            return null;
        }

        const { completed, overdue, duesoon, open } = activitySegment;

        return [
            { name: translate(translationKeys.VDLANG_ACTIVITY_COMPLETED), value: completed.length },
            { name: translate(translationKeys.VDLANG_ACTIVITY_DUESOON), value: duesoon.length },
            { name: translate(translationKeys.VDLANG_ACTIVITY_OVERDUE), value: overdue.length },
            { name: translate(translationKeys.VDLANG_ACTIVITY_OPEN), value: open.length },
        ];
    }, [activities, translate, activitySegment]);
};
