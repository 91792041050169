import { Grid, GridProps, styled } from "@mui/material";
import { blueGrey } from "@mui/material/colors";
import React from "react";

const RootGrid = styled(Grid, { shouldForwardProp: (name) => name !== "noArrow" && name !== "variant" })<IDecisionStepProps>(({
    theme,
    noArrow,
    variant = "paper",
}) => {
    const backgroundColor = {
        dark: blueGrey[100],
        light: theme.palette.background.default,
        medium: blueGrey[50],
        paper: theme.palette.background.paper,
        success: theme.palette.natureGreen.main,
        error: theme.palette.error.main,
    }[variant];

    return {
        minHeight: theme.spacing(20),
        position: "relative",
        padding: theme.spacing(2, 3),
        backgroundColor,
        ...(!noArrow && {
            "&:after": {
                // This element consists only of transparent borders, except on the side where the arrow should be. The border on that side
                // should be colored in the arrow color
                content: '""',
                position: "absolute",
                height: 0,
                width: 0,
                border: "20px solid transparent", // long side will be 2*20 = 40px
                zIndex: 1,
                [theme.breakpoints.down("md")]: {
                    // position in the middle using left+transform
                    left: "50%",
                    transform: "translateX(-50%)",
                    top: "100%",
                    borderTop: `10px solid ${backgroundColor}`, // short side will be 10px
                    borderBottomWidth: 0,
                },
                [theme.breakpoints.up("md")]: {
                    left: "100%",
                    // position in the middle using left+transform
                    top: "50%",
                    transform: "translateY(-50%)",
                    borderLeft: `10px solid ${backgroundColor}`, // short side will be 10px
                    borderRightWidth: 0,
                },
            },
        }),
    };
});

interface IDecisionStepProps extends GridProps {
    variant?: "dark" | "medium" | "light" | "paper" | "success" | "error";
    noArrow?: boolean;
}
const DecisionStep = React.forwardRef<HTMLDivElement, IDecisionStepProps>(({ variant = "paper", noArrow, ...gridProps }, ref) => {
    return (
        <RootGrid
            ref={ref}
            container
            justifyContent="space-evenly"
            alignItems="stretch"
            direction="column"
            noArrow={noArrow}
            variant={variant}
            {...gridProps}
        />
    );
});

export default DecisionStep;
