import { Grid } from "@mui/material";
import { CommentParentType, useComments } from "../../domain/comment";
import { useMeasureContext } from "../../view/MeasureContext";
import LoadingAnimation from "../loading/LoadingAnimation";
import MeasureComments from "./MeasureComments";

const CommentsForCurrentMeasure = () => {
    const measure = useMeasureContext();
    const comments = useComments({ parentType: CommentParentType.MEASURE, parentId: measure.id });
    return comments.isSuccess ? (
        <Grid item>
            <MeasureComments measureId={measure.id} comments={comments.data} />
        </Grid>
    ) : (
        <LoadingAnimation />
    );
};

export default CommentsForCurrentMeasure;
