import { Checkbox, FormControl, FormControlLabel, FormGroup, FormLabel, Grid, Stack, TextField, Typography } from "@mui/material";
import { zMinMaxString } from "api-shared";
import { get, set } from "lodash";
import { useMemo } from "react";
import { Controller, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { translationKeys } from "../../translations/main-translations";
import Form from "../Form";
import InfoIcon from "../icons/InfoIcon";
import ActionItemDialog, { IActionItemDialogProps } from "./ActionItemDialog";

interface CopyEntityDialogProps<T extends readonly string[]> extends Pick<IActionItemDialogProps, "open"> {
    onClose: () => void;
    entityName: string;
    currentTitle: string;
    options: T;
    optionDefaults?: Record<T[number], boolean>;
    copyEntity: (options: T[number][], title: string) => void;
}

type FormValues = { title: string } & Record<string, boolean>;

const CopyEntityDialog = <TCopyOptions extends readonly string[]>({
    entityName,
    open,
    onClose,
    copyEntity,
    options,
    currentTitle,
    optionDefaults,
}: CopyEntityDialogProps<TCopyOptions>) => {
    const { t } = useTranslation();
    const label = t(translationKeys.VDLANG_ENTITY_ACTION_COPY, { entityName: t(entityName) });

    // memoize the default values otherwise form input feels sluggish because of unnecessary rerenders
    const defaultValues = useMemo(
        () =>
            ({
                title: t(translationKeys.VDLANG_ENTITY_ACTION_COPY_TITLE_PLACEHOLDER, { title: currentTitle }),
                ...options.reduce(
                    (map, option: TCopyOptions[number]) => {
                        // react hook form will set values on nested objects if names of fields contain dots
                        // also set nested properties in default values, otherwise form values will contain the data twice
                        // lodash will resolve that dot-notation automatically
                        return set(map, option, optionDefaults?.[option] === true);
                    },
                    {} as Record<string, boolean>,
                ),
            }) as FormValues,
        [currentTitle, optionDefaults, options, t],
    );

    const { control, formState, reset, getValues } = useForm({
        defaultValues,
    });

    const submitEntityCopy = () => {
        const values = getValues();
        const enabledOptions = options.filter((option) => {
            // react hook form will set values on nested objects if names of fields contain dots
            // lodash will resolve that dot-notation automatically
            return get(values, option) === true;
        });

        copyEntity(enabledOptions, values.title);
    };

    const invalidTitleErrorMessage = (title: string) => {
        const validationResult = zMinMaxString.safeParse(title);
        if (validationResult.success) {
            return null;
        }
        const issues = validationResult.error.issues;
        if (issues[0].code === "too_small") {
            return t(translationKeys.VDLANG_MEASURE_TITLE_EMPTY_ERROR);
        }
        if (issues[0].code === "too_big") {
            return t(translationKeys.VDLANG_MEASURE_TITLE_TOO_LONG_ERROR);
        }
        return null;
    };

    const closeDialog = () => {
        reset();
        onClose();
    };

    return (
        <ActionItemDialog
            open={open}
            onClose={closeDialog}
            action="copy"
            primary={label}
            primaryIsTranslated
            onPrimary={submitEntityCopy}
            primaryDisabled={!formState.isValid}
            title={`${label}?`}
            translate={t}
        >
            <Form
                onSubmit={() => {
                    if (formState.isValid) {
                        submitEntityCopy();
                        closeDialog();
                    }
                }}
            >
                <Stack direction="column" spacing={1}>
                    <FormControl fullWidth>
                        <Stack direction="row" spacing={1} alignItems="center">
                            <Typography variant="body2" fontWeight="500">
                                {t(translationKeys.VDLANG_ENTITY_ACTION_COPY_OPTIONS_LEGEND)}
                            </Typography>
                            <InfoIcon title={t(translationKeys.VDLANG_ENTITY_ACTION_COPY_HINT)} />
                        </Stack>
                        <FormGroup>
                            <Grid container>
                                {options.map((option) => {
                                    return (
                                        <Controller
                                            key={option}
                                            name={option}
                                            control={control}
                                            render={({ field }) => {
                                                return (
                                                    <Grid item sm={12} md={6}>
                                                        <FormControlLabel
                                                            onChange={field.onChange}
                                                            control={<Checkbox checked={field.value === true} />}
                                                            label={t(`${translationKeys.VDLANG_ENTITY_ACTION_COPY_OPTION}.${option}`)}
                                                        />
                                                    </Grid>
                                                );
                                            }}
                                        />
                                    );
                                })}
                            </Grid>
                        </FormGroup>
                    </FormControl>
                    <FormControl fullWidth required error={!formState.isValid}>
                        <FormLabel>{t(translationKeys.VDLANG_ENTITY_ACTION_COPY_TITLE_LEGEND)}</FormLabel>
                        <Controller
                            name="title"
                            control={control}
                            rules={{ required: true, maxLength: 1024 }}
                            render={({ field }) => {
                                return (
                                    <TextField
                                        fullWidth
                                        error={!formState.isValid}
                                        helperText={invalidTitleErrorMessage(field.value)}
                                        {...field}
                                    />
                                );
                            }}
                        />
                    </FormControl>
                </Stack>
            </Form>
        </ActionItemDialog>
    );
};

export default CopyEntityDialog;
