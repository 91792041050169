import AddIcon from "@mui/icons-material/AddRounded";
import ChevronRightIcon from "@mui/icons-material/ChevronRightRounded";
import ExpandMoreIcon from "@mui/icons-material/ExpandMoreRounded";
import {
    Alert,
    Button,
    Checkbox,
    Chip,
    Collapse,
    FormControl,
    FormControlLabel,
    FormHelperText,
    Grid,
    Switch,
    Typography,
    styled,
} from "@mui/material";
import { Currency, EffectFilterCurrencyField, EffectType, FeatureFlags } from "api-shared";
import { useState } from "react";
import { Controller, useFormContext } from "react-hook-form";
import { useTranslation } from "react-i18next";
import FeatureFlag from "../../../../components/FeatureFlag";
import FieldLabel from "../../../../components/FieldLabel";
import InfoIcon from "../../../../components/icons/InfoIcon";
import CurrencyInput from "../../../../components/input/CurrencyInput";
import DateRangePicker from "../../../../components/input/date/DateRangePicker";
import { useCurrentClient } from "../../../../domain/client";
import { useIsDesktop } from "../../../../lib/mobile";
import { translationKeys } from "../../../../translations/main-translations";
import { LinearCalculationForm } from "../EffectCategoryDialog";

const NEGATIVE_VALUE_REGEX = /-/;

function syncEffect(initial: Currency, target: Currency): Currency {
    // combination of target/initial changed, sync effect to match new initial/target
    return initial.subtract(target);
}

function syncChangeoverCosts(initial: Currency, target: Currency): Currency {
    // for extra costs the new effect formula from the user perspective should be target-initial
    // e.g.  initial=5000, target=6000 => extra costs of 1000 are displayed, but an effect of -1000 is saved
    // formula result: =(target)-(initial) is displayed, but =-((target)-(initial)) is saved
    return target.subtract(initial).negate();
}

const FieldFormControl = styled(FormControl)(({ theme }) => ({
    [theme.breakpoints.down("md")]: {
        margin: theme.spacing(1, 0),
    },
}));

const AdditionalFieldChip = styled(Chip)(({ theme }) => ({
    backgroundColor: theme.palette.grey[200],
}));

const ExpandAdditionalFieldsFormControlLabel = styled(FormControlLabel)(({ theme }) => ({
    width: "100%",
    marginLeft: 0,
    marginRight: 0,
    marginBottom: theme.spacing(),
    borderRadius: theme.shape.borderRadius,
    "&:hover": {
        backgroundColor: theme.palette.action.hover,
    },
}));

interface ICalculationFormProps {
    disabled: boolean;
    effectType: EffectType;
}

// error key to indicate an error happened while merging the formulas
const FORMULA_MERGING_ERROR = "formula";

const CalculationForm = ({ disabled, effectType }: ICalculationFormProps) => {
    const isDesktop = useIsDesktop();
    const { t } = useTranslation();
    const { name: clientName } = useCurrentClient();
    const [isExpanded, setIsExpanded] = useState(false);

    const {
        getValues,
        setValue,
        control,
        watch,
        setError,
        clearErrors,
        formState: { errors },
    } = useFormContext<LinearCalculationForm>();
    const hasInitial = watch("hasInitial");

    const priceHike = getValues(EffectFilterCurrencyField.PriceHike);
    const [showPriceHike, setShowPriceHike] = useState(priceHike?.value != null);

    function removePriceHike() {
        setShowPriceHike(false);
        setValue(EffectFilterCurrencyField.PriceHike, { value: null, formula: null });
    }

    return (
        <Grid container spacing={isDesktop ? 2 : 0}>
            <Grid item xs={12}>
                <Controller
                    name="hasInitial"
                    control={control}
                    render={({ field }) => {
                        const { value, onChange } = field;
                        return (
                            <FormControlLabel
                                control={<Switch checked={value} onChange={onChange} data-testid="hasInitialSwitch" />}
                                label={
                                    <>
                                        {`${t(translationKeys.VDLANG_EFFECT_CATEGORY_MODAL_HAS_INITIAL)} `}
                                        <InfoIcon
                                            title={t(translationKeys.VDLANG_EFFECT_CATEGORY_MODAL_HAS_INITIAL_HINT, {
                                                effectType: t(`effect_type_${effectType}`),
                                            })}
                                        />
                                    </>
                                }
                                disabled={disabled}
                            />
                        );
                    }}
                />
            </Grid>
            {errors.effect?.type === FORMULA_MERGING_ERROR && (
                <Grid item xs={12}>
                    <Alert severity="error">{t(translationKeys.VDLANG_CURRENCY_INPUT_INVALID_NUMBER_RANGE)}</Alert>
                </Grid>
            )}
            {hasInitial ? (
                [
                    <Grid key={EffectFilterCurrencyField.Initial} item xs={12} md={6}>
                        <Controller
                            name={EffectFilterCurrencyField.Initial}
                            control={control}
                            render={({ field }) => {
                                const { value, onChange } = field;
                                return (
                                    <FieldFormControl required disabled={disabled} fullWidth>
                                        <FieldLabel fieldTitle={EffectFilterCurrencyField.Initial} clientName={clientName} translate={t} />
                                        <CurrencyInput
                                            formula={value?.formula ?? null}
                                            value={value?.value ?? null}
                                            testId={EffectFilterCurrencyField.Initial}
                                            disabled={disabled}
                                            updateValue={function (newValue): void {
                                                const newVal = Currency.fromValue(newValue);
                                                if (newVal === null) {
                                                    return;
                                                }
                                                onChange(newVal);
                                                const target = getValues(EffectFilterCurrencyField.Target);
                                                const targetValue = Currency.fromValue(target?.formula ?? target?.value ?? null);
                                                if (targetValue === null) {
                                                    return;
                                                }
                                                try {
                                                    const newEffect =
                                                        effectType === EffectType.ChangeoverCosts
                                                            ? syncChangeoverCosts(newVal, targetValue)
                                                            : syncEffect(newVal, targetValue);
                                                    setValue(EffectFilterCurrencyField.Effect, newEffect);
                                                    clearErrors(EffectFilterCurrencyField.Effect);
                                                } catch (e) {
                                                    setError(EffectFilterCurrencyField.Effect, { type: FORMULA_MERGING_ERROR });
                                                }
                                            }}
                                            translate={t}
                                        />
                                    </FieldFormControl>
                                );
                            }}
                        />
                    </Grid>,
                    <Grid key={EffectFilterCurrencyField.Target} item xs={12} md={6}>
                        <Controller
                            name={EffectFilterCurrencyField.Target}
                            control={control}
                            render={({ field }) => {
                                const { value, onChange } = field;
                                return (
                                    <FieldFormControl required fullWidth disabled={disabled}>
                                        <FieldLabel fieldTitle={EffectFilterCurrencyField.Target} clientName={clientName} translate={t} />
                                        <CurrencyInput
                                            formula={value?.formula ?? null}
                                            value={value?.value ?? null}
                                            testId={EffectFilterCurrencyField.Target}
                                            updateValue={function (newValue): void {
                                                const newVal = Currency.fromValue(newValue);
                                                if (newVal === null) {
                                                    return;
                                                }
                                                onChange(newVal);
                                                const initial = getValues(EffectFilterCurrencyField.Initial);
                                                const initialValue = Currency.fromValue(initial?.formula ?? initial?.value ?? null);
                                                if (initialValue === null) {
                                                    return;
                                                }
                                                try {
                                                    const newEffect =
                                                        effectType === EffectType.ChangeoverCosts
                                                            ? syncChangeoverCosts(initialValue, newVal)
                                                            : syncEffect(initialValue, newVal);
                                                    setValue(EffectFilterCurrencyField.Effect, newEffect);
                                                    clearErrors(EffectFilterCurrencyField.Effect);
                                                } catch (e) {
                                                    setError(EffectFilterCurrencyField.Effect, { type: FORMULA_MERGING_ERROR });
                                                }
                                            }}
                                            disabled={disabled}
                                            translate={t}
                                        />
                                    </FieldFormControl>
                                );
                            }}
                        />
                    </Grid>,
                ]
            ) : (
                <Grid key={EffectFilterCurrencyField.Effect} item xs={12} md={6}>
                    <Controller
                        name={EffectFilterCurrencyField.Effect}
                        control={control}
                        render={({ field }) => {
                            const { value, onChange } = field;

                            const displayValue =
                                effectType === EffectType.ChangeoverCosts ? new Currency(value.formula, value.value).negate() : value;
                            const stringValue = displayValue?.formula ?? String(displayValue?.value);
                            const hasNegativeValue = NEGATIVE_VALUE_REGEX.test(stringValue);
                            return (
                                <FieldFormControl required fullWidth error={hasNegativeValue} disabled={disabled}>
                                    {/* Show Savings/Changeover Costs depending on effectType */}
                                    <FieldLabel fieldTitle={`effect_type_${effectType}`} clientName={clientName} translate={t} />
                                    <CurrencyInput
                                        formula={displayValue?.formula ?? null}
                                        value={displayValue?.value ?? null}
                                        testId={EffectFilterCurrencyField.Effect}
                                        updateValue={function (newValue): void {
                                            let newVal = Currency.fromValue(newValue);
                                            if (newVal === null) {
                                                return;
                                            }

                                            if (effectType === EffectType.ChangeoverCosts) {
                                                newVal = newVal.negate();
                                            }

                                            onChange(newVal);
                                            setValue(EffectFilterCurrencyField.Initial, { value: null, formula: null });
                                            setValue(EffectFilterCurrencyField.Target, { value: null, formula: null });
                                        }}
                                        disabled={disabled}
                                        translate={t}
                                    />
                                    {hasNegativeValue ? (
                                        <FormHelperText>
                                            {t(translationKeys.VDLANG_CURRENCY_INPUT_NEGATIVE_NUMBER, {
                                                effectType: t(`effect_type_${effectType}`),
                                            })}
                                        </FormHelperText>
                                    ) : null}
                                </FieldFormControl>
                            );
                        }}
                    />
                </Grid>
            )}
            {hasInitial && showPriceHike ? (
                <Grid key={EffectFilterCurrencyField.Effect} item xs={12} md={6}>
                    <Controller
                        name={EffectFilterCurrencyField.PriceHike}
                        control={control}
                        render={({ field }) => {
                            const { value, onChange } = field;

                            return (
                                <FieldFormControl fullWidth disabled={disabled}>
                                    <Grid container justifyContent="space-between" alignItems="center" m={0.5}>
                                        <Grid item>
                                            <FieldLabel
                                                fieldTitle={EffectFilterCurrencyField.PriceHike}
                                                clientName={clientName}
                                                translate={t}
                                            />
                                        </Grid>
                                        {!disabled ? (
                                            <Button onClick={removePriceHike} variant="text" color="error" sx={{ p: 0 }}>
                                                {t(translationKeys.VDLANG_REMOVE)}
                                            </Button>
                                        ) : null}
                                    </Grid>

                                    <CurrencyInput
                                        formula={value?.formula ?? null}
                                        value={value?.value ?? null}
                                        updateValue={function (newValue): void {
                                            const newVal = Currency.fromValue(newValue);
                                            if (newVal !== null) {
                                                onChange(newVal);
                                            }
                                        }}
                                        disabled={disabled}
                                        translate={t}
                                    />
                                </FieldFormControl>
                            );
                        }}
                    />
                </Grid>
            ) : null}
            <Grid item xs={12}>
                <Controller
                    name="pl"
                    control={control}
                    render={({ field, fieldState: { isDirty } }) => {
                        const { value, onChange } = field;
                        const hasError = isDirty && errors.pl != null;

                        // error message will be rendered by DateRangePicker itself, but show label also in error state
                        return (
                            <FieldFormControl required fullWidth disabled={disabled} error={hasError}>
                                <FieldLabel fieldTitle="pl" clientName={clientName} translate={t} />
                                <DateRangePicker
                                    showError={isDirty} // suppress error until field has been modified
                                    value={value}
                                    translate={t}
                                    updateValue={onChange}
                                    disabled={disabled}
                                />
                            </FieldFormControl>
                        );
                    }}
                />
            </Grid>
            {hasInitial && !disabled && (
                <FeatureFlag feature={FeatureFlags.FEATURE_EXPECTED_PRICE_INCREASE}>
                    <Grid key={EffectFilterCurrencyField.PriceHike} item xs={12} md={6}>
                        <ExpandAdditionalFieldsFormControlLabel
                            control={
                                <Checkbox
                                    size="small"
                                    color="default"
                                    disableRipple
                                    icon={<ChevronRightIcon color={disabled ? "disabled" : "action"} />}
                                    checkedIcon={<ExpandMoreIcon />}
                                />
                            }
                            label={t(translationKeys.VDLANG_EFFECT_CATEGORY_MODAL_ADDITIONAL_FIELDS)}
                            checked={isExpanded}
                            onChange={(_event, checked) => setIsExpanded(checked)}
                        />
                        <Collapse in={isExpanded}>
                            <div>
                                {showPriceHike === false ? (
                                    <AdditionalFieldChip
                                        key={EffectFilterCurrencyField.PriceHike}
                                        clickable
                                        size="small"
                                        icon={<AddIcon />}
                                        label={t(EffectFilterCurrencyField.PriceHike)}
                                        onClick={() => setShowPriceHike(true)}
                                    />
                                ) : (
                                    <Typography color="textSecondary">
                                        {t(translationKeys.VDLANG_EFFECT_CATEGORY_MODAL_NO_ADDITIONAL_FIELDS)}
                                    </Typography>
                                )}
                            </div>
                        </Collapse>
                    </Grid>
                </FeatureFlag>
            )}
        </Grid>
    );
};

export default CalculationForm;
