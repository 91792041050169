import { Grid, styled } from "@mui/material";
import { AttributeTitle, DiscardReasons, MeasureDto, MeasureStatus } from "api-shared";
import { TFunction } from "i18next";
import React from "react";
import MarkdownMentions from "../../components/MarkdownMentions";
import { useCurrentDecision } from "../../domain/decision";
import { useProcessName } from "../../domain/measure-config";
import { useAllUsers } from "../../domain/users";
import { useLanguage } from "../../hooks/useLanguage";
import useTimezone from "../../hooks/useTimezone";
import { formatUserFromId } from "../../lib/formatters";
import { translationKeys } from "../../translations/main-translations";
import MeasureInfoCard from "./MeasureInfoCard";

const HintContainer = styled(Grid)({ flexShrink: 0 });

export interface IMeasureHintsProps {
    measure: MeasureDto;
    translate: TFunction;
}

const MeasureHints = ({ translate, measure }: IMeasureHintsProps) => {
    const language = useLanguage();
    const { formatDate, formatTime } = useTimezone();

    const decision = useCurrentDecision(measure.id);
    const decisionGateTask = measure.gateTasks.find((gateTask) => gateTask.id === decision?.gateTaskId);

    const users = useAllUsers();

    const userFormattingOptions = { translate: translate };
    const processNameByMeasure = useProcessName(measure);
    const translatedProcessName = translate(processNameByMeasure);

    let discardReason = "";
    if (measure.status === MeasureStatus.STATUS_DISCARDED) {
        const reason = DiscardReasons.find((r) => r.id === +measure.fields[AttributeTitle.DiscardReason].value);
        discardReason = reason !== undefined ? reason[language] : "";
    }

    return (
        <>
            {
                // If decision was discarded outside of decision gate (= no decision), e.g. via three-points menu
                measure.status === MeasureStatus.STATUS_DISCARDED && (decision === null || decision.isApproved) ? (
                    <HintContainer item>
                        <MeasureInfoCard title={translate("Process discarded", { processName: translatedProcessName })}>
                            {`${translate(AttributeTitle.DiscardReason)}: ${discardReason}`}
                            <Grid container spacing={1} alignItems="flex-start">
                                <Grid item>{translate(AttributeTitle.DiscardStatement) + ":"}</Grid>
                                <Grid item>
                                    <MarkdownMentions markdown={measure.fields[AttributeTitle.DiscardStatement].value} />
                                </Grid>
                            </Grid>
                            {translate(translationKeys.VDLANG_DECISION_COMPLETED_NEGATIVE, {
                                decider: formatUserFromId(measure.updatedById, users, { ...userFormattingOptions }),
                                date: formatDate(measure.updatedAt),
                                time: formatTime(measure.updatedAt),
                                processName: translatedProcessName,
                            })}
                        </MeasureInfoCard>
                    </HintContainer>
                ) : null
            }
            {
                // If decision was discarded by decider in decision gate
                measure.status === MeasureStatus.STATUS_DISCARDED &&
                decision !== null &&
                decision.deciderId !== null &&
                decisionGateTask !== undefined &&
                !decision.isApproved ? (
                    <HintContainer item>
                        <MeasureInfoCard title={translate("Process discarded", { processName: translatedProcessName })}>
                            {`${translate(AttributeTitle.DiscardReason)}: ${discardReason}`}
                            <Grid container spacing={1} alignItems="flex-start">
                                <Grid item>{translate(AttributeTitle.DiscardStatement) + ":"}</Grid>
                                <Grid item>
                                    <MarkdownMentions markdown={measure.fields[AttributeTitle.DiscardStatement].value} />
                                </Grid>
                            </Grid>
                            {decision.deciderId === decisionGateTask.updatedById
                                ? translate(translationKeys.VDLANG_DECISION_COMPLETED_NEGATIVE, {
                                      decider: formatUserFromId(decision.deciderId, users, { ...userFormattingOptions }),
                                      date: formatDate(decisionGateTask.updatedAt),
                                      time: formatTime(decisionGateTask.updatedAt),
                                      processName: translatedProcessName,
                                  })
                                : translate(translationKeys.VDLANG_DECISION_COMPLETED_NEGATIVE_DOCUMENTED, {
                                      decider: formatUserFromId(decision.deciderId, users, { ...userFormattingOptions }),
                                      completedBy: formatUserFromId(decisionGateTask.updatedById, users, { ...userFormattingOptions }),
                                      date: formatDate(decisionGateTask.updatedAt),
                                      time: formatTime(decisionGateTask.updatedAt),
                                      processName: translatedProcessName,
                                  })}
                        </MeasureInfoCard>
                    </HintContainer>
                ) : null
            }
            {measure.status === MeasureStatus.STATUS_CLOSED && measure.completedById != null && measure.completedAt != null ? (
                <HintContainer item>
                    <MeasureInfoCard severity="success" title={translate("Process closed", { processName: translatedProcessName })}>
                        {translate("Closed by user on date", {
                            userName: formatUserFromId(measure.completedById, users, userFormattingOptions),
                            date: formatDate(measure.completedAt),
                        })}
                    </MeasureInfoCard>
                </HintContainer>
            ) : null}
        </>
    );
};

export default React.memo(MeasureHints);
