import CloudUploadIcon from "@mui/icons-material/CloudUploadRounded";
import DeleteIcon from "@mui/icons-material/DeleteRounded";
import DownloadIcon from "@mui/icons-material/DownloadRounded";
import { Button, CircularProgress, Stack } from "@mui/material";
import { FileDto, ImageMimeTypes } from "api-shared";
import { useCallback, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { CellProps, Column } from "react-table";
import Carousel, { CarouselItem } from "../../components/carousel/Carousel";
import DeleteFileDialog from "../../components/dialogues/DeleteFileDialog";
import UploadFileDialog from "../../components/dialogues/UploadFileDialog";
import LoadingAnimation from "../../components/loading/LoadingAnimation";
import PaperTable from "../../components/table/PaperTable";
import TableDateCell, { DateFormat } from "../../components/table/TableDateCell";
import TableHeaderCell from "../../components/table/TableHeaderCell";
import TableIconButton from "../../components/table/TableIconButton";
import TableLinkCallbackCell from "../../components/table/TableLinkCallbackCell";
import TableTextCell from "../../components/table/TableTextCell";
import TableUserCell from "../../components/table/TableUserCell";
import { useDeleteFile, useMeasureFiles, useUploadMeasureFile } from "../../domain/files";
import { useCurrentUserCanEditMeasure } from "../../domain/measure/detail";
import { useAllUsers } from "../../domain/users";
import useDialog from "../../hooks/useDialog";
import { baseUrl } from "../../lib/api";
import { formatSize } from "../../lib/formatters";
import { translationKeys } from "../../translations/main-translations";
import { useMeasureContext } from "../MeasureContext";
import MeasureFullHeightTab from "./MeasureFullHeightTab";

const getFileUrl = (baseUrl: string, path: string | null) => (path ? `${baseUrl}static/${path}` : "");
const getInlineFileUrl = (baseUrl: string, path: string | null) => (path ? `${baseUrl}static/inline/${path}` : "");

const FileList = () => {
    const { t: translate } = useTranslation();

    const measure = useMeasureContext();
    const currentUserCanEditMeasure = useCurrentUserCanEditMeasure(measure);
    const users = useAllUsers();

    const filesQuery = useMeasureFiles({ measureId: measure.id });
    const uploadFileMutation = useUploadMeasureFile();
    const deleteFileMutation = useDeleteFile();

    const uploadDialog = useDialog();
    const [fileToUpload, setFileToUpload] = useState<File | null>(null);
    const [isValidUpload, setIsValidUpload] = useState(false);
    const [fileToDelete, setFileToDelete] = useState<string>();
    const [currentImage, setCurrentImage] = useState<number | null>(null);

    const handleUpload = () => {
        if (fileToUpload !== null) {
            uploadFileMutation.mutate({ measureId: measure.id, file: fileToUpload });
        }

        uploadDialog.close();
        setFileToUpload(null);
        setIsValidUpload(false);
    };

    const imageUrls: CarouselItem[] = useMemo(
        () =>
            filesQuery.data
                ?.filter((img) => img.mimetype && ImageMimeTypes.includes(img.mimetype))
                .map((img) => ({
                    imgLink: getFileUrl(baseUrl, img.hash),
                    displayName: `${img.filename} (${formatSize(img.size ?? 0)})`,
                })) ?? [],
        [filesQuery.data],
    );

    const handleFileClick = useCallback(
        (index: number) => {
            if (!filesQuery.isSuccess) {
                return;
            }
            const clickedFile = filesQuery.data[index];
            if (clickedFile === undefined) {
                return;
            }

            if (clickedFile.mimetype && ImageMimeTypes.includes(clickedFile.mimetype)) {
                const idx = imageUrls.findIndex((imgUrl) => clickedFile.hash && imgUrl.imgLink.includes(clickedFile.hash));
                setCurrentImage(idx);
            }
        },
        [filesQuery.data, filesQuery.isSuccess, imageUrls],
    );

    const renderFileName = useCallback(
        (props: CellProps<FileDto>) => {
            const isImage = props.row.original.mimetype && ImageMimeTypes.includes(props.row.original.mimetype);
            const isSupportedInlineFile = props.row.original.mimetype && props.row.original.mimetype === "application/pdf";
            if (isImage) {
                return <TableLinkCallbackCell onClick={handleFileClick} variant="body2" {...props} />;
            } else if (isSupportedInlineFile) {
                return (
                    <TableLinkCallbackCell
                        onClick={() => window.open(getInlineFileUrl(baseUrl, props.row.original.hash), "_blank")}
                        variant="body2"
                        {...props}
                    />
                );
            } else {
                return <TableTextCell variant="body2" {...props} />;
            }
        },
        [handleFileClick],
    );

    const renderDate = useCallback((props: CellProps<FileDto>) => <TableDateCell format={DateFormat.DateTime} {...props} />, []);

    const renderUser = useCallback((props: CellProps<FileDto>) => <TableUserCell {...props} value={props.value} users={users} />, [users]);

    const renderActions = useCallback(
        ({ value }: CellProps<FileDto>) => (
            <>
                <TableIconButton title={translate("download_file")} href={getFileUrl(baseUrl, value)}>
                    <DownloadIcon />
                </TableIconButton>
                <TableIconButton
                    title={translate("files_column_delete")}
                    onClick={() => setFileToDelete(value)}
                    disabled={!currentUserCanEditMeasure}
                >
                    <DeleteIcon />
                </TableIconButton>
            </>
        ),
        [currentUserCanEditMeasure, translate],
    );

    const columns = useMemo<Column<FileDto>[]>(
        () => [
            {
                Header: TableHeaderCell,
                label: translate("files_column_filename"),
                // Typescript compiler is complaining when typing is changed from any to FileDto
                accessor: (file: FileDto) => `${file.filename} (${formatSize(file.size ?? 0)})`,
                id: "name",
                Cell: renderFileName,
                width: 150,
            },
            {
                Header: TableHeaderCell,
                label: translate("files_column_date"),
                accessor: (file: FileDto) => new Date(file.createdAt ?? 0),
                Cell: renderDate,
                width: 50,
                sortType: "datetime",
                id: "createdAt",
            },
            {
                Header: TableHeaderCell,
                label: translate("files_column_user"),
                accessor: "createdById",
                Cell: renderUser,
                width: 50,
                sortType: "user",
            },
            {
                Header: TableHeaderCell,
                label: translate("files_column_actions"),
                accessor: "hash",
                Cell: renderActions,
                width: 96,
                disableSortBy: true,
                disableResizing: true,
            },
        ],
        [translate, renderFileName, renderDate, renderUser, renderActions],
    );

    if (!filesQuery.isSuccess) {
        return <LoadingAnimation />;
    }

    return (
        <MeasureFullHeightTab>
            <Stack sx={{ height: "100%" }} spacing={2}>
                <Stack direction="row" spacing={2}>
                    <UploadFileDialog
                        open={uploadDialog.isOpen}
                        onClose={uploadDialog.close}
                        onUpload={handleUpload}
                        file={fileToUpload ?? null}
                        updateFile={(file, isValid) => {
                            setFileToUpload(file);
                            setIsValidUpload(isValid);
                        }}
                        primaryDisabled={!isValidUpload}
                        translate={translate}
                    />
                    <DeleteFileDialog
                        item="file"
                        open={fileToDelete !== undefined}
                        onClose={() => setFileToDelete(undefined)}
                        file={filesQuery.data?.find((f) => f.hash === fileToDelete)}
                        onDelete={() =>
                            fileToDelete !== undefined && deleteFileMutation.mutate({ hash: fileToDelete, measureId: measure.id })
                        }
                        translate={translate}
                    />
                    <Button
                        variant="contained"
                        color="primary"
                        disabled={!currentUserCanEditMeasure || uploadFileMutation.isLoading}
                        onClick={uploadDialog.open}
                        startIcon={<CloudUploadIcon />}
                    >
                        {translate(translationKeys.VDLANG_UPLOAD_SELECT_DOCUMENT_PHOTO)}
                    </Button>
                    {uploadFileMutation.isLoading && <CircularProgress variant="determinate" value={uploadFileMutation.progress} />}
                </Stack>
                <PaperTable
                    fullHeight
                    data={filesQuery.data}
                    columns={columns}
                    itemName="files_pagination"
                    noDataText={translate(translationKeys.VDLANG_NO_FILES)}
                    translate={translate}
                />
                <Carousel onClose={() => setCurrentImage(null)} onChange={setCurrentImage} currentIndex={currentImage} items={imageUrls} />
            </Stack>
        </MeasureFullHeightTab>
    );
};

export default FileList;
